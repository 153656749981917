import { wgs84ToLL, psad56ToLL, isValidLLNumber } from 'src/utils';


export const nullifyInvalidLLs = convertedSps => convertedSps.map(({ x: lon, y: lat, ...restConvertedSps }) => {
  if (!isValidLLNumber(lat, 1) || !isValidLLNumber(lon, 2)) {
    return { ...restConvertedSps, x: null, y: null };
  }
  return { ...restConvertedSps, x: lon, y: lat };
});

export const convertSamplingPoints = ({ samplingPoints, fromType, timezone }) => {
  const convertedSps =
  fromType === 'WSG84' ?
    samplingPoints.map(({ name, x, y }) => ({ name, ...wgs84ToLL({ coordinates: [ x, y ], zoneNumber: timezone, xyFormat: true }) })) :
    fromType === 'PSAD56' ?
      samplingPoints.map(({ name, x, y }) => ({ name, ...psad56ToLL({ coordinates: [ x, y ], zoneNumber: timezone, xyFormat: true }) })) :
      samplingPoints;
  return nullifyInvalidLLs(convertedSps);
};

export const createEmptyArray = (n = 0) => new Array(n).fill(undefined);

export const transposeGrid = grid => {
  const newGrid = createEmptyArray(grid?.[0].length)
    .map(() => createEmptyArray(grid.length));

  grid.forEach((row, i) => row.forEach((col, j) => newGrid[j][i] = grid[i][j]));
  return newGrid;
};

export const accuracyTypes = {
  EXACT: 'exact',
  TRANSECT: 'transect',
  PROJECT_AREA: 'project-area',
  SAMPLING_POINT: 'sampling-point',
};
