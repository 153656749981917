import React from 'react';
import ReactDOM from 'react-dom';
import App from 'src/App';
import 'src/index.css';


ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
