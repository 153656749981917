import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';

import { projectApi } from 'src/services';
import { Section } from 'src/components';


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
    maxWidth: 300,
  },
  submitButton: {
    display: 'block',
    marginTop: theme.spacing(5),
  },
}));

const ProjectIdSelection = ({ updateState }) => {
  const classes = useStyles();
  const [ projects, setProjects ] = useState([]);
  const [ project, setProject ] = useState('');

  const getProjects = useCallback(async filter => {
    try {
      const { projects } = (await projectApi.getListProjects({ seaId: filter }));
      setProjects(projects);
    } catch (error) {
      console.error(error);
    }
  }, [ setProjects ]);

  useEffect(() => {
    getProjects(project);
  }, [ project, getProjects ]);

  // TODO: loading mientras se obtienen los años
  return (
    <Section title="O Selecciona id proyecto">
      <FormControl variant="outlined" className={classes.formControl}>
        <Autocomplete
          options={projects}
          getOptionLabel={option => `${option.label || option.value}`}
          getOptionSelected={(option, value) => option.value === value.value}
          filterSelectedOptions
          onChange={(event, newValue) => updateState({ seaProjectId: newValue?.value })}
          renderInput={ params => {
            const query = params.inputProps.value;
            setProject(query);
            return (
              <TextField
                {...params}
                name={`projectYear`}
                variant="outlined"
                label={'Id'}
                value={project}
              />
            );
          }}
        />
      </FormControl>
    </Section>
  );
};

ProjectIdSelection.propTypes = {
  projectId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  updateState: PropTypes.func,
  formType: PropTypes.string,
};


export default ProjectIdSelection;