import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Grid, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';

import { archeologyApi } from 'src/services';
import { Page, Section, Project, GridCoordinatesMap, DialogWrapper, ReportIssueDialog } from 'src/components';
import RecordContainer from 'src/scenes/Archeology/components/RecordContainer';
import ArcheologyValidationSchema from 'src/scenes/Archeology/components/ArcheologyValidationSchema';
import ArchaeologyDetail from 'src/scenes/Archeology/components/ArcheologyDetails';
import { stopSendingOnEnter } from 'src/utils';
import { projectApi } from 'src/services';


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
    maxWidth: 300,
  },
  alertGeoJson: {
    marginBottom: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  comments: {
    width: '100%',
  },
}));

const ArcheologyForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const { state } = useLocation();

  const [ project, setProject ] = useState(state?.project);
  const [ seaProject, setSeaProject ] = useState(state?.seaProject);
  const [ sendingData, setSendingData ] = useState(false);
  const [ dialogStatus, setDialogStatus ] = useState(false);

  const closeDialog = () => setDialogStatus(false);
  const openDialog = () => setDialogStatus(true);

  const getDialogType = () => dialogStatus ?
    <ReportIssueDialog
      seaProjectId={seaProject.id}
      actions={{ closeDialog, restartForm: () => history.push('/app/archeology/load-project') }}
      type="archeology"
    /> : <></>;

  const { handleSubmit, control, register, watch, setValue, getValues, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ArcheologyValidationSchema),
  });

  const [ baseline, hasRecord ] = watch([ 'baseline', 'hasRecord' ]);

  const { seaProjectId } = match.params;
  useEffect(() => {
    if (!project) {
      const fetchData = async () => {
        const { project, seaProject } = await projectApi.getProjectBySeaId(seaProjectId);
        setProject(project);
        setSeaProject(seaProject);
      };
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = async data => {
    try {
      setSendingData(true);

      toast.info('Guardando la información');
      const archeologyForm = new FormData();

      archeologyForm.append('data', JSON.stringify(data));
      archeologyForm.append('baselineFile', data.baselineFile?.[0]);
      archeologyForm.append('pas132File', data.pas132File?.[0]);
      archeologyForm.append('paleontologicalBaselineFile', data.paleontologicalBaselineFile?.[0]);

      const resp = await archeologyApi.saveArcheology(archeologyForm);
      setSendingData(false);

      if (resp) {
        toast.dismiss();
        toast.success(resp.message);
        history.push('/app/archeology/load-project');
      }
    } catch (err) {
      setSendingData(false);
      toast.dismiss();
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      } else {
        console.error(`Problem submit form: ${err}`);
      }
    }
  };

  const onError = errors => {
    console.error(errors);
    toast.error('Hay problemas con el formulario', { autoClose: 10000 });
  };

  const { ref: commnetsRef, ...commentsRegister } = register('comments', { shouldUnregister: true });

  return (
    <Page title="Arqueología">
      <form onSubmit={handleSubmit(onSubmit, onError)} noValidate onKeyDown={stopSendingOnEnter}>
        {project && seaProject &&
          <>
            <Section title="Línea base arqueológica" >
              <Project project={seaProject}>
                <input type="hidden" { ...register('projectId', { shouldUnregister: true }) } defaultValue={project.id}/>
                <ArchaeologyDetail register={register} control={control}
                  watch={watch} errors={errors} setValue={setValue} />
              </Project>
            </Section>
            {baseline &&
              <>
                { hasRecord ?
                  <RecordContainer register={register} control={control}
                    watch={watch} errors={errors} setValue={setValue} getValues={getValues} />
                  :
                  <Section title="Área de estudio - prospección" >
                    <div className={classes.alertGeoJson}>
                      {Boolean(errors.geoJson?.features) &&
                      <Alert severity="error">{`${errors?.geoJson?.features?.message}`}</Alert>
                      }
                    </div>
                    <GridCoordinatesMap control={control} importKml
                      watch={watch} errors={errors} setValue={setValue} getValues={getValues}/>
                  </Section>

                }
                <Section title="Observaciones">
                  <Grid className={classes.container} container direction="row" spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        inputRef={commnetsRef}
                        { ...commentsRegister }
                        type="text"
                        className={classes.comments}
                        label="Observaciones"
                        multiline
                        rows={4}
                        placeholder="Escribe las consideraciones que tengan con relación al proyecto"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Section>
              </>
            }
            <Box display="flex" flex={2}>
              <Box mr={1}>
                <Button className={classes.submitButton} variant="contained" onClick={openDialog}>
                  No es posible llenar el formulario
                </Button>
              </Box>
              <Button type="submit" variant="contained" color="primary" disabled={ sendingData }>
                Enviar
              </Button>
              { sendingData &&
                <Box display='flex' ml={ 2 }>
                  <CircularProgress color='secondary' />
                </Box>
              }
            </Box>
            <DialogWrapper maxWidth='sm' fullWidth onClose={closeDialog} open={dialogStatus}>
              { getDialogType() }
            </DialogWrapper>

          </>
        }
      </form>
    </Page>
  );
};


export { ArcheologyForm };