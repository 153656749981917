import React, { useState, useEffect, useContext } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { userApi } from 'src/services';

import { GlobalContext } from 'src/context';
import Loading from 'src/components/Loading';
import { enums } from 'src/utils';


const ProtectedRoute = ({ ...rest }) => {
  const history = useHistory();
  const { restoreSession, baseline, changeBaseline } = useContext(GlobalContext);

  const [ isLoading, setIsLoading ] = useState(true);
  const [ isAuthenticated, setIsAuthenticated ] = useState(false);

  const currentLocation = history.location.pathname;
  const currentBaseline = Object.keys(enums.baseline).find(k => new RegExp(enums.baseline[k].path).test(currentLocation));

  useEffect(() => {
    if (currentBaseline && !baseline) {
      changeBaseline(currentBaseline);
    }
  }, [ currentBaseline, changeBaseline, baseline, history ]);

  const userAuthenticated = async () => {
    setIsLoading(true);

    try {
      const authenticated = await userApi.isAuthenticated();
      setIsAuthenticated(authenticated);
      restoreSession();
    } catch (err) {
      console.error(err);
      setIsAuthenticated(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    userAuthenticated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    isLoading ? <Loading /> :
    isAuthenticated ? <Route {...rest} /> : <Redirect to="/" />
  );
};


export default ProtectedRoute;
