import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Page } from 'src/components';


// TODO: Se deja así mientras aún no haya homes de nada además de arq
const AreasOfInfluenceHome = () => {
  const history = useHistory();
  useEffect(() => {
    history.replace('/app/areas-of-influence/load-project');
  }, [ history ]);

  return (
    <Page title="Áreas de Influencia">
      Trabajo en progreso...
    </Page>
  );
};

export { AreasOfInfluenceHome };
