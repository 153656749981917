const styles = theme => ({
  selector: {
    width: 300,
  },
  textArea: {
    width: '100%',
  },
  accordionSummary: {
    backgroundColor: '#eee',
  },
  accordionFinished: {
    backgroundColor: 'rgba(156, 255, 255, 0.3)',
  },
  accordionOnWebFinished: {
    backgroundColor: 'rgba(156, 235, 166, 0.4)',
  },
  accordionDetails: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  checkLabel: {
    borderRadius: '12px',
    transition: 'background-color 100ms ease 0s',
    '&:hover': {
      transition: 'background-color 300ms ease 0s',
      backgroundColor: 'rgba(255,255,255,0.65)',
    },
  },
});

export {
  styles,
};