import urlJoin from 'url-join';

import history from 'src/utils/history';
import config from 'src/config/local';
const { serverUrl } = config;


const notRedirectUrls = [ 'user/login', 'user/is-authenticated' ];

const request = async ({ url, method = 'POST', data = {}, options = {} }) => {
  try {
    const finalUrl = new URL(url.match(/^https?:\/\//) ? url : urlJoin(serverUrl, 'api', url));
    const requestOptions = {
      method,
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      credentials: 'include',
      ...options,
    };

    if (method.toLowerCase() === 'get') {
      finalUrl.search = new URLSearchParams(data);
    } else {
      if (requestOptions.headers['Content-Type'] === 'multipart/form-data') {
        requestOptions.body = data;
        delete requestOptions.headers['Content-Type'];
      } else {
        requestOptions.body = JSON.stringify(data);
      }
    }

    const resp = await fetch(finalUrl, requestOptions);
    const contentType = resp.headers.get('content-type');

    if (contentType?.includes('application/json')) {
      const jsonResp = await resp.json();
      if (200 <= resp.status && resp.status < 300) {
        return { ...jsonResp, httpStatus: resp.status };
      } else if (resp.status === 401 && notRedirectUrls.includes(url)) {
        return Promise.reject({ serverMessage: jsonResp.message });
      } else if (resp.status === 401 || resp.status === 404) {
        if (jsonResp?.noRedirect) {
          return Promise.reject({ serverMessage: jsonResp.message });
        }
        resp.status === 401 && localStorage.clear();
        history.push('/');
        return;
      } else {
        return Promise.reject({ serverMessage: jsonResp.message });
      }
    } else {
      const textResp = await resp.text();
      console.log(textResp);
      return Promise.reject({ serviceMessage: 'Error en el tipo de datos esperado' });
    }
  } catch (e) {
    console.error(e);
    if (e.code === 20 && e.message === 'The user aborted a request.') {
      return Promise.reject({ serviceMessage: 'Se canceló la petición de datos' });
    }
    return Promise.reject({
      serviceMessage: 'Error al intentar comunicarse con el servidor. Por favor revise su conexión o inténte más tarde',
    });
  }
};

const get = (url, data, options) => request({ url, method: 'GET', data, options });
const post = (url, data, options) => request({ url, method: 'POST', data, options });
const patch = (url, data, options) => request({ url, method: 'PATCH', data, options });
const del = (url, data, options) => request({ url, method: 'DELETE', data, options });
const put = (url, data, options) => request({ url, method: 'PUT', data, options });


export {
  get,
  post,
  patch,
  del,
  put,
};