import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';


const AlertsList = memo(({ alerts }) => alerts.length === 1 ?
  <Alert severity={alerts[0].type}>{alerts[0].message}</Alert>
  : <details open>
    <summary><Typography variant="subtitle1" style={{ display: 'inline-block' }}>Avisos:</Typography></summary>
    { alerts.map(({ type, message }, ind) => <Alert key={ind} severity={type}>{message}</Alert>)}
  </details>,
);

AlertsList.propTypes = {
  alerts: PropTypes.array.isRequired,
};


export {
  AlertsList,
};
