import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, FormControlLabel, RadioGroup, Radio, FormControl, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const CoordinatesTypes = ({ coordType, setCoordType }) => {
  const classes = useStyles();

  const { type, timezone } = coordType;

  return (
    <Grid container className={classes.container} >
      <Grid item>
        <FormControl component="fieldset">
          <FormLabel component="legend">Tipo Coordenada</FormLabel>
          <RadioGroup row aria-label="coordinates types"
            value={ type } onChange={ e => setCoordType(ps => ({ ...ps, type: e.target.value })) }
          >
            <FormControlLabel value="LAT-LNG" control={<Radio />} label="LAT-LNG" />
            <FormControlLabel value="WSG84" control={<Radio />} label="WSG84" />
            <FormControlLabel value="PSAD56" control={<Radio />} label="PSAD56" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {(type === 'WSG84' || type === 'PSAD56') &&
        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="legend">DATUM</FormLabel>
            <RadioGroup row aria-label="Huso"
              value={ timezone } onChange={ e => setCoordType(ps => ({ ...ps, timezone: parseInt(e.target.value, 10) })) }
            >
              <FormControlLabel value={ 18 } control={<Radio />} label="18" />
              <FormControlLabel value={ 19 } control={<Radio />} label="19" />
            </RadioGroup>
          </FormControl>
        </Grid>
      }
    </Grid>
  );
};

CoordinatesTypes.propTypes = {
  coordType: PropTypes.shape({
    type: PropTypes.string,
    timezone: PropTypes.number,
  }),
  setCoordType: PropTypes.func,
};


export { CoordinatesTypes };