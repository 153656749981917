import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';


const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
  },
}));

const SelectWrapper = props => {
  const { label, options, withEmptyValue = false, fullWidth = false, ...otherProps } = props;
  const classes = useStyles();

  return (
    <FormControl fullWidth={fullWidth} variant="outlined" className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select label={label} {...otherProps}>
        {withEmptyValue &&
          <MenuItem value="">
            <em>None</em>
          </MenuItem>}
        {options.map((option, idx) =>
          <MenuItem key={idx} value={option.value}>{option.label}</MenuItem>,
        )}
      </Select>
    </FormControl>
  );
};

SelectWrapper.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  withEmptyValue: PropTypes.bool,
  fullWidth: PropTypes.bool,
  otherProps: PropTypes.object,
};


export { SelectWrapper };