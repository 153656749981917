import { /*, del*/ get, put } from 'src/services/api';


const saveForm = async data => await put('/noise-and-vibrations', data);
const getData = async seaProjectId => (await get(`/noise-and-vibrations/data/${seaProjectId}`)).data;


export {
  saveForm,
  getData,
};