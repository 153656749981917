import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Map } from 'src/components';
import { CoordinatesTypes } from 'src/components';
import { GridCoordinatesGeometryFlora } from 'src/scenes/Flora/components/GridCoordinatesGeometryFlora';
import { GridCoordinatesTransectGeometryFlora } from 'src/scenes/Flora/components/GridCoordinatesTransectGeometryFlora';
import { pointToGeoPoint, pointsToLineStringFeats } from 'src/utils';
import { accuracyTypes, convertSamplingPoints } from 'src/scenes/Flora/utils';


const GridCoordinatesMapFlora = props => {
  const { coordType, setCoordType, recordIndex, campaignIndex, samplingPoints, setSamplingPoints, accuracy } = props;

  const samplingPointsAsGeojson = () => {
    const spCleaned = samplingPoints.filter(s => s.name && s.x && s.y);
    const spConverted = convertSamplingPoints({
      samplingPoints: spCleaned, fromType: coordType.type, timezone: coordType.timezone,
    });
    const accuracyValidAndNotTransect =
      accuracy === accuracyTypes.PROJECT_AREA || accuracy === accuracyTypes.EXACT || accuracy === accuracyTypes.SAMPLING_POINT;

    if (accuracyValidAndNotTransect) {
      return {
        type: 'FeatureCollection',
        features: spConverted.map(({ name, x, y }) => pointToGeoPoint({ lat: y, lon: x, name })),
      };
    } else if (accuracy === accuracyTypes.TRANSECT) {
      const lineStringFeats = pointsToLineStringFeats(spConverted).filter(feature => feature.geometry.coordinates.length > 1);
      return {
        type: 'FeatureCollection',
        features: lineStringFeats,
      };
    } else {
      throw new Error(`Invalid accuracy!!\nAccuracy=${accuracy}`);
    }
  };

  return (
    <Grid container direction="row" justifyContent="flex-start" spacing={2}>
      <Grid item xs={6}>
        <Grid item container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <CoordinatesTypes coordType={ coordType } setCoordType={ setCoordType }/>
          </Grid>
        </Grid>
        <Grid item container direction="column">
          { accuracy === accuracyTypes.TRANSECT ?
            <GridCoordinatesTransectGeometryFlora
              samplingPoints={ samplingPoints }
              setSamplingPoints={ setSamplingPoints }
              coordType={ coordType }
            /> :
            <GridCoordinatesGeometryFlora
              samplingPoints={ samplingPoints }
              setSamplingPoints={ setSamplingPoints }
              coordType={ coordType }
              accuracy={ accuracy }
            />
          }
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Map nameId={ `map-${campaignIndex}-${recordIndex}` } geoJson={ samplingPointsAsGeojson() } />
      </Grid>
    </Grid>
  );
};

GridCoordinatesMapFlora.propTypes = {
  coordType: PropTypes.shape({ type: PropTypes.string, timezone: PropTypes.number }).isRequired,
  setCoordType: PropTypes.func.isRequired,
  recordIndex: PropTypes.number.isRequired,
  campaignIndex: PropTypes.number.isRequired,
  samplingPoints: PropTypes.array.isRequired,
  setSamplingPoints: PropTypes.func.isRequired,
  accuracy: PropTypes.string.isRequired,
};


export { GridCoordinatesMapFlora };
