import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { typeGeoJson } from 'src/utils';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Button, Checkbox } from '@material-ui/core';
import ReactDataSheet from 'react-datasheet';
import PropTypes from 'prop-types';

import {
  wgs84ToLLNullable as wgs84ToLL,
  psad56ToLLNullable as psad56ToLL,
  isValidCoordinate,
  formatCoordinateToUser,
  formatCoordinateToFloatNumber,
} from 'src/utils';


const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dataGrid: {
    marginTop: theme.spacing(2),
    width: '100%',
    margin: 'auto',
  },
}));

const gridHeader = [
  { readOnly: true, value: '' },
  { value: 'C Este', readOnly: true },
  { value: 'C Norte', readOnly: true },
];

const gridColumn = (index = 1) => ([
  { readOnly: true, value: index },
  { value: '' },
  { value: '' },
]);


const GridCoordinatesGeometry = ({ id, index, remove, control, watch, setValue, getValues, handleGeoJson }) => {
  const classes = useStyles();

  const geometryName = `${id}.geometry`;
  const gridName = `${id}.grid`;
  const isBadDataName = `${id}.isBadData`;

  const gridDefault = [
    gridHeader,
    gridColumn(),
  ];

  const geometry = watch(geometryName, typeGeoJson.POINT);
  const grid = watch(gridName, gridDefault);
  const [ coordinatesTypes, zoneNumber ] = watch([ 'coordinatesTypes', 'zoneNumber' ]);

  const handleChangeGeometry = () => {
    const newGrid = [ gridHeader, gridColumn() ];

    setValue(gridName, newGrid);
    handleGeoJson({ geoJson: {}, index });
  };

  const handleChangeIsBadData = () => {
    onChangeGridCoordinates({ grid });
  };

  const addRow = () => {
    setValue(gridName, [ ...grid, gridColumn(grid.length) ]);
  };

  const cleanGridCoordinates = ({ grid }) => grid.filter(([ , x, y ], index) => index > 0 && x.value && y.value)
    .map(([ , x, y ]) => [ x.value, y.value ]);

  const convertCoordinates = coordinates => coordinatesTypes === 'WSG84' ?
    coordinates.map(([ x, y ]) =>
      wgs84ToLL({ coordinates: [ formatCoordinateToFloatNumber(x), formatCoordinateToFloatNumber(y) ], zoneNumber })) :
    coordinatesTypes === 'PSAD56' ?
      coordinates.map(([ x, y ]) => psad56ToLL({
        coordinates: [ formatCoordinateToFloatNumber(x), formatCoordinateToFloatNumber(y) ],
        zoneNumber,
      })) :
      coordinates.map(([ x, y ]) => [ formatCoordinateToFloatNumber(y), formatCoordinateToFloatNumber(x) ]);

  const buildGeoJson = coordinates => {
    if (coordinates.length === 0) {
      return {};
    }

    const coordinatesGeoJson =
      geometry === typeGeoJson.POINT ? coordinates[0] :
      geometry === typeGeoJson.LINESTRING ? coordinates :
      geometry === typeGeoJson.POLYGON ? [ [ ...coordinates, coordinates[0] ] ] :
      console.error('geometry doesn\'t exist');

    const isBadData = getValues(isBadDataName);

    return {
      type: 'Feature',
      geometry: { type: geometry, coordinates: coordinatesGeoJson },
      properties: { isBadData },
    };
  };

  const onChangeGridCoordinates = ({ grid }) => {
    let coordinates = cleanGridCoordinates({ grid });
    coordinates = convertCoordinates(coordinates);

    const geoJson = buildGeoJson(coordinates);

    handleGeoJson({ geoJson, index });
  };

  useEffect(() => {
    onChangeGridCoordinates({ grid });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ coordinatesTypes, zoneNumber ]);

  const handleRemoveGridGeometry = () => {
    handleGeoJson({ geoJson: {}, index });
    remove(index);
  };

  return (
    <Grid item className={classes.container}>
      <Grid item container direction="row" justifyContent="space-between" alignItems="center" >
        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="legend">Tipo de Geometría</FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup row
                  {...field}
                  onChange={(_, data) => {
                    handleChangeGeometry(data);
                    field.onChange(data);
                  }}>
                  <FormControlLabel value={typeGeoJson.POINT} control={<Radio />} label="Punto" />
                  <FormControlLabel value={typeGeoJson.LINESTRING} control={<Radio />} label="Línea" />
                  <FormControlLabel value={typeGeoJson.POLYGON} control={<Radio />} label="Polígono" />
                </RadioGroup>
              )}
              defaultValue={typeGeoJson.POINT}
              name={geometryName}
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Grid item container direction="column" alignItems="flex-end">
            <Grid item>
              <Controller
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    {...field}
                    labelPlacement="start"
                    label="¿Datos erroneos?"
                    onChange={(_, data) => {
                      field.onChange(data);
                      handleChangeIsBadData(data);
                    }}
                  />
                )}
                defaultValue={false}
                name={isBadDataName}
                control={control}
              />
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={handleRemoveGridGeometry}>
              BORRAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        {geometry !== typeGeoJson.POINT &&
          <Button variant="outlined" onClick={addRow}>
            AGREGAR FILA
          </Button>
        }
        <Controller
          name={gridName}
          defaultValue={gridDefault}
          control={control}
          render={() => (
            <ReactDataSheet
              className={classes.dataGrid}
              data={grid}
              valueRenderer={cell => cell.value }
              onCellsChanged={(changes, additions) => {
                const newGrid = grid;
                changes.forEach(({ row, col, value }) => {
                  if (isValidCoordinate({ value, coordinatesTypes, col })) {
                    newGrid[row][col] = { ...newGrid[row][col], value: formatCoordinateToUser({ value, coordinatesTypes }) };
                  }

                  additions && additions.forEach(({ row, col, value }) => {
                    if (isValidCoordinate({ value, coordinatesTypes, col })) {
                      if (!newGrid[row] && value) {
                        newGrid[row] = gridColumn(row);
                      }

                      if (newGrid[row]?.[col]) {
                        newGrid[row][col] = { ...newGrid[row][col], value: formatCoordinateToUser({ value, coordinatesTypes }) };
                      }
                    }
                  });
                });
                setValue(gridName, newGrid);
                onChangeGridCoordinates({ grid: newGrid });
              }}
              onContextMenu={(e, cell) => cell.readOnly ? e.preventDefault() : null}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

GridCoordinatesGeometry.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number,
  remove: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  handleGeoJson: PropTypes.func.isRequired,
};


export default GridCoordinatesGeometry;