import { useEffect, useRef } from 'react';


// NOTAR: esto no funciona para evitar la navegación manejada por react, solo para cuando el browser detecta que quieres de verdad
// ir a otra página o recargar la página


/**
 * @param { function } checkConfirmNeeded Si esto retorna un valor truthy, se activará un diálogo de
 * "Esta página de pide que confirmes que quieres salir".
 */
const useLeaveConfirm = ({ checkConfirmNeeded } = {}) => {
  // Para usar la fn en el listener sin tener que añadir y remover el listener a cada rato cada vez que cambie con cada render
  const checkerRef = useRef();
  checkerRef.current = checkConfirmNeeded;

  useEffect(() => {
    const onBeforeUnload = event => {
      const keepHere = checkerRef.current();
      if (keepHere) {
        event.preventDefault();
        event.returnValue = keepHere;
        return keepHere;
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  // eslint-disable-next-line -- ref no necesitan incluirse en las dependencias
  }, []);
};


export { useLeaveConfirm };