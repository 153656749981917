import React, { useEffect } from 'react';
import PropTypes from 'prop-types';


const Page = ({ children, title }) => {
  useEffect(() => {
    document.title = title || '';
  }, [ title ]);
  return (
    <>
      {children}
    </>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};


export default Page;
