import React from 'react';
import { Grid, TextField, Checkbox, FormControlLabel, FormControl, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';


const FloraDetail = ({ form, setForm, errors }) => {

  const { hasBaseline, hasFlora, studyYear } = form;

  const onChange = field => value => setForm(prevForm => ({ ...prevForm, [field]: value }));

  return (
    <Grid container direction="row" spacing={4}>
      <Grid item xs={6}>
        <Grid item xs={12} sm container direction="column" spacing={2}>
          <Grid item>
            <TextField
              value={ studyYear }
              onChange={ e => onChange('studyYear')(e.target.value) }
              label="Año de estudio"
              type="text"
              variant="outlined"
              error={Boolean(errors?.studyYear)}
              helperText={errors?.studyYear?.errorMessage}
            />
          </Grid>
          <Grid container item direction="row" alignItems="center" spacing={4}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox color="primary"
                    checked={ hasBaseline }
                    onChange={ e => onChange('hasBaseline')(e.target.checked) }
                  />
                }
                labelPlacement="start"
                label="¿Tiene línea base?"
              />
            </Grid>
            { hasBaseline &&
              <Grid item>
                <FormControl error={Boolean(errors?.baselineFile)} component="fieldset">
                  <input
                    id="upload-baseline"
                    type="file"
                    onChange={ e => onChange('baselineFile')(e.target.files[0])}
                    accept=".pdf,.doc,.docx,.odt,.zip,.rar,.7z"
                  />
                  { Boolean(errors?.baselineFile) &&
                    <FormHelperText>
                      {`${errors.baselineFile.errorMessage}`}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
            }
          </Grid>
          { hasBaseline &&
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox color="primary"
                    checked={ hasFlora }
                    onChange={ e => onChange('hasFlora')(e.target.checked) }
                  />
                }
                labelPlacement="start"
                label="¿Tiene Flora?"
              />
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

FloraDetail.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  errors: PropTypes.object,
};


export default FloraDetail;