import { put, get, del } from 'src/services/api';


const saveForm = async data => await put('/parts-and-works', data);
const getCurrentStatus = async projectId => (await get(`/parts-and-works/current-status/${projectId}`)).data;
const deleteData = async partsAndWorksId => await del(`/parts-and-works/delete-data/${partsAndWorksId}`);


export {
  saveForm,
  getCurrentStatus,
  deleteData,
};