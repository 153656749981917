import * as yup from 'yup';


// Esta cosa solo se puede hacer globalmente o por campo, pero no por esquema... así que hagámoslo global.
yup.setLocale({
  mixed: {
    default: 'Valor inválido',
    notType: 'El dato tiene un formato inválido',
  },
  number: {
    min: 'Debe ser mínimo ${min}',
    max: 'Debe ser máximo ${max}',
    integer: 'Debe ser un número entero',
  },
});


export default yup;