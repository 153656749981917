import React, { useState, useEffect } from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { toast } from 'react-toastify';

import floraSchema, { arraySchemaIds } from 'src/scenes/Flora/floraValidationSchema';
import { Page, Section, Project, DialogWrapper, ReportIssueDialog } from 'src/components';
import FloraDetail from 'src/scenes/Flora/components/FloraDetail';
import { Campaign } from 'src/scenes/Flora/components/Campaign';
import { projectApi, floraApi } from 'src/services';


const emptyCampaign = {
  name: '',
  date: null,
  isExactDate: true,
  records: [
    {
      // TODO: maybe code this so it's always consistent with addNewRecord in Campaign.js?
      accuracy: 'project-area',
      places: [
        {
          name: null,
          geometry: null,
          species: [],
        },
      ],
    },
  ],
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
    maxWidth: 300,
  },
  submitButton: {
    display: 'block',
    marginTop: theme.spacing(5),
  },
  comments: {
    width: '100%',
  },
}));

const FloraForm = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();

  const { state } = useLocation();
  const [ project, setProject ] = useState(state?.project);
  const [ seaProject, setSeaProject ] = useState(state?.seaProject);
  const [ sendingData, setSendingData ] = useState(false);
  const [ dialogStatus, setDialogStatus ] = useState(false);

  const closeDialog = () => setDialogStatus(false);
  const openDialog = () => setDialogStatus(true);

  const getDialogType = () => dialogStatus ?
    <ReportIssueDialog
      seaProjectId={seaProject.id}
      actions={{ closeDialog, restartForm: () => history.push('/app/flora/load-project') }}
      type="flora"
    /> : <></>;

  const [ form, setForm ] = useState({
    hasBaseline: false,
    hasFlora: false,
    studyYear: '',
    campaigns: [ emptyCampaign ],
    baselineFile: null,
    comments: '',
  });

  const [ errors, setErrors ] = useState({});

  const { seaProjectId } = match.params;
  useEffect(() => {
    if (!project) {
      const fetchData = async () => {
        const { project, seaProject } = await projectApi.getProjectBySeaId(seaProjectId);
        setProject(project);
        setSeaProject(seaProject);
      };
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  const addNewCampaign = () => setForm(pf => ({
    ...pf,
    campaigns: [ ...pf.campaigns, emptyCampaign ],
  }));

  const onUpdateCampaign = index => newValues => setForm(pf => ({
    ...pf,
    campaigns: pf.campaigns.map((c, i) => i !== index ? c : { ...c, ...newValues }),
  }));

  const onDeleteCampaign = index => setForm(pf => ({
    ...pf,
    campaigns: pf.campaigns.filter((c, i) => i !== index),
  }));

  // Extract this to a utils file or something? is it useful outside of here?
  const makeErrors = (valErrors, arraySchemas) => {
    const errorObj = {};
    valErrors.inner.forEach(innerError => {
      const pathArr = innerError.path.match(/[^\][.]+/g);
      let currentPart = errorObj;
      pathArr.forEach((part, ind) => {
        if (currentPart[part] === undefined) {
          currentPart[part] = arraySchemas.includes(part) ? [] : {};
        }
        currentPart = currentPart[part];
        if (ind === pathArr.length - 1) {
          currentPart.errorMessage = innerError.message;
          currentPart.errorType = innerError.type;
          // made up field, useful when the error has additional data (like species indicating which species repeat and where)
          currentPart.errorExtra = innerError.params.errorExtra;
        }
      });
    });
    return errorObj;
  };

  const onSubmit = async () => {

    try {
      setErrors({});
      await floraSchema.validate(form, { abortEarly: false });

      setSendingData(true);
      toast.info('Guardando la información');

      const { baselineFile, ...restForm } = form;
      const floraForm = new FormData();
      floraForm.append('data', JSON.stringify({ projectId: project.id, ...restForm }));
      floraForm.append('baselineFile', baselineFile);
      const { message } = await floraApi.saveFlora(floraForm);

      setSendingData(false);
      toast.dismiss();
      toast.success(message);

      history.push('/app/flora/load-project');

    } catch (e) {
      toast.dismiss();
      setSendingData(false);
      if (e.serverMessage) {
        toast.error(e.serverMessage);
      } else {
        toast.error(<div>Hay problemas con el formulario.<br/>Por favor revisar</div>,
          { autoClose: 10000, allowHtml: true },
        );
        const formErrors = makeErrors(e, arraySchemaIds);
        setErrors(formErrors);
        console.error(`Problem submit form: ${e}`);
      }
    }
  };

  const onCommentUpdate = comments => setForm(pf => ({ ...pf, comments }));

  const { hasFlora, hasBaseline, campaigns } = form;
  return (
    <>
      {project && seaProject &&
        <Page title="Flora">
          <Section title="Línea base de flora">
            <Project project={seaProject}>
              <FloraDetail errors={errors} form={ form } setForm={ setForm } />
            </Project>
          </Section>
          { hasBaseline && hasFlora &&
            <>
              <Box display='flex' mt={ 2 } mb={ 1 } justifyContent='space-between'>
                <Typography variant="h5" component="span" gutterBottom>
                  Campañas
                </Typography>
                { errors?.campaigns?.errorType === 'required' &&
                  <Alert severity="error">{`${errors.campaigns.errorMessage}`}</Alert>
                }
                <Button type="button" variant="contained" color="primary" onClick={ addNewCampaign }>Agregar Campaña</Button>
              </Box>
              {
                campaigns.map((c, i) =>
                  <Campaign key={i} index={i}
                    errors = {errors?.campaigns?.[i]}
                    campaign={c}
                    onUpdate={onUpdateCampaign(i)}
                    onDelete={() => onDeleteCampaign(i)}
                  />)
              }
            </>
          }
          <Section title="Observaciones">
            <Box>
              <TextField
                onChange={ e => onCommentUpdate(e.target.value)}
                type="text"
                name="comments"
                className={classes.comments}
                label="Observaciones"
                multiline
                rows={4}
                placeholder="Escribe las consideraciones que tengas con relación al proyecto"
                variant="outlined"
              />
            </Box>
          </Section>
          <Box display="flex" flex={2}>
            <Box mr={1}>
              <Button className={classes.submitButton} variant="contained" onClick={openDialog}>
                No es posible llenar el formulario
              </Button>
            </Box>
            <Button className={classes.submitButton} type="button" variant="contained" color="primary"
              disabled={sendingData} onClick={onSubmit} >
              Enviar
            </Button>
          </Box>
          <DialogWrapper maxWidth='sm' fullWidth onClose={closeDialog} open={dialogStatus}>
            { getDialogType() }
          </DialogWrapper>
        </Page>
      }
    </>
  );
};


export { FloraForm };
