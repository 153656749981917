import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Wizard, WizardPage } from 'src/components';
import { SpeciesLinking } from 'src/scenes/Flora/components/SpeciesLinking';
import { TransectSpeciesLinking } from 'src/scenes/Flora/components/TransectSpeciesLinking';
import { GridCoordinatesMapFlora } from 'src/scenes/Flora/components/GridCoordinatesMapFlora';
import { pointToGeoPoint } from 'src/utils';
import { convertSamplingPoints, transposeGrid } from 'src/scenes/Flora/utils';
import { accuracyTypes } from 'src/scenes/Flora/utils';


const placeToSamplingPoint = ({ name, geometry: { coordinates: [ x, y ] } }) => ({ name, x, y });

const WizardFlora = ({ places, updatePlaces, campaignIndex, recordIndex, accuracy }) => {

  const [ coordType, setCoordType ] = useState({ type: 'LAT-LNG', timezone: 18 });

  const [ samplingPoints, setSamplingPoints ] = useState(places.map(placeToSamplingPoint));
  const [ speciesGridState, setSpeciesGridState ] = useState({ grid: [], isVertical: false });

  // TODO: limpiar datos cuando se cambie la coordenada (evitar 1ra ejecución)

  const updateSpeciesGrid = ns => setSpeciesGridState(ps => ({ ...ps, ...ns }));

  const generateReadOnlyGrid = verticalGrid => {
    const { isVertical } = speciesGridState;

    const readOnlyGrid = verticalGrid.slice(0, -1).map(row => row.map(col => ({ ...col, readOnly: true })));
    const originalGrid = isVertical ? readOnlyGrid : transposeGrid(readOnlyGrid);

    updateSpeciesGrid({ grid: originalGrid });
  };

  const onPressComplete = () => {
    const { grid, isVertical } = speciesGridState;
    const verticalGrid = isVertical ? grid : transposeGrid(grid);

    if (accuracy === accuracyTypes.TRANSECT) {
      console.log('No implementado');
    } else {
      const spConverted = convertSamplingPoints({ samplingPoints, fromType: coordType.type, timezone: coordType.timezone });
      const newPlaces = spConverted.map(({ name, x, y }, si) => ({
        name,
        geometry: pointToGeoPoint({ simpleGeometry: true, lat: y, lon: x }),
        species: verticalGrid.map((row, ri) => ri > 0 && row[si + 1].value ? { scientificName: row[0].value } : '').filter(v => v),
      }));

      updatePlaces(newPlaces);
      generateReadOnlyGrid(verticalGrid);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onPressReset = () => {
    updatePlaces([]);
    setSamplingPoints([]);
    setSpeciesGridState({ grid: [], isVertical: false });
  };

  const validateSamplingPoints = () => {
    if (samplingPoints.length === 0) {
      return { isValid: false, message: 'Debes agregar a menos un punto de muestreo' };
    }

    if (samplingPoints.filter(s => s.name && s.x && s.y).length !== samplingPoints.length) {
      return { isValid: false, message: 'Tienes puntos de muestreo con datos incompletos' };
    }

    // inefficient since we're converting the points twice (once here, another time on submit)
    // ... hey! wait a second, GridCoordinatesMapFlora *ALSO* converts points?? well... whatever i guess.
    const spConverted = convertSamplingPoints({ samplingPoints, fromType: coordType.type, timezone: coordType.timezone });
    const someCordIsInvalid = spConverted.some(spc => spc.x === null);
    if (someCordIsInvalid) {
      return { isValid: false, message: 'Tienes puntos de muestreo con coordenadas inválidas' };
    }

    // Quizá hay que separar los "sampling points" de "transect points" o algo asi, esto anda pero conceptualmente el código esta enredado
    if (accuracy !== accuracyTypes.TRANSECT) {
      const stationsNamesIsOk = new Set(samplingPoints.map(s => s.name)).size === samplingPoints.length;
      if (!stationsNamesIsOk) {
        return { isValid: false, message: 'No puedes tener nombres de puntos repetidos' };
      }
    }

  };


  const validateSpecies = () => {
    const { grid, isVertical } = speciesGridState;
    const verticalGrid = isVertical ? grid : transposeGrid(grid);

    const speciesList = verticalGrid.slice(1, -1).map(row => row[0].value);

    if (speciesList.length === 0) {
      return { isValid: false, message: 'Debes agregar a menos una especie' };
    }

    if (speciesList.some(name => !name)) {
      return { isValid: false, message: 'No puedes tener nombres de especies vacíos' };
    }

    const speciesNamesIsOk = new Set(speciesList).size === speciesList.length;
    if (!speciesNamesIsOk) {
      return { isValid: false, message: 'No puedes tener nombres de especies repetidos' };
    }

    const emptySpecies = verticalGrid.slice(1, -1).find(row => row.slice(1).every(cell => cell.value === ''));
    if (emptySpecies) {
      return { isValid: false, message: 'Tienes una especie sin puntos de muestreo asignados' };
    }
  };

  const stepsValidations = ({ fromStep }) => {
    let validation = { isValid: true };
    if (fromStep === 0) {
      validation = validateSamplingPoints() || validation;
    } else if (fromStep === 1) {
      validation = validateSpecies() || validation;
    }

    return validation;
  };

  return (
    <Wizard onComplete={ onPressComplete } onReset={ onPressReset } stepsValidations={ stepsValidations } showLastStep>
      <WizardPage title="Puntos de muestreo">
        <GridCoordinatesMapFlora
          coordType={ coordType } setCoordType={ setCoordType } campaignIndex={ campaignIndex } recordIndex={ recordIndex }
          samplingPoints={ samplingPoints } setSamplingPoints={ setSamplingPoints } accuracy={ accuracy }
        />
      </WizardPage>
      <WizardPage title="Cruce de especies">
        { accuracy === accuracyTypes.TRANSECT ?
          <TransectSpeciesLinking samplingPoints={ samplingPoints } state={ speciesGridState } setState={ updateSpeciesGrid } /> :
          <SpeciesLinking samplingPoints={ samplingPoints } state={ speciesGridState } setState={ updateSpeciesGrid }/>
        }
      </WizardPage>
    </Wizard>
  );
};

WizardFlora.propTypes = {
  places: PropTypes.array.isRequired,
  updatePlaces: PropTypes.func.isRequired,
  campaignIndex: PropTypes.number.isRequired,
  recordIndex: PropTypes.number.isRequired,
  accuracy: PropTypes.string.isRequired,
};

export default WizardFlora;
