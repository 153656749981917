import React, { useState, useEffect, useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { archeologyApi } from 'src/services';
import RecordForm from 'src/scenes/Archeology/components/RecordForm';
import Loading from 'src/components/Loading';


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const RecordContainer = ({ register, control, watch, errors, setValue, getValues }) => {
  const classes = useStyles();

  const [ isLoading, setIsLoading ] = useState(true);
  const [ formOptions, setFormOptions ] = useState({});

  const { fields, prepend, remove } = useFieldArray({
    control: control,
    name: 'records',
  });

  // TODO: se obtienen por cada lista de registros!? obtener en ArcheologyForm!
  const getFormOptions = useCallback(async () => {
    try {
      setIsLoading(true);

      const options = await archeologyApi.getFormOptions();
      setFormOptions(options);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      } else {
        console.error(`Problem loading options: ${err}`);
      }
    }
  }, []);

  const handleAddRecord = () => {
    prepend({ name: 'records' });
  };

  useEffect(() => {
    getFormOptions();
  }, [ getFormOptions ]);

  return (
    <div className={classes.root}>
      <Grid className={classes.container} container direction="row" spacing={2}>
        <Grid item xs>
          <Typography variant="h5" component="span" gutterBottom>
            Registros
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleAddRecord}>
            Nuevo Registro
          </Button>
        </Grid>
      </Grid>
      <Grid item container direction='row'>
        <Grid item xs={12} >
          <div >
            {Boolean(errors?.records?.message) &&
              <Alert severity="error">{`${errors?.records?.message}`}</Alert>
            }
          </div>
        </Grid>
      </Grid>

      {isLoading ?
        <Loading /> :
        fields.map(({ id, name }, index) =>
          <RecordForm
            key={id}
            name={name}
            index={index}
            remove={remove}
            formOptions={formOptions}
            register={register}
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
          />,
        )
      }
    </div>
  );
};

RecordContainer.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};


export default RecordContainer;