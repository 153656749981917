import { get, post } from 'src/services/api';


const getRandomProject = async data => (await get('/flora/project-random', data)).data;
const getPriorityProject = async data => (await get('/flora/project-priority', data));
const saveFlora = async data => await post('/flora', data, { headers: { 'Content-Type': 'multipart/form-data' } });


export {
  getRandomProject,
  getPriorityProject,
  saveFlora,
};