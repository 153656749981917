import React from 'react';
import { InputLabel, Chip, TextField, FormHelperText, Box } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';


const MultiChipsInput = React.forwardRef((props, ref) => {
  const { value, onChange, onInputChange, label, helperText, placeholder, id, hasError, errorHelperText, ...restProps } = props;

  const handleChipSelect = async (e, newSelections) => {
    const newVal = newSelections?.length ? newSelections[newSelections.length - 1].trim() : null;
    let finalSelections = [];
    if (newVal) {
      finalSelections = newSelections;
      const finalNewVal = newVal.trim();
      finalSelections[newSelections.length - 1] = finalNewVal;
    } else {
      finalSelections = newSelections.slice(0, -1);
    }

    onChange(finalSelections);
  };


  return <Box>
    <Box my={2}>
      <InputLabel shrink htmlFor={id}>{label}</InputLabel>
    </Box>
    <Autocomplete
      id={id}
      multiple
      freeSolo
      options={[]}
      value={value}
      size="small"
      getOptionLabel={option => option}
      onChange={handleChipSelect}
      onInputChange={onInputChange}
      renderInput={params => (
        <TextField {...params} autoFocus variant="outlined" color="secondary"
          placeholder={placeholder} aria-describedby={`ayuda-${id}`}
        />
      )}
      renderOption={option => option.id}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip key={option} variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      {...restProps}
      ref={ref}
    />
    {helperText && <FormHelperText id={`ayuda-${id}`}>{helperText}</FormHelperText>}
    {hasError && <FormHelperText id={`error-${id}`} error>{errorHelperText}</FormHelperText>}
  </Box>;
});

MultiChipsInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  errorHelperText: PropTypes.string,
  hasError: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
};


export { MultiChipsInput };