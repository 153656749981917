import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Page } from 'src/components';


// TODO: Se deja así mientras aún no haya homes de nada además de arq
const NoiseHome = () => {
  const history = useHistory();
  useEffect(() => {
    history.replace('/app/noise/load-project');
  }, [ history ]);

  return (
    <Page title="Ruido">
      Trabajo en progreso...
    </Page>
  );
};


export { NoiseHome };
