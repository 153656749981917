import * as yup from 'yup';
import { accuracyTypes } from 'src/scenes/Flora/utils';


// typeof NaN is 'number', which makes sense but is also annoying.
const numberNotNaN = num => !Number.isNaN(num) && typeof num === 'number';

const exactPlacestTest = (placesArr, context) => {
  for (let i = 0; i < placesArr.length; i++) {
    const { geometry, species } = placesArr[i];

    const geomIsValid =
      geometry?.coordinates?.length === 2 &&
      numberNotNaN(geometry.coordinates[0]) && numberNotNaN(geometry.coordinates[1]);
    const speciesIsValid = species?.scientificName?.length > 0;

    if (!geomIsValid || !speciesIsValid) {
      return context.createError({ path: context.path, message: 'Tienes filas con datos incompletos o inválidos' });
    }
    // by using a test directly on the places array rather than doing more nested yup.something() calls, we
    // can return the error with its path set to "...places", rather than, say "...places[3].geometry",
    // which is very convenient when deciding when to display error messages.
  }
  return true;
};

const repeatedSpeciesMess = repeats => {
  const len = repeats.length;
  if (len > 1) {
    return `Especies repetidas: las especies en índices ${repeats.slice(0, -1).join(',')} y ${repeats[len - 1]} ya estaban en la tabla`;
  } else {
    return `Especie repetida: la especie en el índice ${repeats[0]} aparece antes en la tabla`;
  }
};

const repeatedSpeciesTest = (speciesArray, context) => {
  const speciesDict = {};
  const repeats = [];
  for (let i = 0; i < speciesArray.length; i++) {
    const speciesVal = speciesArray[i].scientificName;
    const dictVal = speciesDict[speciesVal];
    speciesDict[speciesVal] = dictVal === undefined ? 1 : dictVal + 1;
    if (speciesDict[speciesVal] > 1) {
      repeats.push(i);
    }
  }
  if (repeats.length > 0) {
    // maybe it'd be good to only show the first 5 indices or something, because it may get too long
    // and look silly if there are too many repeats?
    const mess = repeatedSpeciesMess(repeats.map(index => index + 1));
    return context.createError({
      message: mess,
      path: context.path,
      params: { errorExtra: { badIndices: repeats, speciesCount: speciesDict } },
    });
  } else {
    return true;
  }
};


const speciesArraySchema = yup.array(yup.object().shape({ scientificName: yup.string() }))
  .compact().required('Debe haber al menos una especie')
  .test('required', 'Debe haber al menos una especie', val => val?.length > 0)
  .test({
    name: 'noRepeatedSpecies',
    test: function(speciesArray) {
      return repeatedSpeciesTest(speciesArray, this);
    },
  });

const floraRecordSchema = {
  // oneOf includes '' because otherwise oneOf activates rather than required when accuracy==''
  accuracy: yup.string().required('Campo obligatorio').oneOf([ '', ...Object.values(accuracyTypes) ], 'Precisión inválida'),
  places: yup.array()
    .when('accuracy', {
      is: accuracyTypes.PROJECT_AREA,
      then: () => yup.array().of(yup.object().shape({
        species: speciesArraySchema,
      })).compact()
        .required('Datos faltantes en registro').test('required', 'Datos faltantes en registro', val => val?.length > 0),
      otherwise: () => yup.array()
        .when('accuracy', {
          is: accuracyTypes.EXACT,
          then: () => yup.array().compact().required('Datos faltantes en registro')
            .test('required', 'Datos faltantes en registro', val => val?.length > 0)
            .test({
              name: 'placesValidation',
              test: function(placesArr) {
                return exactPlacestTest(placesArr, this);
              },
            }),
          // otherwise, wizard handles validation by itself, but let's validate there's at least a place in there:
          otherwise: () => yup.array().compact()
            .required('Datos faltantes en registro').test('required', 'Datos faltantes en registro', val => val?.length > 0),
        }),
    }),
};

const campaignSchema = {
  name: yup.string(),
  isExcactDate: yup.boolean(),
  date: yup.date().required('Debe ingresar fecha de campaña').nullable()
    .test('beforeTomorrow', 'No pueden haber campañas con una fecha superior a la de hoy', value => value <= new Date(),
    ),
  records: yup.array().of(
    yup.object().shape(floraRecordSchema),
  ).required('Debes ingresar al menos un registro').test('required', 'Debes ingresar al menos un registro', val => val?.length > 0),
};

const floraSchema = yup.object({
  studyYear: yup.number().typeError('Campo obligatorio. Debe ser un año válido') // the actual input is a string thou?
    .min(1990, 'Debes ingresar un año mayor a 1990')
    .max(2100, 'Debes ingresar un año menor a 2100')
    .required('Campo obligatorio'),
  hasBaseline: yup.boolean(),
  baselineFile: yup.mixed().when('hasBaseline', {
    is: true,
    then: () => yup.mixed().nullable().required('Si tiene línea base, debe subir el archivo'),
  }),
  hasFlora: yup.boolean(),
  campaigns: yup.array()
    .when([ 'hasBaseline', 'hasFlora' ], {
      is: (hasBaseline, hasFlora) => hasBaseline && hasFlora,
      then: () => yup.array().of(
        yup.object().shape(campaignSchema),
      ).required('Debe haber al menos una campaña con al menos un registro')
        .test('required', 'Debe haber al menos una campaña con al menos un registro', val => val?.length > 0),
      otherwise: () => yup.array().nullable(),
    }),
  // no validation needed for comments
});

// Which path names refer to arrays of objects that we validate individually?
// (it's not really necessary since you can just always use objects, but I like it when arrays are arrays)
export const arraySchemaIds = [ 'campaigns', 'records', 'places' ];

export default floraSchema;