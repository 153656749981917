import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, FormControlLabel, FormHelperText,
  RadioGroup, Radio, FormControl, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const CoordinatesTypes = ({ direction = 'row', control, errors }) => {
  const classes = useStyles();

  const coordinatesTypes = useWatch({
    control,
    name: 'coordinatesTypes',
    defaultValue: 'LAT-LNG',
  });

  return (
    <Grid container direction={direction} className={classes.container} >
      <Grid item>
        <FormControl error={Boolean(errors.coordinatesTypes)} component="fieldset">
          <FormLabel component="legend">Tipo Coordenada</FormLabel>
          <Controller
            render={({ field }) =>
              <RadioGroup row aria-label="coordinates types" {...field}>
                <FormControlLabel
                  value="LAT-LNG"
                  control={<Radio />}
                  label="LAT-LNG"
                />
                <FormControlLabel
                  value="WSG84"
                  control={<Radio />}
                  label="WSG84"
                />
                <FormControlLabel
                  value="PSAD56"
                  control={<Radio />}
                  label="PSAD56"
                />
              </RadioGroup>
            }
            defaultValue='LAT-LNG'
            name="coordinatesTypes"
            control={control}
          />
          {Boolean(errors.coordinatesTypes) &&
            <FormHelperText>
              {`${errors?.coordinatesTypes?.message}`}
            </FormHelperText>
          }
        </FormControl>
      </Grid>
      {(coordinatesTypes === 'WSG84' || coordinatesTypes === 'PSAD56') &&
        <Grid item>
          <FormControl error={Boolean(errors.zoneNumber)} component="fieldset">
            <FormLabel component="legend">DATUM</FormLabel>
            <Controller
              render={({ field }) =>
                <RadioGroup row aria-label="Huso" {...field}>
                  <FormControlLabel
                    value="18"
                    control={<Radio />}
                    label="18"
                  />
                  <FormControlLabel
                    value="19"
                    control={<Radio />}
                    label="19"
                  />
                </RadioGroup>
              }
              defaultValue="19"
              name="zoneNumber"
              control={control}
            />
            {Boolean(errors.zoneNumber) &&
              <FormHelperText>
                {`${errors?.zoneNumber?.message}`}
              </FormHelperText>
            }
          </FormControl>
        </Grid>
      }
    </Grid>
  );
};

CoordinatesTypes.propTypes = {
  direction: PropTypes.string,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};


export default CoordinatesTypes;