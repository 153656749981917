import React from 'react';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Info as InfoIcon, InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons/';


const useStylesTooltip = makeStyles(() => ({
  tooltip: {
    fontSize: '0.8rem',
    paddingLeft: '0.7rem',
    paddingRight: '0.7rem',
  },
}));

const useStyles = makeStyles(() => ({
  infoIcon: {
    cursor: 'pointer',
  },
  container: {
    display: 'inline-block',
  },
}));


const InfoTooltip = ({ contents, placement, color = 'inherit', outlined = false, toolTipClasses = null, children, ...restProps }) => {
  const ttClasses = useStylesTooltip();
  const classes = useStyles();
  const Icon = outlined ? InfoOutlinedIcon : InfoIcon;

  return <Tooltip classes={toolTipClasses ?? ttClasses} title={typeof contents === 'string' ? <p>{contents}</p> : contents }
    placement={placement} arrow {...restProps}><div className={classes.container}>
      {children ? children : <Icon color={color} fontSize="small" className={classes.infoIcon}/>}
    </div>
  </Tooltip>;
};

InfoTooltip.propTypes = {
  children: PropTypes.any,
  contents: PropTypes.oneOfType([
    PropTypes.string, PropTypes.element,
  ]),
  placement: PropTypes.string,
  color: PropTypes.string,
  outlined: PropTypes.bool,
  toolTipClasses: PropTypes.object,
};


export {
  InfoTooltip,
};
