import React, { memo, useCallback } from 'react';
import { Grid, Button, TextField, Typography, Box, Select, MenuItem, FormControlLabel, Checkbox,
  Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { parseNumber, testDecimalsFormat } from 'src/utils';
import { TypographicInputLabel } from 'src/components';
import * as commons from 'src/scenes/subformCommons/subformCommons';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const getOptionSelected = (option, value) => option.value === value;

const getIdNameStr = (name, id) => {
  const nameStr = name ? `Nombre: ${name}` : '';
  const idStr = id ? `ID: ${id}` : '';
  return name || id ? ` ${nameStr}${name ? '. ' : ''}${idStr}` : '';
};
const stopEventPropagation = event => event.stopPropagation();

const TrialPitForm = memo(({ index, trialPit, slopeOptions, drainageOptions, setForm, setHighlightedFeat, errors }) => {
  const { featId, dataOnWeb, finished } = trialPit;

  const setSubformState = useCallback(({ index, fieldName, data }) => setForm(pf => ({
    ...pf,
    trialPits: pf.trialPits.map((subform, currInd) => index === currInd ?
      ({ ...subform, [fieldName]: data, dataOnWeb: false }) : subform,
    ),
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  })), []);

  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId, isPoint: true });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const onChange = useCallback(e => {
    let data;
    const fieldName = e.target.name;
    if (fieldName === 'slopeUnitIsPercent') {
      data = e.target.value === 'percent';
    } else {
      data = e.target.value;
    }
    setSubformState({ index, fieldName, data });
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onBlurNumber = useCallback(e => {
    setForm(pf => ({
      ...pf,
      trialPits: pf.trialPits.map((tp, currInd) =>
        index === currInd ? ({ ...tp, [e.target.name]: parseNumber(tp[e.target.name], 2) ?? null }) : tp,
      ),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onCheckboxChange = useCallback(e => {
    setSubformState({ index, fieldName: e.target.name, data: e.target.checked });
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const getSlopeLabels = useCallback(value => slopeOptions.labels[value?.value ?? value], [ slopeOptions ]);
  const getDrainageLabels = useCallback(value => drainageOptions.labels[value?.value ?? value], [ drainageOptions ]);

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Calicata #{index + 1}{getIdNameStr(trialPit.name, trialPit.idInProject)}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={trialPit.name} name="name" id={`${featId}-name`} variant="outlined" size="small" onChange={onChange}
            fullWidth autoComplete="off" />
        </Grid>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-id`}>ID</TypographicInputLabel>
          <TextField value={trialPit.idInProject} name="idInProject" id={`${featId}-id`} variant="outlined" size="small"
            onChange={onChange}
            fullWidth
            autoComplete="off" />
        </Grid>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-soilDepth`}>Profundidad del suelo [cm]</TypographicInputLabel>
          <TextField value={trialPit.soilDepth ?? ''} name="soilDepth" id={`${featId}-soilDepth`} variant="outlined" size="small"
            onBlur={onBlurNumber} fullWidth autoComplete="off"
            error={Boolean(errors?.soilDepth)}
            onChange={e => testDecimalsFormat(e.target.value) && onChange(e)}
            helperText={`Profundiad con hasta dos decimales`}/>
        </Grid>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-waterTableDepth`}>Nivel freático [cm]</TypographicInputLabel>
          <TextField
            variant="outlined" size="small" autoComplete="off"
            name={'waterTableDepth'}
            value={trialPit.waterTableDepth ?? ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value) && onChange(e)}
            error={Boolean(errors?.waterTableDepth)}
            helperText={`Nivel con hasta dos decimales. ${
              errors?.waterTableDepth ? errors.waterTableDepth.errorMessage : ''}`}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${featId}-slopeClasses`}>Clases de pendiente</TypographicInputLabel>
          </Box>
          <Box my={1}>
            <Autocomplete
              multiple
              value={trialPit.slopeClasses}
              name={'slopeClasses'}
              options={slopeOptions.options}
              getOptionLabel={getSlopeLabels}
              getOptionSelected={getOptionSelected}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  id={`${featId}-slopeClasses`}
                  variant="outlined"
                  placeholder={'Selecciona clases'}
                  error={Boolean(errors?.slopeClasses)}
                  helperText={errors?.slopeClasses?.errorMessage}
                />
              )}
              onChange={(event, data) => {
                onChange({ target: { name: 'slopeClasses', value: data.map(opt => typeof opt === 'string' ? opt : opt.value) } });
              }}
            />
          </Box>
        </Grid>

        <Grid item md={4} xs={4}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${featId}-slopeUnitIsPercent`}>Unidad de la pendiente</TypographicInputLabel>
          </Box>
          <Box my={1}>
            <Select
              variant="outlined"
              name={'slopeUnitIsPercent'}
              value={trialPit.slopeUnitIsPercent ? 'percent' : 'degrees' }
              onChange={onChange}
              fullWidth
            >
              <MenuItem value="degrees">Grados</MenuItem>
              <MenuItem value="percent">Porcentaje</MenuItem>
            </Select>
          </Box>
        </Grid>

        <Grid item md={4} xs={4}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${featId}-slopeRangeLowerLimit`}>Pendiente inferior</TypographicInputLabel>
          </Box>
          <Box my={1}>
            <TextField
              variant="outlined"
              name={'slopeRangeLowerLimit'}
              value={trialPit.slopeRangeLowerLimit ?? ''}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={e => (/^\d*$/.test(e.target.value)) && onChange(e)}
              error={Boolean(errors?.slopeRangeLowerLimit)}
              helperText={`Escriba un número entero. ${
                errors?.slopeRangeLowerLimit ? errors.slopeRangeLowerLimit.errorMessage : ''}`}
              onBlur={onBlurNumber}
              fullWidth
            />
          </Box>
        </Grid>

        <Grid item md={4} xs={4}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${featId}-slopeRangeLowerLimit`}>Pendiente superior</TypographicInputLabel>
          </Box>
          <Box my={1}>
            <TextField
              variant="outlined"
              name={'slopeRangeUpperLimit'}
              value={trialPit.slopeRangeUpperLimit ?? ''}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={e => (/^\d*$/.test(e.target.value)) && onChange(e)}
              error={Boolean(errors?.slopeRangeUpperLimit)}
              helperText={`Escriba un número entero. ${
                errors?.slopeRangeUpperLimit ? errors.slopeRangeUpperLimit.errorMessage : ''}`}
              onBlur={onBlurNumber}
              fullWidth
            />
          </Box>
        </Grid>

        <Grid item md={12} xs={12}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${featId}-drainage`}>Drenaje</TypographicInputLabel>
          </Box>
          <Box my={1}>
            <Autocomplete
              value={trialPit.drainage}
              name={'drainage'}
              options={drainageOptions.options}
              getOptionLabel={getDrainageLabels}
              getOptionSelected={getOptionSelected}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  id={`${featId}-drainage`}
                  variant="outlined"
                  placeholder={'Drenaje'}
                  error={Boolean(errors?.drainage)}
                  helperText={errors?.drainage?.errorMessage}
                />
              )}
              onChange={(event, newValue) => {
                onChange({ target: { name: 'drainage', value: newValue === null ? null : newValue.value } });
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>;
});

TrialPitForm.propTypes = {
  trialPit: PropTypes.object,
  index: PropTypes.number,
  slopeOptions: PropTypes.object,
  drainageOptions: PropTypes.object,
  setForm: PropTypes.func,
  setHighlightedFeat: PropTypes.func,
  errors: PropTypes.object,
};

TrialPitForm.displayName = 'TrialPitForm';


export { TrialPitForm };