import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography, Paper, FormControlLabel, Checkbox,
  Accordion, AccordionSummary, AccordionDetails, List, ListItem } from '@material-ui/core';
import { ProjectYearSelection } from 'src/scenes/LoadProject/components';
import { Alert } from '@material-ui/lab';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Loading from 'src/components/Loading';
import soilSchema, { arraySchemaIds } from 'src/scenes/Soil/soilValidationSchema';
import { Map, Page, Section, Project, DialogWrapper, LeaveConfirm, ObservationsSection, AlertsList } from 'src/components';
import { filterGeomTypeFromGeoJson, isInvalidGeometry, geometryToFeature,
  separateCollectionsFromFeatureCollection, makeErrors } from 'src/utils';
import { SoilUnitForm, TrialPitForm, RemoveGeomsDialog } from 'src/scenes/Soil/components';
import { projectApi, soilApi, optionsApi } from 'src/services';
import { useMustLeave, useMustReload } from 'src/hooks';


const typeKey = 'soil';

const DialogContents = ({ type, actions }) => {
  switch (type) {
    case 'remove-geoms': {
      return <RemoveGeomsDialog
        confirmFn={actions.deleteGeoJson}
        closeDialog={actions.closeDialog}
        type="soil"
      />;
    }
    default:
      break;
  }
};

DialogContents.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object,
  actions: PropTypes.object,
};


const makeEmptyTrialPit = ({ featId, name = '', idInProject = '', dataOnWeb = false }) => ({
  featId, // id de la feature en la featureCollection (con el índice está bien)
  name,
  idInProject,
  waterTableDepth: null,
  soilDepth: null,
  slopeRangeUpperLimit: null,
  slopeRangeLowerLimit: null,
  slopeUnitIsPercent: false,
  slopeClasses: [],
  drainage: null,
  dataOnWeb,
  finished: false,
});

const makeEmptySoilUnit = ({ featId, name = '', idInProject = '', dataOnWeb = false }) => ({
  featId,
  name,
  idInProject,
  useCapacity: [],
  vegetationCover: null,
  use: '',
  erosion: '',
  chemicalCharacteristics: '',
  biodiversityCapacity: null,
  dataOnWeb,
  finished: false,
});

const featurePopUp = feature => {
  const fProps = feature.properties;
  return `<div>
    ${fProps.unitNumber ? 'Unidad #' : 'Calicata #' }${fProps.unitNumber ?? fProps.pitNumber}
  </div>`;
};

const defaultLayerColor = '#3388ff';
const highlightedPolygonColor = 'rgba(32, 250, 250, 1)';
const highlightedPointClass = 'highlighted-point';

const highlightLayer = (layer, isPoint) => {
  if (isPoint) {
    // un solo punto
    if (layer._icon) {
      layer._icon.classList.add(highlightedPointClass);
    } else if (layer._layers) {
      Object.values(layer._layers).forEach(lyr => {
        lyr._icon.classList.add(highlightedPointClass);
      });
    }
    return;
  }
  // polígono:
  layer.setStyle({ color: highlightedPolygonColor });
};

const unhighlightLayer = (layer, isPoint) => {
  if (isPoint) {
    // un solo punto
    if (layer._icon) {
      layer._icon.classList.remove(highlightedPointClass);
    } else if (layer._layers) {
      Object.values(layer._layers).forEach(lyr => {
        lyr._icon.classList.remove(highlightedPointClass);
      });
    }
    return;
  }
  // polígono:
  layer.setStyle({ color: defaultLayerColor });
};

const accordionTransitionPropObject = { unmountOnExit: true };

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
    maxWidth: 300,
  },
  submitButton: {
    display: 'block',
    marginTop: theme.spacing(5),
  },
  comments: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
  },
  soilPaper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  pitPaper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  accordionSummary: {
    backgroundColor: '#eee',
    marginBottom: theme.spacing(3),
  },
  hr: {
    marginLeft: 0,
    width: '25%',
  },
  success: {
    color: 'green',
  },
  pending: {
    color: theme.palette.text.secondary,
  },
  inProgress: {
    color: theme.palette.text.secondary,
  },
}));

const SoilForm = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { seaProjectId } = match.params;

  const history = useHistory();
  const pushProjectToHistory = seaProject => history.push({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const replaceProjectToHistory = seaProject => history.replace({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const { state: locationState } = useLocation();
  const [ seaProject, setSeaProject ] = useState(locationState?.seaProject);
  const [ sendingData, setSendingData ] = useState(false);
  const [ dialog, setDialog ] = useState({ isOpen: false, type: null, data: null, actions: null });
  const [ kmlFileNames, setKmlFileNames ] = useState([]);

  // tiene objetos de la forma { type: 'error'|'warning', message: string, key?: string}
  const [ kmlAlerts, setKmlAlerts ] = useState([]);

  const [ form, setForm ] = useState({
    geoJson: null,
    homogenousSoilUnits: [],
    trialPits: [],
    badData: false,
    comments: '',
  });

  const layersRef = useRef({});

  // { isPoint, index } índice relativo al geojson y si es punto o no
  const highlightedFeat = useRef(null);

  const onEachFeature = (feature, layer) => {
    layer.bindPopup(featurePopUp(feature));
    layersRef.current[feature.properties.id] = layer;
  };

  // índice relativo a las listas de calicatas o unidades homogéneas
  const setHighlightedFeat = useCallback(({ featId, isPoint = false, unhighlightPrevious = true, toggleSelf = true }) => {
    const prevHighlighted = highlightedFeat.current;
    const prevSameAsFeatId = prevHighlighted?.index === featId;
    if (prevHighlighted != null && ((toggleSelf && prevSameAsFeatId) || (unhighlightPrevious && !prevSameAsFeatId))) {
      unhighlightLayer(layersRef.current[prevHighlighted.index], prevHighlighted.isPoint);
    }
    if (featId === null || (prevSameAsFeatId && toggleSelf)) {
      highlightedFeat.current = null;
      return;
    }
    const layer = layersRef.current[featId];
    highlightLayer(layer, isPoint);
    highlightedFeat.current = { index: featId, isPoint };
  }, []);

  // cosas para prevenir que maten la página por accidente cuando tienen datos no guardados:
  // TODO: por ahora en verdad no aporta mucho esta ref, pero la dejaré viva por si hacemos edición/guardar progreso
  const lastSavedCommentsRef = useRef('');
  const checkLeaveConfirmNeeded = () => !mustLeave &&
    form.comments !== lastSavedCommentsRef.current ||
    form.homogenousSoilUnits.some(hsu => !hsu.dataOnWeb) || form.trialPits.some(tp => !tp.dataOnWeb);

  const [ loadingProject, setLoadingProject ] = useState(!seaProject);
  const [ loadedFormDataAndOptions, setLoadedFormDataAndOptions ] = useState(false);
  const [ formOptions, setFormOptions ] = useState(null);
  const [ projectYear, setProjectYear ] = useState(seaProject?.year ?? null);
  const [ randomProjectNotFound, setRandomProjectNotFound ] = useState(false);
  const dbDataRef = useRef(null);

  const { mustLeave, leavePage } = useMustLeave({ history });
  const { mustReload, reloadPage } = useMustReload();

  const closeDialog = useCallback(() => setDialog({ isOpen: false, type: null, data: null, actions: null }), []);

  useEffect(() => {
    if (seaProjectId) {
      const fetchFormDataAndOptions = async () => {
        try {
          const [ prevData, biodiversityCapacities, slopeClasses, drainages ] = await Promise.all([
            soilApi.getData(seaProjectId),
            optionsApi.getBiodiversityCapacities(),
            optionsApi.getSlopeClasses(),
            optionsApi.getDrainages(),
          ]);

          if (prevData) {
            const features = [ ...Array(prevData.homogenousSoilUnits.length + prevData.trialPits.length) ];
            const homogenousSoilUnits = [];
            const trialPits = [];
            for (let ind = 0; ind < prevData.homogenousSoilUnits.length; ind++) {
              const { geometry, ...restUnit } = prevData.homogenousSoilUnits[ind];
              const { name, idInProject, featId } = restUnit;
              homogenousSoilUnits.push({ ...restUnit, dataOnWeb: true }); // la bd las manda ordenadas, debería recuperar el orden original
              features[restUnit.featId] = geometryToFeature({
                geometry, id: featId, properties: { name, idInProject, featId, unitNumber: ind + 1 },
              });
            }
            for (let ind = 0; ind < prevData.trialPits.length; ind++) {
              const { geometry, ...restPit } = prevData.trialPits[ind];
              const { name, idInProject, featId } = restPit;
              trialPits.push({ ...restPit, dataOnWeb: true }); // la bd las manda ordenadas, así que debería recuperar el orden original
              features[restPit.featId] = geometryToFeature({
                geometry, id: featId, properties: { name, idInProject, featId, pitNumber: ind + 1 },
              });
            }

            setForm({
              geoJson: {
                type: 'FeatureCollection',
                features,
              },
              homogenousSoilUnits,
              trialPits,
              comments: prevData.comments,
              badData: prevData.badData,
            });
            lastSavedCommentsRef.current = prevData.comments;
          }

          dbDataRef.current = prevData;
          setFormOptions({
            biodiversityCapacities: {
              options: biodiversityCapacities,
              labels: biodiversityCapacities.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.label }), {}),
            },
            slopeClasses: {
              options: slopeClasses,
              labels: slopeClasses.reduce((acc, curr) => ({ ...acc, [curr.value]: `${curr.shortLabel} - ${curr.label}` }), {}),
            },
            drainages: {
              options: drainages,
              labels: drainages.reduce((acc, curr) => ({ ...acc, [curr.value]: `${curr.shortLabel} - ${curr.label}` }), {}),
            },
          });
          setLoadedFormDataAndOptions(true);
        } catch (e) {
          console.log(e);
          toast.error(
            e.serverMessage ?? e.serviceMessage ?? 'Hubo un error al cargas los datos del formulario, por favor intenta más tarde',
          );
        }
      };
      fetchFormDataAndOptions();
    } else {
      getPrioritizedProject();
    }
  // eslint-disable-next-line
  }, [ seaProjectId ]);

  // TODO: estado de error y mensaje de error para esto
  useEffect(() => {
    if (seaProjectId && !seaProject) {
      const fetchData = async () => {
        setLoadingProject(true);
        const { seaProject } = await projectApi.getProjectBySeaId(seaProjectId);
        setSeaProject(seaProject);
        setLoadingProject(false);
      };
      fetchData();
    }
  }, [ seaProjectId, seaProject ]);

  const getPrioritizedProject = async () => {
    try {
      const result = (await projectApi.getPriorityProject({ type: typeKey }))?.data;
      if (result) {
        const { seaProject } = result;
        setSeaProject(result.seaProject);
        if (!seaProject) {
          setLoadingProject(false);
        } else {
          setLoadingProject(false);
          replaceProjectToHistory(seaProject);
        }
      } else {
        setLoadingProject(false);
      }
    } catch (err) {
      setLoadingProject(false);
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      }
      console.error(err);
    }
  };

  const getRandomizedProject = async year => {
    setLoadingProject(true);
    setRandomProjectNotFound(false);
    try {
      const result = (await projectApi.getRandomProject({ year, type: typeKey }))?.data;
      const { seaProject } = result || {};
      if (!seaProject) {
        setRandomProjectNotFound(true);
        setLoadingProject(false);
      } else {
        setSeaProject(seaProject);
        setLoadingProject(false);
        pushProjectToHistory(seaProject);
      }
    } catch (err) {
      toast.error(err.serverMessage ?? err.serviceMessage ?? 'Hubo un error al pedir el proyecto. Por favor intenta más tarde');
      console.error(err);
    }
  };

  const onChangeProjectYear = ({ projectYear }) => {
    setProjectYear(projectYear);
    getRandomizedProject(projectYear);
  };

  const [ errors, setErrors ] = useState({});

  const onSubmitGenerator = (stayHere = false) => async () => {
    if (sendingData || mustLeave) {
      return;
    }
    try {
      setErrors({});
      form.seaId = seaProjectId;
      await soilSchema.validate(form, { abortEarly: false });
      setSendingData(true);
      toast.info('Guardando la información');
      form.seaId = seaProjectId;
      // TODO: detectar cambios y enviar solo aquellos con cambios?
      // const unsavedDataForm = {
      //   ...form,
      //   homogenousSoilUnits: form.homogenousSoilUnits.filter(hsu => !hsu.dataOnWeb),
      //   trialPits: form.trialPits.filter(hsu => !hsu.dataOnWeb),
      // };

      const { message } = await soilApi.saveForm(form);

      lastSavedCommentsRef.current = form.comments;
      setSendingData(false);
      toast.dismiss();
      toast.success(message);
      if (!stayHere) {
        leavePage();
      } else {
        reloadPage();
      }
    } catch (e) {
      toast.dismiss();
      setSendingData(false);
      if (e.name === 'ValidationError') {
        toast.error(<div>Hay problemas con el formulario.<br/>Por favor revisar</div>,
          { autoClose: 10000, allowHtml: true },
        );
        const formErrors = makeErrors(e, arraySchemaIds);
        console.error(`Problem submit form: ${e}`);
        console.error({ formErrors });
        setErrors(formErrors);
      } else {
        console.error(e);
        toast.error(e.serverMessage ?? e.serviceMessage ?? 'Ocurrió un error inesperado, por favor inténtalo más tarde');
      }
    }
  };

  const onSubmit = onSubmitGenerator(false);
  const onSubmitReloadPage = onSubmitGenerator(true);

  const onCommentUpdate = useCallback(comments => setForm(pf => ({ ...pf, comments })), []);
  const onBadDataUpdate = useCallback(event => setForm(pf => ({ ...pf, badData: event.target.checked })), []);

  const deleteGeoJson = useCallback(() => {
    setForm(pf => ({
      ...pf, geoJson: null, homogenousSoilUnits: [], trialPits: [],
      ...(dbDataRef.current && { deletePreviousData: true }), // al enviar, se borrarán los datos ya existentes
    }));
    setKmlAlerts([]);
    setHighlightedFeat({ featId: null });
    setKmlFileNames([]);
  }, [ setHighlightedFeat ]);

  const openDeleteGeoJsonDialog = useCallback(() =>
    setDialog({ isOpen: true, type: 'remove-geoms', actions: { closeDialog, deleteGeoJson } })
  , [ closeDialog, deleteGeoJson ]);

  const handleGeoJson = ({ geoJson, kmlFileName }) => {
    if (kmlFileNames.includes(kmlFileName)) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `Ya ha sido cargado un archivo con el nombre ${kmlFileName}` },
      ]);
      return;
    }

    if (!geoJson.features?.length) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `El KML cargado no parece contener geometrías o no se pudieron analizar sus geometrías` },
      ]);
    }

    const validTypes = [ 'Polygon', 'Point', 'MultiPolygon', 'MultiPoint' ];
    const { geoJson: filteredGeoJson, changed, changedObj } = filterGeomTypeFromGeoJson({ geoJson, validTypes });

    // Dejaré este log a propósito por si nos sirve para debuggear:
    if (Object.keys(changedObj).length) {
      console.warn(changedObj);
    }
    if (!filteredGeoJson?.features?.length) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `El KML cargado no parece tener ni polígonos ni puntos` },
      ]);
      return;
    } else if (changed) {
      // Si se filtró algo del ḰML, advertir y continuar.
      setKmlAlerts(ps => {
        let typeFilterWarningActive = false;
        let nullFilterWarningActive = false;
        const finalList = ps.filter(w => {
          typeFilterWarningActive |= w.key === 'type-filter';
          nullFilterWarningActive |= w.key === 'null-filter';
          return w.type === 'warning';
        });

        const newWarnings = [];
        for (const changeKey in changedObj) {
          if (changeKey === 'null' && !nullFilterWarningActive) {
            newWarnings.push({
              type: 'warning',
              message: `Se encontraron y filtraron geometrías vacías. Considera revisar el KML si esto no te parece correcto`,
              key: 'null-filter',
            });
          } else if (changeKey !== 'null' && !typeFilterWarningActive) {
            newWarnings.push({
              type: 'warning', message: `Se han filtrado geometrías que no eran ni polígonos ni puntos`, key: 'type-filter',
            });
          }
        }
        return [ ...finalList, ...newWarnings ];
      });
    } else {
      setKmlAlerts(pa => {
        // Limpiamos errores de kmls pasados que no puedieron subirse, pero dejamos las warnings (que son de kmls que sí se subieron)
        const warnings = pa.filter(alert => alert.type === 'warning');
        return warnings;
      });
    }

    // procesar geojson para separar distintos tipos de geometrías de posibles GeometryCollections
    const processedGeoJson = separateCollectionsFromFeatureCollection({ featureCollection: filteredGeoJson });
    // polígonos:
    const soilUnits = [];
    // puntos:
    const trialPits = [];
    for (let i = 0; i < processedGeoJson.features.length; i++) {
      const feature = processedGeoJson.features[i];
      const { properties } = feature;

      let name;
      let idInProject;

      Object.keys(properties).forEach(key => {
        const normalizedKey = key.toLowerCase().trim();
        if (normalizedKey === 'nombre' || normalizedKey === 'name') {
          name = properties[key]?.toString()?.trim();
        } else if (normalizedKey === 'id') {
          idInProject = properties[key]?.toString()?.trim();
        }
      });

      const nameStr = name ? `Nombre: ${name}` : '';
      const idStr = idInProject ? `ID: ${idInProject}` : '';
      const nameIdStr = name || idInProject ? `(${nameStr}${name ? '. ' : ''}${idStr})` : '';
      if (isInvalidGeometry(feature, { allowMulti: true })) {
        setKmlAlerts([ { type: 'error', message: `Se encontró una geometría con coordenadas inválidas ${nameIdStr}` } ]);
        return;
      }

      const featId = i + (form.geoJson?.features?.length ?? 0);
      const featProps = { name, idInProject, id: featId };
      const featType = feature.geometry?.geometries?.[0].type ?? feature.geometry?.type;

      if (featType.includes('Poly')) {
        soilUnits.push(makeEmptySoilUnit({ featId, name, idInProject }));
        featProps.unitNumber = soilUnits.length;
      } else if (featType.includes('Point')) {
        trialPits.push(makeEmptyTrialPit({ featId, name, idInProject }));
        featProps.pitNumber = trialPits.length;
      }

      processedGeoJson.features[i] = { ...feature, properties: featProps };
    }

    setForm(pf => ({
      ...pf,
      geoJson: {
        type: 'FeatureCollection',
        features: [ ...(pf.geoJson?.features ?? []), ...processedGeoJson.features ],
      },
      homogenousSoilUnits: [ ...pf.homogenousSoilUnits, ...soilUnits ],
      trialPits: [ ...pf.trialPits, ...trialPits ],
    }));
    setKmlFileNames(pf => [ ...pf, kmlFileName ]);
    return;
  };

  const formStatus = useMemo(() => {
    if (!loadedFormDataAndOptions) {
      return null;
    }
    const dbData = dbDataRef.current;
    if (dbData) {
      if (!form.comments && !form.geoJson && !form.badData) {
        return { message: 'Pendiente', status: 'pending', updatedAt: dbData.updatedAt };
      } else if (form.geoJson && (form.homogenousSoilUnits.some(hsu => !hsu.finished) || form.trialPits.some(tp => !tp.finished))) {
        return {
          message: 'En progreso (faltan datos de calicatas o unidades homogéneas por llenar)',
          status: 'inProgress',
          updatedAt: dbData.updatedAt,
        };
      } else {
        return { message: 'Enviado', status: 'success', updatedAt: dbData.updatedAt };
      }
    } else {
      return { message: 'Pendiente', status: 'pending' };
    }
  // eslint-disable-next-line -- Todas las dependencias están bien en el punto en que este valor cambia
  }, [ loadedFormDataAndOptions ])

  return loadingProject ? <Loading/> : <>
    {!seaProject && <>
      <ProjectYearSelection formType={typeKey} projectYear={projectYear} updateState={onChangeProjectYear} />
      { randomProjectNotFound && <Typography variant="body1">No se encontró proyecto para el año seleccionado</Typography> }
    </>}
    { seaProject && (!loadedFormDataAndOptions ? <Loading/> : <>
      <Section title="Proyecto">
        { !mustLeave && !mustReload && <LeaveConfirm checkConfirmNeeded={checkLeaveConfirmNeeded}/> }
        <Box>
          <Project project={seaProject} formType={typeKey} formStatus={formStatus}></Project>
        </Box>
      </Section>
      <Page title={`Suelo - ${seaProject.nombre}`}>
        <Box display='flex' mt={ 4 } mb={ 1 } justifyContent='space-between'>
          <Typography variant="h4" gutterBottom>
            Suelo
          </Typography>
        </Box>
        <Paper className={classes.paper}>
          { errors.geoJson &&
            <Box px={4} mb={1}><Alert severity="error">{errors.geoJson.errorMessage}</Alert></Box>
          }
          { kmlFileNames.length > 0 &&
            <Box>
              <Typography variant="h6" component="span" color="textSecondary">
                Documentos:
              </Typography>
              <List>
                {kmlFileNames.map((filename, i) => (
                  <Box key={`filename-${i}`}>
                    <ListItem alignItems="flex-start">
                      - {filename}
                    </ListItem>
                  </Box>
                ))}
              </List>
            </Box>
          }
          <Box px={4}>
            <Map nameId={'soilMap'} geoJson={form.geoJson} handleGeoJson={handleGeoJson} onEachFeature={onEachFeature}
              importKml={true} deleteGeoJson={openDeleteGeoJsonDialog} mapHeight={'24rem'} allowMulti
            />
          </Box>
          <Box mx={4} my={2}>
            { Boolean(kmlAlerts.length) &&
              <AlertsList alerts={kmlAlerts} />
            }
          </Box>
          <Box>
            <FormControlLabel
              control={ <Checkbox color="primary" checked={form.badData} onChange={onBadDataUpdate} /> }
              labelPlacement="start"
              label="¿Datos erróneos o no disponibles?"
            />
          </Box>
        </Paper>
        { Boolean(form.homogenousSoilUnits.length) && <Box mt={2}>
          <Accordion defaultExpanded={true} TransitionProps={accordionTransitionPropObject}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5"gutterBottom>
                Unidades homogéneas de suelo <small>({form.homogenousSoilUnits.length})</small>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* No sé porqué este 100% es necesario aquí <.< */}
              <Box width='100%'>
                { form.homogenousSoilUnits.map((hsu, index) =>
                  <SoilUnitForm key={hsu.featId} index={index} setHighlightedFeat={setHighlightedFeat}
                    {...hsu} unitNumber={form.geoJson.features[hsu.featId].properties.unitNumber}
                    biodiversityCapacityOptions={formOptions.biodiversityCapacities}
                    setForm={setForm} errors={errors.homogenousSoilUnits?.[index]}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>}
        { Boolean(form.trialPits.length) && <Box mt={2}>
          <Accordion defaultExpanded={true} TransitionProps={accordionTransitionPropObject}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5"gutterBottom>Calicatas <small>({form.trialPits.length})</small></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { form.trialPits.map((tp, index) =>
                  <TrialPitForm trialPit={tp} index={index} key={`tp-${index}`} setHighlightedFeat={setHighlightedFeat}
                    slopeOptions={formOptions.slopeClasses} drainageOptions={formOptions.drainages}
                    setForm={setForm} errors={errors.trialPits?.[index]}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>}
        <ObservationsSection onChange={e => onCommentUpdate(e.target.value)} value={form.comments} className={classes.comments} />
        { errors.geoJson &&
          <Box>
            <Alert severity="error">{errors.geoJson.errorMessage}</Alert>
          </Box>
        }
        <Box display="flex" flex={2}>
          <Box mx={1.5}>
            <Button className={classes.submitButton} type="button" variant="contained" color="secondary"
              disabled={sendingData || mustLeave} onClick={onSubmitReloadPage} >
              Guardar progreso
            </Button>
          </Box>
          <Box>
            <Button className={classes.submitButton} type="button" variant="contained" color="primary"
              disabled={sendingData || mustLeave } onClick={onSubmit} >
              Enviar
            </Button>
          </Box>
        </Box>
        <DialogWrapper maxWidth='sm' fullWidth onClose={closeDialog} open={dialog.isOpen}>
          { dialog.isOpen && <DialogContents type={dialog.type} actions={dialog.actions} data={dialog.data} /> }
        </DialogWrapper>
      </Page>
    </>)}
  </>;
};


export { SoilForm };
