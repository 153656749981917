const setUser = user => localStorage.setItem('user', JSON.stringify(user));
const getUser = () => JSON.parse(localStorage.getItem('user') || '{}');
const cleanUser = () => localStorage.removeItem('user');

const setBaseline = baseline => localStorage.setItem('baseline', baseline);
const getBaseline = () => localStorage.getItem('baseline') || '';
const cleanBaseline = () => localStorage.removeItem('baseline');

export {
  setUser,
  getUser,
  cleanUser,
  setBaseline,
  getBaseline,
  cleanBaseline,
};
