import { get, post, del } from 'src/services/api';


const getRandomProject = async data => (await get('/project/project-random', data)).data;
const getComponentList = async data => (await get('/areas-of-influence/component-list', data)).data;
const saveForm = async data => await post('/areas-of-influence', data);
const getCurrentWork = async projectId => (await get(`/areas-of-influence/current-work/${projectId}`)).data;
const deleteComponent = async data => (await del(`/areas-of-influence/delete-component`, data)).data;


export {
  getRandomProject,
  getComponentList,
  saveForm,
  getCurrentWork,
  deleteComponent,
};