import React, { useEffect, useState } from 'react';
import { Grid, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Map } from 'src/components';
import { GridCoordinatesGeometryProj } from 'src/scenes/LoadProject/components';


const GridCoordinatesMapProj = ({
  index,
  importKml,
  coordType,
  features,
  geoJson,
  updateState }) => {

  const [ fromKml, setFromKml ] = useState(false);
  const [ showBadDataCheckbox, setShowBadDataCheckbox ] = useState(false);
  const [ isBadDataGlobal, setIsBadDataGlobal ] = useState(false);

  const mapName = `map${index}`;

  const cleanAllGeometriesAndGeoJson = () => {
    updateState({ features: [] });
  };

  const handleGeoJson = ({ importFromKml = false, geoJson = {} }) => {
    if (importFromKml) {
      // TODO: esto hace update de las features, luego se activa el useEffect de abajo, luego se llama esta misma función...
      // creo que el hecho de que esto funcione es suerte ya que react podría en vez de eso batchear los updates?
      // y creo que en otro componente que estaba haciendo esto mismo esto me falló... es medio desastroso el flujo este.
      cleanAllGeometriesAndGeoJson();
      setFromKml(true);
      setIsBadDataGlobal(false);
      updateState({
        geoJson: {
          'type': 'FeatureCollection',
          features: geoJson.features.map(({ properties, ...restOfGeojson }) => ({
            properties: {
              ...properties,
              isBadData: isBadDataGlobal,
            },
            ...restOfGeojson,
          })),
        },
      });
      setShowBadDataCheckbox(true);
      return;
    }
    //Check old status for clean the map from the last geoJson if came from kml
    if (fromKml) {
      updateState({ geoJson: {} });
    }
    setFromKml(false);
    updateState({ geoJson: { 'type': 'FeatureCollection', features } });
  };

  useEffect(() => {
    handleGeoJson({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ features ]);

  const onPressNewGeometry = () => {
    setShowBadDataCheckbox(false);
    if (features.length > 0) {
      updateState({ features: [ ...features, [] ] });
    } else {
      updateState({ features: [ [] ] });
    }
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6}>
        <Button variant="contained" color="primary" onClick={ onPressNewGeometry }>
          Nueva Geometría
        </Button>
        <Grid item container direction="column">
          {
            features.map((elem, index) =>
              <GridCoordinatesGeometryProj
                key={index}
                index={index}
                coordType={coordType}
                features={features}
                updateState={updateState}
                isBadDataGlobal={isBadDataGlobal}
              />,
            )
          }
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Map key={index} nameId={mapName} geoJson={geoJson} handleGeoJson={handleGeoJson} importKml={importKml} />
        {
          showBadDataCheckbox &&
            <FormControlLabel
              control={<Checkbox color="primary" />}
              value={isBadDataGlobal}
              labelPlacement="start"
              label="¿Datos erróneos?"
              onChange={ e => setIsBadDataGlobal(e.target.checked) }
            />
        }
      </Grid>
    </Grid>
  );
};

GridCoordinatesMapProj.propTypes = {
  errors: PropTypes.object.isRequired,
  updateState: PropTypes.func,
  importKml: PropTypes.bool,
  index: PropTypes.number,
  geoJson: PropTypes.object,
  coordType: PropTypes.object,
  features: PropTypes.array,
};


export default GridCoordinatesMapProj;