import * as yup from 'yup';
import { isInvalidGeometry } from 'src/utils';


const recordSchema = {
  code: yup.string().required('Campo obligatorio'),
  generalCategory: yup.string().required('Campo obligatorio'),
  // TODO: acá se necesita un test además del required porque el Autocomplete dentro del MultiChipsInput se enoja cuando tiene valores
  // nulls, así que su valor vacío es [], lo que no da errores contra el required(). Deberíamos ver si estandarizamos que los arrays
  // vacios sean [] en vez de nulls (que me parece razonable) o si cambiamos el MultiChips para que no use autocomplete
  // (usé ese componente para sacar la cosa rápido, así que esto también me parece razonable)
  specificCategories: yup.array().of(yup.string())
    .required('Campo obligatorio').test('isRequired', 'Campo obligatorio', value => value?.length > 0),
  periods: yup.array().of(yup.string()).required('Campo obligatorio').test('isRequired', 'Campo obligatorio', value => value?.length > 0),
  functionalities: yup.array().of(yup.string())
    .required('Campo obligatorio').test('isRequired', 'Campo obligatorio', value => value?.length > 0),
  movableHeritages: yup.array().of(yup.string())
    .required('Campo obligatorio').test('isRequired', 'Campo obligatorio', value => value?.length > 0),
  immovableHeritages: yup.array().of(yup.string())
    .required('Campo obligatorio').test('isRequired', 'Campo obligatorio', value => value?.length > 0),
  alterationTypes: yup.array().of(yup.string())
    .required('Campo obligatorio').test('isRequired', 'Campo obligatorio', value => value?.length > 0),
  conservationState: yup.string().required('Campo obligatorio'),
  measures: yup.array().of(yup.string())
    .required('Campo obligatorio').test('isRequired', 'Campo obligatorio', value => value?.length > 0),
  geoJson: yup.object().shape({
    features: yup.array()
      .required('Agrega al menos una geometría, campo obligatorio').test('isRequired', 'Campo obligatorio', value => value?.length > 0),
  }).required(),
};

const archeologySchema = yup.object().shape({
  studyYear: yup.number().typeError('Debes ingresar un año valido')
    .min(1990, 'Debes ingresar un año mayor a 1990')
    .max(2100, 'Debes ingresar un año menor a 2100')
    .required('Campo obligatorio'),
  baseline: yup.boolean(),
  baselineFile: yup.mixed().when('baseline', {
    is: true,
    then: () => yup.mixed().test('isRequired', 'Campo obligatorio', value => value?.length > 0),
  }),
  archeologists: yup.mixed().when('baseline', {
    is: true,
    then: () => yup.mixed().when('studyYear', {
      is: studyYear => studyYear <= 2012,
      then: () => yup.array().of(yup.string())
        .required('Campo obligatorio')
        .test('isRequired', 'Campo obligatorio', value => value?.length > 0),
    }),
  }),
  paleontologicalBaseline: yup.boolean(),
  paleontologicalBaselineFile: yup.mixed().when('paleontologicalBaseline', {
    is: true,
    then: () => yup.mixed().test('isRequired', 'Campo obligatorio', value => value?.length > 0),
  }),
  pas132: yup.boolean(),
  pas132File: yup.mixed().when('pas132', {
    is: true,
    then: () => yup.mixed().test('isRequired', 'Campo obligatorio', value => value?.length > 0),
  }),
  hasRecord: yup.boolean(),
  coordinatesTypes: yup.mixed().when('baseline', {
    is: true,
    then: () => yup.string().required('Campo obligatorio'),
  }),
  zoneNumber: yup.mixed().when('coordinatesTypes', {
    is: 'WSG84',
    then: () => yup.string().required('Campo obligatorio'),
  }),
  geoJson: yup.mixed().when([ 'baseline', 'hasRecord' ], {
    is: (baseline, hasRecord) => baseline && !hasRecord,
    then: () => yup.object().shape({
      features: yup.array().of(
        yup.object().test(
          'features',
          'No puedes agregar una geometría vacía o una con coordenadas invalidas',
          function (value) {
            const coordinates = value?.geometry?.coordinates || value?.geometry?.geometries;
            if (!coordinates) {
              return this.createError({ message: 'No puedes agregar una geometría vacía' });
            }
            const hasInvalidData = isInvalidGeometry(value);
            if (coordinates.length === 0) {
              return this.createError({ message: 'No puedes agregar una geometría vacía' });
            } else if (hasInvalidData) {
              return this.createError({ message: 'No puedes agregar una geometría con coordenadas invalidas' });
            } else {
              return coordinates.length > 0 && !hasInvalidData;
            }
          },
        ),
      ).required('Agrega al menos una geometría, campo obligatorio'),
    }),
  }),
  records: yup.mixed().when('hasRecord', {
    is: true,
    then: () => yup.array().of(
      yup.object().shape(recordSchema),
    ).required('Debes ingresar al menos un registro').test('isRequired', 'Debes ingresar al menos un registro', val => val?.length > 0),
  }),
});


export default archeologySchema;