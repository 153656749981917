import React from 'react';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Grid, TextField, Button, Typography, Accordion, AccordionSummary, AccordionDetails, FormControl } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

import { AutocompleteInput, GridCoordinatesMap, MultiChipsInput } from 'src/components';


const useStyles = makeStyles(theme => ({
  alertGeoJson: {
    marginBottom: theme.spacing(2),
  },
}));

const stopEventPropagation = event => event.stopPropagation();

const RecordForm = ({ name, index, remove, formOptions, register, control, watch, errors, setValue, getValues }) => {
  const classes = useStyles();

  const { generalCategoriesOptions, periodsOptions, functionalitiesOptions, movableHeritagesOptions,
    immovableHeritagesOptions, conservationStatesOptions, measureOptions, alterationTypeOptions } = formOptions;
  const hasErrors = Boolean(errors?.records?.[index]);

  const { recordRef, ...recordRegister } = register(`${name}[${index}].code`, { shouldUnregister: true });
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="recordpanel">
        <Grid item md={12} xs={12} container justifyContent="space-between" alignItems ="center">
          <Grid item md={6} xs={8} spacing={1} container alignItems ="center">
            <Grid item>
              { hasErrors &&
                <Box component="span" mr={1} color="error.main">
                  ¡Datos faltantes!
                </Box>
              }
              <Typography display="inline">
                Registro #
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                inputRef={recordRef}
                { ...recordRegister }
                placeholder="Id del registro"
                onClick={stopEventPropagation}
                error={Boolean(errors?.records?.[index]?.code)}
                helperText={errors?.records?.[index]?.code?.message}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={() => remove(index)}>
              BORRAR
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <AutocompleteInput
              name={`${name}[${index}].generalCategory`}
              options={generalCategoriesOptions}
              label="Categoría General"
              error={errors?.records?.[index]?.generalCategory}
              helperText={errors?.records?.[index]?.generalCategory?.message}
              control={control}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth component="fieldset">
              <Controller
                render={({ field }) => (
                  <MultiChipsInput
                    {...field}
                    id="specific-category-input"
                    label="Categoría Específica"
                    hasError={Boolean(errors?.records?.[index]?.specificCategories)}
                    errorHelperText={errors?.records?.[index]?.specificCategories?.message}
                    placeholder="Escribe categorías y confírmalas con Enter"
                  />
                )}
                name={`${name}[${index}].specificCategories`}
                defaultValue={[]}
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <AutocompleteInput
              name={`${name}[${index}].periods`}
              options={periodsOptions}
              multiple
              label="Período"
              error={errors?.records?.[index]?.periods}
              helperText={errors?.records?.[index]?.periods?.message}
              control={control}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <AutocompleteInput
              name={`${name}[${index}].functionalities`}
              options={functionalitiesOptions}
              multiple
              label="Funcionalidad"
              error={errors?.records?.[index]?.functionalities}
              helperText={errors?.records?.[index]?.functionalities?.message}
              control={control}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <AutocompleteInput
              name={`${name}[${index}].movableHeritages`}
              options={movableHeritagesOptions}
              multiple
              label="Materialidad Mueble"
              error={errors?.records?.[index]?.movableHeritages}
              helperText={errors?.records?.[index]?.movableHeritages?.message}
              control={control}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <AutocompleteInput
              name={`${name}[${index}].immovableHeritages`}
              options={immovableHeritagesOptions}
              multiple
              label="Materialidad Inmueble"
              error={errors?.records?.[index]?.immovableHeritages}
              helperText={errors?.records?.[index]?.immovableHeritages?.message}
              control={control}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <AutocompleteInput
              name={`${name}[${index}].alterationTypes`}
              options={alterationTypeOptions}
              multiple
              label="Tipo de Alteración"
              error={errors?.records?.[index]?.alterationTypes}
              helperText={errors?.records?.[index]?.alterationTypes?.message}
              control={control}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <AutocompleteInput
              name={`${name}[${index}].conservationState`}
              options={conservationStatesOptions}
              label="Estado de conservación"
              error={errors?.records?.[index]?.conservationState}
              helperText={errors?.records?.[index]?.conservationState?.message}
              control={control}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <AutocompleteInput
              name={`${name}[${index}].measures`}
              options={measureOptions}
              multiple
              label="Medidas"
              error={errors?.records?.[index]?.measures}
              helperText={errors?.records?.[index]?.measures?.message}
              control={control}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <div className={classes.alertGeoJson}>
              {Boolean(errors?.records?.[index]?.geoJson?.features) &&
                <Alert severity="error">{`${errors.records[index].geoJson.features.message}`}</Alert>
              }
            </div>
            <GridCoordinatesMap name={name} index={index} control={control} watch={watch} setValue={setValue} getValues={getValues} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};


RecordForm.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  formOptions: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};


export default RecordForm;