import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ReactDataSheet from 'react-datasheet';
import PropTypes from 'prop-types';

import { accuracyTypes } from 'src/scenes/Flora/utils';


const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dataGrid: {
    marginTop: theme.spacing(2),
    width: '100%',
    margin: 'auto',
  },
}));

const latLngHeader = accuracy => ({
  [accuracyTypes.SAMPLING_POINT]: [
    { readOnly: true, value: 'Estación' },
    { readOnly: true, value: 'Latitud' },
    { readOnly: true, value: 'Longitud' },
  ],
  [accuracyTypes.EXACT]: [
    { readOnly: true, value: 'Especie' },
    { readOnly: true, value: 'Latitud' },
    { readOnly: true, value: 'Longitud' },
  ],
}[accuracy]);

const wsg68Header = accuracy => ({
  [accuracyTypes.SAMPLING_POINT]: [
    { readOnly: true, value: 'Estación' },
    { readOnly: true, value: 'C Norte' },
    { readOnly: true, value: 'C Este' },
  ],
  [accuracyTypes.EXACT]: [
    { readOnly: true, value: 'Especie' },
    { readOnly: true, value: 'C Norte' },
    { readOnly: true, value: 'C Este' },
  ],
}[accuracy]);

const colToKeyMapper = {
  0: 'name',
  1: 'y',
  2: 'x',
};

const getHeader = (accuracy, type) => type === 'LAT-LNG' ? latLngHeader(accuracy) : wsg68Header(accuracy);

const emptyRow = [ { value: '' }, { value: '' }, { value: '' } ];

const GridCoordinatesGeometryFlora = ({ coordType, samplingPoints, setSamplingPoints, accuracy }) => {

  const classes = useStyles();
  const [ grid, setGrid ] = useState([ getHeader(accuracy, coordType.type) ]);

  const samplingPointToRow = sp => [
    { value: sp.name || '' },
    { value: sp.y || '' },
    { value: sp.x || '' },
  ];

  const onCellsChanged = (changes, additions) => {
    const newSamplingPoints = [ ...samplingPoints ];
    changes && changes.forEach(({ row, col, value }) => {
      if (row - 1 >= newSamplingPoints.length) {
        newSamplingPoints.push({ [colToKeyMapper[col]]: value });
      } else {
        newSamplingPoints[row - 1] = { ...newSamplingPoints[row - 1], [colToKeyMapper[col]]: value };
      }
    });
    additions && additions.forEach(({ row, col, value }) => {
      if (value) {
        if (row - 1 > newSamplingPoints.length) {
          newSamplingPoints.push({ [colToKeyMapper[col]]: value });
        } else {
          newSamplingPoints[row - 1] = { ...newSamplingPoints[row - 1], [colToKeyMapper[col]]: value };
        }
      }
    });
    const finalSamplingPoints = newSamplingPoints
      .filter(sp => sp.name || sp.x || sp.y)
      .map(sp => ({ ...sp, x: parseFloat(sp.x), y: parseFloat(sp.y) }));

    setGrid([
      getHeader(accuracy, coordType.type),
      ...finalSamplingPoints
        .map(samplingPointToRow),
      emptyRow,
    ]);

    setSamplingPoints(finalSamplingPoints);
  };

  useEffect(() => {
    setGrid([
      getHeader(accuracy, coordType.type),
      ...samplingPoints
        .map(samplingPointToRow),
      emptyRow,
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ coordType.type, accuracy ]);

  return (
    <Grid item className={classes.container}>
      <Grid item xs={8}>
        <ReactDataSheet
          className={classes.dataGrid}
          data={grid}
          valueRenderer={ cell => cell.value }
          onCellsChanged={onCellsChanged}
          onContextMenu={(e, cell) => cell.readOnly ? e.preventDefault() : null}
        />
      </Grid>
    </Grid>
  );
};

GridCoordinatesGeometryFlora.propTypes = {
  coordType: PropTypes.object,
  samplingPoints: PropTypes.array,
  setSamplingPoints: PropTypes.func,
  accuracy: PropTypes.string.isRequired,
};

export { GridCoordinatesGeometryFlora };
