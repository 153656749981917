import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { GridCoordinatesMapFlora } from 'src/scenes/Flora/components/GridCoordinatesMapFlora';
import { convertSamplingPoints } from 'src/scenes/Flora/utils';
import { pointToGeoPoint } from 'src/utils';


const exactPlaceToSamplingPoint =
  ({ species: { scientificName }, geometry: { coordinates: [ x, y ] } }) => ({ name: scientificName, x, y });

// This component's job is to make use of GridCoordinatesMapFlora when the accuracy is exact
const ExactGridContainer = ({ places, updatePlaces, campaignIndex, recordIndex, accuracy }) => {

  const [ coordType, setCoordType ] = useState({ type: 'LAT-LNG', timezone: 18 });

  const [ samplingPoints, setSamplingPoints ] = useState(places.map(exactPlaceToSamplingPoint));

  useEffect(() => {
    const spConverted = convertSamplingPoints({ samplingPoints, fromType: coordType.type, timezone: coordType.timezone });
    const newPlaces = spConverted.map(({ name, x, y }) => ({
      geometry: pointToGeoPoint({ simpleGeometry: true, lat: y, lon: x }),
      species: { scientificName: name },
    }));
    updatePlaces(newPlaces);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ samplingPoints, coordType.type, coordType.timezone ]);

  return (
    <Box>
      <GridCoordinatesMapFlora
        coordType={ coordType } setCoordType={ setCoordType } campaignIndex={ campaignIndex } recordIndex={ recordIndex }
        samplingPoints={ samplingPoints } setSamplingPoints={ setSamplingPoints } accuracy={ accuracy }
      />
    </Box>
  );
};

ExactGridContainer.propTypes = {
  places: PropTypes.array.isRequired,
  updatePlaces: PropTypes.func.isRequired,
  campaignIndex: PropTypes.number.isRequired,
  recordIndex: PropTypes.number.isRequired,
  accuracy: PropTypes.string.isRequired,
};

export default ExactGridContainer;
