import React from 'react';
import PropType from 'prop-types';
import { Box, Toolbar } from '@material-ui/core';
import TopBar from 'src/layouts/MainLayout/components/TopBar';


const MainLayout = ({ children }) => (
  <Box display="flex" >
    <TopBar />
    <Box flexGrow={ 1 }>
      <Toolbar />
      {children}
    </Box>
  </Box>
);

MainLayout.propTypes = {
  children: PropType.element.isRequired,
};


export { MainLayout };