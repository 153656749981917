import { get, post } from 'src/services/api';


const login = async data => (await post('user/login', data)).data;
const logout = async data => (await post('user/logout', data)).data;
const isAuthenticated = async () => (await get('user/is-authenticated')).data.isAuthenticated;


export {
  login,
  logout,
  isAuthenticated,
};