import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  container: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    minHeight: '100px',
  },
}));

const Section = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid className={classes.container} container direction="row" spacing={2}>
        <Grid item xs>
          <Typography variant="h5" component="span" gutterBottom>
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        {children}
      </Paper>
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};


export default Section;