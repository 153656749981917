import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(({
  contrastText: {
    color: '#ffffff',
  },
  toolbar: {
    height: 64,
  },
}));

const TopBar = () => {
  const classes = useStyles();

  return (
    <AppBar>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Typography variant="h6" className={classes.contrastText}>BASEALINES</Typography>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};


export default TopBar;