import React, { useCallback, useMemo } from 'react';
import { Grid, Button, FormControlLabel, Checkbox, TextField, Typography, Box,
  Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Map } from 'src/components';


const useStyles = makeStyles(theme => ({
  selector: {
    width: 300,
  },
  locality: {
    width: '98%',
    marginTop: theme.spacing(2),
  },
  accordionSummary: {
    backgroundColor: '#eee',
  },
  deleteButton: {
    textTransform: 'capitalize',
  },
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const getOptionSelected = (option, value) => option.value === value;

const AoIContents = ({
  index, updateAreaGeometryGroup, phaseOptions, errors, componentIndex, areaGeometry, onDelete, availablePhases,
}) => {
  const { phases, locality, geoJson, geometriesHaveProblems, problemsDescription } = areaGeometry;
  const classes = useStyles();

  const updateState = update => updateAreaGeometryGroup({ groupIndex: index, update });

  const phasesDict = useMemo(() => phaseOptions.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.label }), {}), [ phaseOptions ]);

  // por alguna razón getOptionsLabel se llama sobre las opciones y no solo los valores aunque le damos renderOption
  const getValueLabel = useCallback(value => phasesDict[value.value ?? value], [ phasesDict ]);

  const handleGeoJson = ({ geoJson = {} }) => {
    if (!geoJson.features?.length) {
      updateState({ geoJson: null });
    }

    // TODO: siempre es correcto que sea FeatureCollection? según esto https://www.ibm.com/docs/en/db2/11.5?topic=formats-geojson-format
    // una feature o geometry suelta son geojsons válidos, pero no sé si el convertidor de kmls los deja así alguna vez
    updateState({
      geoJson: {
        'type': 'FeatureCollection',
        features: geoJson.features.map(({ properties, ...restOfGeojson }) => ({
          properties,
          ...restOfGeojson,
        })),
      },
    });
    return;
  };

  const deleteGeoJson = () => {
    updateState({ geoJson: null });
  };

  const phasesLabel = useMemo(() =>
    `${phases.map(ph => phasesDict[ph]).join(', ')}${phases.length > 1 ? ' (multifase)' : '' }`
  , [ phases, phasesDict ]);

  const renderPhaseOpt = option => availablePhases[option.value] ? option.label : `${option.label} (ya asignada a otra área)`;

  const getPhaseOptDisabled = option => !availablePhases[option.value];

  const onChangePhase = (e, data) => updateState({ phases: data.map(opt => typeof opt === 'string' ? opt : opt.value) });

  const idStr = `${componentIndex}-${index}`;
  const mapName = `aoi-map-${idStr}`;

  return <Accordion defaultExpanded={true} TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Fase(s): { phases.length ? phasesLabel : <em>sin fase definida</em> }
          </Typography>
        </Box>
        <Box>
          <Button variant="contained" color="secondary" onClick={ onDelete }>
            Borrar
          </Button>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Grid item md={8} xs={12}>
            <Box my={2}>
              <Autocomplete
                multiple
                value={phases}
                name={`fases-aoi-${index}`}
                options={phaseOptions}
                getOptionLabel={getValueLabel}
                renderOption={renderPhaseOpt}
                getOptionSelected={getOptionSelected}
                getOptionDisabled={getPhaseOptDisabled}
                filterSelectedOptions
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={'Fases del proyecto'}
                    error={Boolean(errors?.phases)}
                    helperText={errors?.phases?.errorMessage}
                  />
                )}
                onChange={onChangePhase}
              />
              { phases?.length > 1 && <small>Multifase</small> }
            </Box>
          </Grid>
          <Grid item xs>
            <Box my={3}>
              <Box>
                <TextField
                  className={classes.locality}
                  label="Localidad de referencia"
                  minRows={8}
                  maxRows={14}
                  multiline
                  name="localidad"
                  onChange={ e => updateState({ 'locality': e.target.value })}
                  placeholder="Escribe localidades o puntos notables del entorno como referencia para reconocer la ubicación del elemento"
                  type="text"
                  value={locality}
                  variant="outlined"
                />
              </Box>
            </Box>
          </Grid>
          { geometriesHaveProblems &&
            <Grid item xs>
              <Box my={3}>
                <Box>
                  <TextField
                    className={classes.locality}
                    label="Descripción de problemas de las geometrías"
                    minRows={8}
                    maxRows={14}
                    multiline
                    name="descripcion-problemas"
                    onChange={ e => updateState({ 'problemsDescription': e.target.value })}
                    placeholder="Describe los problemas que hay con las geometrías"
                    type="text"
                    value={problemsDescription}
                    variant="outlined"
                  />
                </Box>
              </Box>
            </Grid>
          }
        </Grid>
        <Grid item xs={6}>
          <Map nameId={mapName} geoJson={geoJson} handleGeoJson={handleGeoJson} importKml={true} deleteGeoJson={deleteGeoJson} />
          <FormControlLabel
            control={
              <Checkbox color="primary" checked={geometriesHaveProblems}
                onChange={event => updateState({ geometriesHaveProblems: event.target.checked })}
              />
            }
            labelPlacement="start"
            label="¿Datos erróneos o no disponibles?"
          />
          { Boolean(errors?.geoJson) &&
            <Alert severity="error">{ errors?.geoJson?.errorMessage}</Alert>
          }
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>;
};

AoIContents.propTypes = {
  phaseOptions: PropTypes.array,
  availablePhases: PropTypes.object,
  areaGeometry: PropTypes.object.isRequired,
  componentIndex: PropTypes.number,
  index: PropTypes.number,
  errors: PropTypes.object,
  updateAreaGeometryGroup: PropTypes.func,
  onDelete: PropTypes.func,
};


export { AoIContents };