import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import { Project, Section, CoordinatesTypes } from 'src/components';
import { AutoCompleteConsultants, GridCoordinatesMapProj } from 'src/scenes/LoadProject/components';


const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

const ProjectForm = ({
  project,
  coordType,
  setCoordType,
  features,
  consultant,
  doesntHaveConsultant,
  updateState,
  geoJson,
  errors }) => {
  const classes = useStyles();
  return (
    <>
      {
        project &&
        <Section title="Proyecto">
          <Project project={project}>
            <AutoCompleteConsultants
              errors={errors}
              consultant={consultant}
              doesntHaveConsultant={doesntHaveConsultant}
              updateState={updateState}
            />
            <CoordinatesTypes
              direction="column"
              errors={errors}
              coordType={coordType}
              setCoordType={setCoordType}
              updateState={updateState}
            />
          </Project>
        </Section>}
      <Section title="Área del proyecto">
        <div className={classes.root}>
          {Boolean(errors.geoJson?.features) &&
            <Alert severity="error">{errors?.geoJson?.features?.message}</Alert>
          }
        </div>
        <GridCoordinatesMapProj
          importKml
          coordType={coordType}
          features={features}
          errors={errors}
          geoJson={geoJson}
          updateState={updateState}
        />
      </Section>
    </>
  );
};

ProjectForm.propTypes = {
  errors: PropTypes.object.isRequired,
  coordType: PropTypes.object,
  setCoordType: PropTypes.func,
  features: PropTypes.array,
  project: PropTypes.object,
  geoJson: PropTypes.object,
  updateState: PropTypes.func,
  consultant: PropTypes.string.isRequired,
  doesntHaveConsultant: PropTypes.bool.isRequired,
};


export default ProjectForm;
