import React, { Fragment, useCallback, useState } from 'react';
import { Box, DialogContent, DialogActions, Button, DialogTitle, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';


const getOptionLabel = opt => opt.name;
const getOptionSelected = (option, value) => option.name === value?.name;

const AddComponentDialog = props => {
  const { closeDialog, addComponent, componentList, availableOptions } = props;

  const [ selectedComp, setSelectedComp ] = useState(null);
  const [ finishing, setFinishing ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  const getDisabledOption = option => !availableOptions[option.name];

  const renderOpt = useCallback(option =>
    availableOptions[option.name] ? option.name : `${option.name} (ya activo en el formulario)`
  // eslint-disable-next-line
  , []);

  const onChange = useCallback((e, data) => {
    setErrorMessage('');
    setSelectedComp(data);
  }, []);

  const finish = () => {
    if (!finishing) {
      setFinishing(true);
      if (!selectedComp) {
        setErrorMessage('Debes seleccionar un tipo de componente a añadir');
        return setFinishing(false);
      }
      addComponent(selectedComp);
      closeDialog();
    }
  };

  return (
    <Fragment>
      <DialogTitle id='form-dialog-title'>Añadir componente de áreas de influencia</DialogTitle>
      <DialogContent>
        <Box mb={1} ml={1}>
          <Typography variant="subtitle1">
            Elige el tipo de componente que quieres llenar
          </Typography>
        </Box>
        <Box my={1}>
          <Autocomplete
            value={selectedComp}
            name={'selector-de-componentes'}
            options={componentList}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            getOptionDisabled={getDisabledOption}
            renderOption={renderOpt}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label={'Componentes'}
                error={Boolean(errorMessage)}
                helperText={errorMessage}
              />
            )}
            onChange={onChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancelar</Button>
        <Button onClick={finish} color="primary" disabled={finishing}>Añadir</Button>
      </DialogActions>
    </Fragment>
  );
};

AddComponentDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  addComponent: PropTypes.func.isRequired,
  componentList: PropTypes.array.isRequired,
  availableOptions: PropTypes.object.isRequired,
};


export {
  AddComponentDialog,
};
