import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';


const RemoveGeomsDialog = props => {
  const { confirmFn, closeDialog } = props;

  const handleConfirm = () => {
    confirmFn();
    closeDialog();
  };

  return <>
    <DialogTitle id="form-dialog-title">Remover geometrías</DialogTitle>
    <DialogContent>
      <Box p={1}>
        <Typography variant="body1" color="textPrimary">
          Estás a punto de quitar la información de los KMLs que has cargado, con lo que perderás cualquier progreso no guardado.
        </Typography>
        <br />
        <Typography variant="body1" color="textPrimary">
          ¿Realmente deseas remover las geometrías cargadas?
        </Typography>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeDialog} color="secondary">Cancelar</Button>
      <Button onClick={handleConfirm} color="primary">Confirmar</Button>
    </DialogActions>
  </>;
};

RemoveGeomsDialog.propTypes = {
  confirmFn: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
};


export {
  RemoveGeomsDialog,
};
