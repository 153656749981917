import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, FormControlLabel, Checkbox, TextField, FormControl, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';

import CoordinatesTypes from 'src/scenes/Archeology/components/CoordinatesTypes';
import { MultiChipsInput } from 'src/components';


const useStyles = makeStyles(() => ({
  root: {
    marginTop: 10,
  },
}));

const ArchaeologyDetail = ({ register, control, errors }) => {
  const classes = useStyles();

  const [ baseline, pas132, paleontologicalBaseline ] = useWatch({
    control,
    name: [ 'baseline', 'pas132', 'paleontologicalBaseline' ],
    defaultValue: false,
  });

  const { studyYearRef, ...studyYearRegister } = register('studyYear', { shouldUnregister: true });
  const { baselineRef, ...baselineRegister } = register('baseline', { shouldUnregister: true });
  const { hasRecordRef, ...hasRecordRegister } = register('hasRecord', { shouldUnregister: true });
  const { paleontologicalBaselineRef, ...paleontologicalBaselineRegister } = register(
    'paleontologicalBaseline', { shouldUnregister: true },
  );
  const { pas132Ref, ...pas132Register } = register('pas132', { shouldUnregister: true });

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={4} className={classes.root}>
        <Grid item xs={6}>
          <Grid item xs={12} sm container direction="column" spacing={2}>
            <Grid item>
              <TextField
                inputRef={studyYearRef}
                { ...studyYearRegister }
                label="Año del estudio"
                type="text"
                variant="outlined"
                error={Boolean(errors.studyYear)}
                helperText={errors?.studyYear?.message}
              />
            </Grid>
            <Grid container item direction="row" alignItems="center" spacing={4}>
              <Grid item>
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  inputRef={baselineRef}
                  { ...baselineRegister }
                  labelPlacement="start"
                  label="¿Tiene línea base?"
                />
              </Grid>
              { baseline &&
                <Grid item>
                  <FormControl error={Boolean(errors.baselineFile)} component="fieldset">
                    <input
                      id="upload-baseline"
                      { ...register('baselineFile', { shouldUnregister: true }) }
                      type="file"
                      accept=".pdf,.doc,.docx,.odt,.zip,.rar,.7z"
                    />
                    {Boolean(errors.baselineFile) &&
                    <FormHelperText>
                      {`${errors?.baselineFile?.message}`}
                    </FormHelperText>
                    }
                  </FormControl>
                </Grid>
              }
            </Grid>
            { baseline &&
              <>
                <Grid item>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    inputRef={hasRecordRef}
                    { ...hasRecordRegister }
                    labelPlacement="start"
                    label="¿Tiene registros?"
                  />
                </Grid>
                <Grid container item direction="row" alignItems="center" spacing={4}>
                  <Grid item>
                    <FormControlLabel
                      control={<Checkbox color="primary"/>}
                      inputRef={paleontologicalBaselineRef}
                      { ...paleontologicalBaselineRegister }
                      labelPlacement="start"
                      label="¿Tiene línea base paleontológica?"
                    />
                  </Grid>
                  { paleontologicalBaseline &&
                    <Grid item>
                      <FormControl error={Boolean(errors.paleontologicalBaselineFile)} component="fieldset">
                        <input
                          id="upload-paleontological-baseline"
                          { ...register('paleontologicalBaselineFile', { shouldUnregister: true }) }
                          type="file"
                          accept=".pdf,.doc,.docx,.odt,.zip,.rar,.7z"
                        />
                        {Boolean(errors.paleontologicalBaselineFile) &&
                          <FormHelperText>
                            {`${errors?.paleontologicalBaselineFile?.message}`}
                          </FormHelperText>
                        }
                      </FormControl>
                    </Grid>
                  }
                </Grid>
                <Grid container item direction="row" alignItems="center" spacing={4}>
                  <Grid item>
                    <FormControlLabel
                      control={<Checkbox color="primary"/>}
                      inputRef={pas132Ref}
                      { ...pas132Register }
                      labelPlacement="start"
                      label="¿Tiene PAS132?"
                    />
                  </Grid>
                  { pas132 &&
                    <Grid item>
                      <FormControl error={Boolean(errors.pas132File)} component="fieldset">
                        <input
                          id="upload-pas132"
                          { ...register('pas132File', { shouldUnregister: true }) }
                          name="pas132File"
                          type="file"
                          accept=".pdf,.doc,.docx,.odt,.zip,.rar,.7z"
                        />
                        {Boolean(errors.pas132File) &&
                          <FormHelperText>
                            {`${errors?.pas132File?.message}`}
                          </FormHelperText>
                        }
                      </FormControl>
                    </Grid>
                  }
                </Grid>
              </>
            }
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12} sm container direction="column" spacing={3}>
            {baseline &&
              <>
                <Grid item>
                  <FormControl fullWidth component="fieldset">
                    <Controller
                      render={({ field }) => (
                        <MultiChipsInput
                          {...field}
                          id="archeologists-input"
                          label="Participantes de la línea base"
                          hasError={Boolean(errors?.archeologists)}
                          errorHelperText={errors?.archeologists?.message}
                          placeholder="Escribe nombres y confírmalos con Enter"
                        />
                      )}
                      name="archeologists"
                      defaultValue={[]}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <CoordinatesTypes direction='column' control={control} errors={errors} />
              </>
            }
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

ArchaeologyDetail.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};


export default ArchaeologyDetail;