import React, { useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import ReactDataSheet from 'react-datasheet';
import PropTypes from 'prop-types';

import { createEmptyArray, transposeGrid } from 'src/scenes/Flora/utils';


const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dataGrid: {
    marginTop: theme.spacing(2),
    width: '100%',
    margin: 'auto',
  },
  speciesName: {
    fontStyle: 'italic',
  },
}));

const hasSomeValueInRow = (grid, row) => grid[row].some(cell => cell.value !== '');

const hasSomeValueInCol = (grid, col) => createEmptyArray(grid.length)
  .some((_, row) => grid[row][col].value !== '');

const SpeciesLinking = ({ samplingPoints, state: { grid, isVertical }, setState }) => {
  const classes = useStyles();


  const spAsEmptyColumns = useMemo(() => samplingPoints.map(() => ({ value: '' })), [ samplingPoints ]);
  const emptyRow = useMemo(() => [ { value: '' }, ...spAsEmptyColumns ], [ spAsEmptyColumns ]);

  useEffect(() => {
    const buildEmptyTable = () => {
      if (isVertical) {
        const spAsColumns = samplingPoints.map(sp => ({ readOnly: true, value: sp.name }));

        const newGrid = [
          [ { readOnly: true, value: 'Especie/Estación', width: 120 }, ...spAsColumns ],
          [ ...emptyRow ],
        ];

        setState({ grid: newGrid });
      } else {
        const newGrid = [ [ { readOnly: true, value: 'Estación/Especie', width: 120 }, { value: '' } ] ];
        samplingPoints.forEach(sp => newGrid.push([
          { readOnly: true, value: sp.name },
          { value: '' },
        ]));

        setState({ grid: newGrid });
      }
    };

    buildEmptyTable();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeAxis = e => {
    const newGrid = transposeGrid(grid);
    setState({ isVertical: e.target.value === 'yAxis', grid: newGrid });
  };

  return (
    <Grid item className={classes.container}>
      <Grid container alignItems='flex-start' justifyContent='space-between'>
        <FormControl component="fieldset">
          <FormLabel component="legend">Eje donde se encuentran las especies</FormLabel>
          <RadioGroup row aria-label="Especies" value={ isVertical ? 'yAxis' : 'xAxis' } onChange={ changeAxis }>
            <FormControlLabel value="xAxis" control={ <Radio /> } label="Eje X"/>
            <FormControlLabel value="yAxis" control={ <Radio /> } label="Eje Y"/>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid>
        <ReactDataSheet
          className={ classes.dataGrid }
          data={ grid }
          valueRenderer={ cell => cell.value }
          onCellsChanged={(changes = [], additions = []) => {
            let newGrid = [ ...grid ];

            // add values from cells
            changes.forEach(({ row, col, value }) => {
              newGrid[row][col] = { value };
            });

            additions.forEach(({ row, col, value }) => {
              if (isVertical && row === newGrid.length) {
                newGrid.push([ ...emptyRow ]);
              } else if (!isVertical && col === newGrid[0]?.length) {
                newGrid = newGrid.map(r => [ ...r, { value: '' } ]);
              }

              if (newGrid?.[row]?.[col]) {
                newGrid[row][col] = { value };
              }
            });

            // clean empty rows and columns
            if (isVertical) {
              newGrid = newGrid.filter((_, rowIndex) => hasSomeValueInRow(newGrid, rowIndex));
            } else {
              const colsToRemove = [];
              for (let colIndex = 0; colIndex < newGrid[0].length; colIndex++) {
                if (!hasSomeValueInCol(newGrid, colIndex)) {
                  colsToRemove.push(colIndex);
                }
              }
              newGrid = newGrid.map(row => row.filter((_, cIndex) => !colsToRemove.includes(cIndex)));
            }

            // add empty col or row
            if (isVertical && hasSomeValueInRow(newGrid, newGrid.length - 1)) {
              newGrid.push([ ...emptyRow ]);
            } else if (!isVertical && hasSomeValueInCol(newGrid, newGrid[0].length - 1)) {
              newGrid = newGrid.map(r => [ ...r, { value: '' } ]);
            }

            // add style to species cells
            if (isVertical) {
              newGrid.forEach((row, i) => i > 0 && (row[0].className = classes.speciesName));
            } else {
              newGrid[0].forEach((cell, i) => i > 0 && (cell.className = classes.speciesName));
            }

            setState({ grid: newGrid });
          }}
        />
      </Grid>
    </Grid>
  );
};

SpeciesLinking.propTypes = {
  samplingPoints: PropTypes.array,
  state: PropTypes.shape({
    grid: PropTypes.array,
    isVertical: PropTypes.bool,
  }),
  setState: PropTypes.func,
};

export { SpeciesLinking };
