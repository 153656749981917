import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, TablePagination, IconButton, TableFooter } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import PropTypes from 'prop-types';

import { archeologyApi } from 'src/services';


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

const AllProjectsRow = React.memo(({ row }) => {
  const {
    projectId,
    seaId,
    projectName,
    projectUserFullName,
    createdAt,
    seaUrl,
    archeologyUserFullName,
    studyYear,
    quantity,
    baseline,
    paleontologicalBaseline,
    pas132 } = row;

  const fechaProjecto = new Date(createdAt);
  return (
    <TableRow>
      <TableCell align="center"><span>{projectId}</span></TableCell>
      <TableCell align="center"><a target="blank" href={`${seaUrl}`}>{seaId}</a></TableCell>
      <TableCell align="center"><span>{projectName}</span></TableCell>
      <TableCell align="center"><span>{projectUserFullName}</span></TableCell>
      <TableCell align="center"><span>{archeologyUserFullName}</span></TableCell>
      <TableCell align="center">
        <span>
          {`${fechaProjecto.getDate()}-${fechaProjecto.getMonth() + 1}-${fechaProjecto.getFullYear()}`}
        </span>
      </TableCell>
      <TableCell align="center"><span>{studyYear}</span></TableCell>
      <TableCell align="center">
        <span>
          {
            archeologyUserFullName ?
              Number.isInteger(parseInt(quantity, 10)) ?
                quantity : 0 : ``
          }
        </span>
      </TableCell>
      <TableCell align="center"><span>{archeologyUserFullName ? baseline ? `✓` : `x` : ''}</span></TableCell>
      <TableCell align="center"><span>{archeologyUserFullName ? paleontologicalBaseline ? `✓` : `x` : ``}</span></TableCell>
      <TableCell align="center"><span>{archeologyUserFullName ? pas132 ? `✓` : `x` : ``}</span></TableCell>
    </TableRow>
  );
});
AllProjectsRow.displayName = 'AllProjectsRow';
AllProjectsRow.propTypes = {
  row: PropTypes.object.isRequired,
};


const AllProjectsHead = () =>
  <TableHead>
    <TableRow>
      <TableCell><strong>Id</strong></TableCell>
      <TableCell component="th" align="center"><strong>Id SEA</strong></TableCell>
      <TableCell component="th" align="center"><strong>Nombre</strong></TableCell>
      <TableCell component="th" align="center"><strong>Usuario Proyecto</strong></TableCell>
      <TableCell component="th" align="center"><strong>Usuario Arch</strong></TableCell>
      <TableCell component="th" align="center"><strong>Fecha Creación</strong></TableCell>
      <TableCell component="th" align="center"><strong>Año de Estudio</strong></TableCell>
      <TableCell component="th" align="center"><strong>Registros</strong></TableCell>
      <TableCell component="th" align="center"><strong>Línea Base</strong></TableCell>
      <TableCell component="th" align="center"><strong>Línea Base Paleontológica</strong></TableCell>
      <TableCell component="th" align="center"><strong>Pas132</strong></TableCell>
    </TableRow>
  </TableHead>;

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const classes = useStyles();

  const handleFirstPageButtonClick = event => onPageChange(event, 0);

  const handleBackButtonClick = event => onPageChange(event, page - 1);

  const handleNextButtonClick = event => onPageChange(event, page + 1);

  const handleLastPageButtonClick = event => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        <FirstPageIcon />
      </IconButton>

      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>

      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        <LastPageIcon />
      </IconButton>
    </div>
  );
};
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

const ProjectsTable = ({ search, clean }) => {
  const classes = useStyles();
  const [ state, setState ] = useState({
    page: 0,
    totalRows: 0,
    offset: 0,
    limit: 5,
    searched: false,
    firstRender: true,
    projects: [],
  });

  const { page, totalRows, limit, firstRender, projects } = state;

  const getProjects = async (newState = state) => {
    try {
      if (newState.searched) {
        setState(prevState => ({ ...prevState, ...newState }));
      } else {
        const { totalRows, projects } = await archeologyApi.getProjects({
          limit: newState.limit || limit,
          offset: newState.offset });
        setState(prevState => ({
          ...prevState,
          ...newState,
          projects,
          firstRender: false,
          totalRows: parseInt(totalRows, 10),
        }));
      }
    } catch (err) {
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      } else {
        console.error(`Problem to request project: ${err}`);
      }
    }
  };

  const handleChangePage = (event, page) => {
    getProjects({
      offset: parseInt(page, 10) * limit,
      page: page,
    });
  };

  const handleChangeRowsPerPage = event => {
    getProjects({
      offset: 0,
      limit: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  useEffect(() => {
    getProjects();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onClean = () => {
      if (!firstRender) {
        getProjects({
          page: 0,
          offset: 0,
          searched: false,
        });
      }
    };
    onClean();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ clean ]);

  useEffect(() => {
    const displaySearch = () => {
      if (!firstRender) {
        getProjects({
          totalRows: 1,
          page: 0,
          offset: 0,
          current: 1,
          searched: true,
          projects: search.projects,
        });
      }
    };
    displaySearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ search ]);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <AllProjectsHead/>
        <TableBody>
          {
            projects.map(row => (
              <AllProjectsRow key={row.seaId} row={row}/>
            ))
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[ 5, 15, 30 ]}
              count={totalRows}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage={'Filas por Página'}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
ProjectsTable.propTypes = {
  search: PropTypes.object.isRequired,
  clean: PropTypes.bool.isRequired,
};

export { ProjectsTable };