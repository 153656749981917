import React from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';

import { useLeaveConfirm } from 'src/hooks';


const defaultMessage = 'Puede que tengas cambios sin guardar, por favor confirma que de verdad quieres dejar esta página';
/**
 * @prop { function } checkConfirmNeeded Si esto retorna un valor truthy, se activará un diálogo para evitar que salgan de la página.
 * @prop { string } message El mensaje que se da cuando se evita que intenten navegar entre páginas cuando la navegación es manejada
 * por React (no se puede cambiar el mensaje nativo cuando navegan fuera de la página por el navegador). Por defecto es:
 * "Puede que tengas cambios sin guardar, por favor confirma que de verdad quieres dejar esta página".
 */
const LeaveConfirm = ({ checkConfirmNeeded, message = defaultMessage }) => {

  useLeaveConfirm({ checkConfirmNeeded });

  return <Prompt message={() => {
    const keepHere = checkConfirmNeeded();
    return keepHere ? message : true;
  }}/>;
};

LeaveConfirm.propTypes = {
  checkConfirmNeeded: PropTypes.func.isRequired,
  message: PropTypes.string,
};


export { LeaveConfirm };
