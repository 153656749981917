
import React from 'react';
import Routes from 'src/Routes';
import 'react-datasheet/lib/react-datasheet.css';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import esLocale from 'date-fns/locale/es';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { GlobalProvider } from 'src/context';
import theme from 'src/theme';


const App = () =>
  <ThemeProvider theme={ theme }>
    <CssBaseline />
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ esLocale }>
      <GlobalProvider>
        <Routes />
      </GlobalProvider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>;

export default App;