import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';


const RemoveComponentDialog = props => {
  const { componentName, componentIndex, removeComponent, closeDialog, dataOnWeb } = props;

  const remove = () => {
    removeComponent({ index: componentIndex, name: componentName, dataOnWeb });
    closeDialog();
  };

  return <>
    <DialogTitle id="form-dialog-title">Remover {'"'}{componentName}{'"'}</DialogTitle>
    <DialogContent>
      <Box p={1}>
        {!dataOnWeb ?
          <Typography variant="body1" color="textPrimary">
            Al remover este componente del formulario luego podrás volver a agregarlo, pero
            no podrás recuperar los datos no guardados que tengas actualmente ingresados en su formulario.
          </Typography> :
          <Typography variant="body1" color="textPrimary">
            Al remover este componente del formulario luego podrás volver a agregarlo, pero
            perderás los datos que anteriormente fueron enviados en el formulario.
          </Typography>
        }
        <br />
        <Typography variant="body1" color="textPrimary">
          ¿Realmente deseas remover el componente?
        </Typography>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeDialog} color="secondary">Cancelar</Button>
      <Button onClick={remove} color="primary">Remover</Button>
    </DialogActions>
  </>;
};

RemoveComponentDialog.propTypes = {
  removeComponent: PropTypes.func.isRequired,
  componentIndex: PropTypes.number.isRequired,
  closeDialog: PropTypes.func.isRequired,
  componentName: PropTypes.string.isRequired,
  dataOnWeb: PropTypes.bool,
};


export {
  RemoveComponentDialog,
};
