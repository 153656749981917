import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Container, Typography, Box, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { GlobalContext } from 'src/context';
import { userApi } from 'src/services';
import Loading from 'src/components/Loading';
import { useContext } from 'react';


const schema = yup.object().shape({
  email: yup.string().email('Debes ingresar un email válido').required('Campo obligatorio'),
  password: yup.string().required('Campo obligatorio'),
});

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorLabel: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  errorMsg: {
    color: '#6c6c6c',
  },
}));

const LoginContainer = () => {
  const history = useHistory();
  const classes = useStyles();
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const { loginSuccess } = useContext(GlobalContext);

  const [ isLoading, setIsLoading ] = useState(false);
  const [ errorServer, setErrorServer ] = useState(false);

  const redirectToInitialPage = useCallback(() => history.push('app/'), [ history ]);

  const checkAuthentication = useCallback(async () => {
    try {
      setIsLoading(true);

      const isAuthenticated = await userApi.isAuthenticated();

      setIsLoading(false);
      if (isAuthenticated) {
        redirectToInitialPage();
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  }, [ redirectToInitialPage ]);

  useEffect(() => {
    checkAuthentication();
  }, [ checkAuthentication ]);

  const onSubmit = async({ email, password }) => {
    try {
      setIsLoading(true);

      const resp = await userApi.login({ email, password });

      setIsLoading(false);
      if (resp) {
        loginSuccess(resp.user);
        redirectToInitialPage();
      }
    } catch (err) {
      setIsLoading(false);

      console.error(err);
      if (err.serverMessage) {
        setErrorServer(err.serverMessage);
      } else {
        console.error(`Problem with login: ${err}`);
      }
    }
  };

  const { emailRef, ...emailRegister } = register('email', { shouldUnregister: true });
  const { passwordRef, ...passwordRegister } = register('password', { shouldUnregister: true });

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="90vh"
      justifyContent="center"
    >
      {isLoading ?
        <Loading /> :
        <Container maxWidth="sm">
          <Typography variant="h6" color="primary">INICIAR SESIÓN</Typography>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form} noValidate>
            <TextField
              inputRef={emailRef}
              { ...emailRegister }
              fullWidth
              margin="normal"
              variant="outlined"
              label="Email"
              type="text"
              autoFocus
              error={Boolean(errors.email)}
              helperText={errors?.email?.message}
            />
            <TextField
              inputRef={passwordRef}
              { ...passwordRegister }
              fullWidth
              margin="normal"
              variant="outlined"
              label="Contraseña"
              type="password"
              error={Boolean(errors.password)}
              helperText={errors?.password?.message}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
            Entrar
            </Button>
          </form>
          <Box my={2}>
            {errorServer &&
            <Box display="flex" flexDirection="column" alignItems="center" >
              <Typography className={classes.errorLabel} align="center">HA OCURRIDO UN ERROR</Typography>
              <Typography className={classes.errorMsg} align="center">{errorServer}</Typography>
            </Box>
            }
          </Box>
        </Container>
      }
    </Box>
  );
};


export { LoginContainer };