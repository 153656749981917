import { get, post } from 'src/services/api';


const getFormOptions = async () => (await get('/archeology/form-options')).data;
const saveArcheology = async data => await post('/archeology', data, { headers: { 'Content-Type': 'multipart/form-data' } });
const getRandomProject = async data => (await get('/archeology/project-random', data)).data;
const getPriorityProject = async data => (await get('/archeology/project-priority', data));
const getProjects = async data => (await get(`/archeology/all-projects/`, data)).data;
const getOneProject = async data => (await get(`/archeology/one-project/`, data)).data;


export {
  getFormOptions,
  saveArcheology,
  getRandomProject,
  getPriorityProject,
  getProjects,
  getOneProject,
};