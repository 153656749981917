import { useEffect, useState, useCallback } from 'react';


const useMustReload = () => {
  const [ mustReload, setMustReload ] = useState(false);

  const reloadPage = useCallback(() => setMustReload(true), []);

  useEffect(() => {
    if (mustReload) {
      window.location.reload();
    }
  }, [ mustReload ]);

  return { reloadPage, setMustReload, mustReload };
};


export { useMustReload };