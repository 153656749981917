import wgs84Util from 'wgs84-util';
import proj4 from 'proj4';

import { isValidLLNumber } from 'src/utils';

//configuraciones para proj4
//PSAD56 / UTM zone 19S === 24879
//PSAD56 / UTM zone 18S === 24878
//WGS 84 / UTM zone 19S === 32719
//WGS 84 / UTM zone 18S === 32718

const psad56String = huso => `+proj=utm +zone=${huso} +south +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +units=m +no_defs`;
const wgs84String = huso => `+proj=utm +zone=${huso} +south +datum=WGS84 +units=m +no_defs`;
const converterFactory = huso => proj4(psad56String(huso), wgs84String(huso)).forward;

const wgs84ToLLNullable = ({ coordinates, zoneNumber = 19, xyFormat = false }) => {
  const { coordinates: [ lon, lat ] } = wgs84Util.UTMtoLL({
    geometry: { coordinates: [ coordinates[0], coordinates[1] ] },
    properties: { zoneNumber: zoneNumber, zoneLetter: 'S' },
  });
  if (isValidLLNumber(lon, 2) && isValidLLNumber(lat, 1)) {
    return xyFormat ? { x: lon, y: lat } : [ lon, lat ];
  } else {
    return xyFormat ? { x: null, y: null } : [ null, null ];
  }
};

const wgs84ToLL = ({ coordinates, zoneNumber = 19, xyFormat = false }) => {
  const { coordinates: [ lon, lat ] } = wgs84Util.UTMtoLL({
    geometry: { coordinates: [ coordinates[0], coordinates[1] ] },
    properties: { zoneNumber: zoneNumber, zoneLetter: 'S' },
  });
  return xyFormat ? { x: lon, y: lat } : [ lon, lat ];
};

const psad56ToLL = ({ coordinates, zoneNumber = 19, xyFormat = false }) => {
  const { x, y } = converterFactory(zoneNumber)({ x: coordinates[0], y: coordinates[1] });
  return wgs84ToLL({ coordinates: [ x, y ], zoneNumber, xyFormat });
};

const psad56ToLLNullable = ({ coordinates, zoneNumber = 19, xyFormat = false }) => {
  const { x, y } = converterFactory(zoneNumber)({ x: coordinates[0], y: coordinates[1] });
  return wgs84ToLLNullable({ coordinates: [ x, y ], zoneNumber, xyFormat });
};

export {
  psad56ToLLNullable,
  wgs84ToLLNullable,
  psad56ToLL,
  wgs84ToLL,
};