import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';

import { projectApi } from 'src/services';
import { Section } from 'src/components';


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
    maxWidth: 300,
  },
  submitButton: {
    display: 'block',
    marginTop: theme.spacing(5),
  },
}));

const ProjectYearSelection = ({ projectYear, updateState, formType }) => {
  const classes = useStyles();
  const [ years, setYears ] = useState([]);

  const getYears = useCallback(async () => {
    try {
      const { years } = await projectApi.getListYear({ type: formType });
      setYears(years);
    } catch (error) {
      console.error(error);
    }
  }, [ setYears, formType ]);

  useEffect(() => {
    getYears();
  }, [ getYears ]);

  // TODO: loading mientras se obtienen los años
  return (
    <Section title="Selecciona año del proyecto">
      <FormControl variant="outlined" className={classes.formControl}>
        <Autocomplete
          options={years}
          getOptionLabel={option => `${option.label || option.value}`}
          getOptionSelected={(option, value) => option.value === value.value}
          filterSelectedOptions
          onChange={(event, newValue) => updateState({ projectYear: newValue?.value })}
          renderInput={params => (
            <TextField
              {...params}
              name={`projectYear`}
              variant="outlined"
              label={'Año'}
              value={projectYear}
            />
          )}
        />
      </FormControl>
    </Section>
  );
};

ProjectYearSelection.propTypes = {
  projectYear: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  updateState: PropTypes.func,
  formType: PropTypes.string,
};


export default ProjectYearSelection;