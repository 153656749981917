import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Page } from 'src/components';


// TODO: Se deja así mientras aún no existe la tabla de resumen de flora, además se deben generalizar esos componentes
const FloraHome = () => {
  const history = useHistory();

  useEffect(() => {
    history.replace('/app/flora/load-project');
  }, [ history ]);

  return (
    <Page title="Flora">
      Trabajo en progreso...
    </Page>
  );
};

export { FloraHome };
