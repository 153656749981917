import React, { memo, useCallback } from 'react';
import { Grid, Button, TextField, Typography, Box,
  Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { TypographicInputLabel } from 'src/components';
import { parseNumber, testDecimalsFormat } from 'src/utils';
import * as commons from 'src/scenes/subformCommons/subformCommons';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

// Para que clickear en los botones de los acordiones no los cierre o abra
const stopEventPropagation = event => event.stopPropagation();

const accordionTransitionPropObject = { unmountOnExit: true };

const getOptionSelected = (option, value) => option.value === value;

const useCapacityOptions = [ 1, 2, 3, 4, 5, 6, 7, 8 ];
const romanNums = [ 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII' ];
const getUseCapacityLabel = val => romanNums[val - 1];

const getIdNameStr = (name, id) => {
  const nameStr = name ? `Nombre: ${name}` : '';
  const idStr = id ? `ID: ${id}` : '';
  return name || id ? ` ${nameStr}${name ? '. ' : ''}${idStr}` : '';
};

const SoilUnitForm = memo(({ index, featId, unitNumber,
  name, idInProject, use, erosion, chemicalCharacteristics, biodiversityCapacity, useCapacity, vegetationCover, dataOnWeb,
  biodiversityCapacityOptions, setForm, errors, setHighlightedFeat, finished,
}) => {
  const classes = useStyles();

  // TODO: mover esto y los onChange más generales a un hook para reusarlo?
  const setSubformState = useCallback(({ index, fieldName, data }) => setForm(pf => ({
    ...pf,
    homogenousSoilUnits: pf.homogenousSoilUnits.map((hsu, currInd) => index === currInd ?
      ({ ...hsu, [fieldName]: data, dataOnWeb: false }) : hsu,
    ),
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  })), []);

  const genericOnChange = useCallback(e => {
    setSubformState({ index, fieldName: e.target.name, data: e.target.value });
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onChangeNumber = useCallback(e => {
    const data = e.target.value;
    if (!testDecimalsFormat(data)) {
      return;
    }
    setSubformState({ index, fieldName: e.target.name, data });
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onBlurNumber = useCallback(e => {
    setForm(pf => ({
      ...pf,
      homogenousSoilUnits: pf.homogenousSoilUnits.map((hsu, currInd) =>
        index === currInd ? ({ ...hsu, [e.target.name]: parseNumber(hsu[e.target.name], 2) ?? null }) : hsu,
      ),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onChangeBioCat = useCallback((e, data) => {
    setSubformState({ index, fieldName: 'biodiversityCapacity', data: data === null ? null : data.value });
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onChangeUseCapacity = useCallback((e, data) => {
    setSubformState({ index, fieldName: 'useCapacity', data: data.sort() });
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const getBioLabel = useCallback(value =>
    biodiversityCapacityOptions.labels[value?.value ?? value]
  , [ biodiversityCapacityOptions ]);

  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const onCheckboxChange = useCallback(e => {
    setSubformState({ index, fieldName: e.target.name, data: e.target.checked });
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const vegetationErrorStr = (errors?.vegetationCover) ? `. ${errors?.vegetationCover?.errorMessage}` : '';
  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  return <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={accordionSummaryClass} expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Unidad #{unitNumber}{getIdNameStr(name, idInProject)}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={12} container spacing={3}>
          <Grid item md={6} xs={6}>
            <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
            <TextField value={name} name="name" id={`${featId}-name`} variant="outlined" size="small" onChange={genericOnChange}
              fullWidth autoComplete="off" />
          </Grid>
          <Grid item md={6} xs={6}>
            <TypographicInputLabel htmlFor={`${featId}-id`}>ID</TypographicInputLabel>
            <TextField value={idInProject} name="idInProject" id={`${featId}-id`} variant="outlined" size="small" onChange={genericOnChange}
              fullWidth autoComplete="off" />
          </Grid>
          <Grid item md={12} xs={12}>
            <TypographicInputLabel htmlFor={`${featId}-cover`}>Porcentaje de cobertura vegetacional</TypographicInputLabel>
            <TextField value={vegetationCover ?? ''} id={`${featId}-cover`} name="vegetationCover" variant="outlined"
              size="small" onChange={onChangeNumber} onBlur={onBlurNumber} fullWidth autoComplete="off"
              error={Boolean(errors?.vegetationCover)}
              helperText={`Porcentaje de hasta dos decimales${vegetationErrorStr}`
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Box mb={1}>
              <TypographicInputLabel htmlFor={`${featId}-biodiversity-cap-input`}>
                Categoría de capacidad de sustentar biodiversidad
              </TypographicInputLabel>
            </Box>
            <Box my={1}>
              <Autocomplete
                value={biodiversityCapacity}
                name={'selector-de-capacidades-biodiversidad'}
                options={biodiversityCapacityOptions.options}
                getOptionLabel={getBioLabel}
                getOptionSelected={getOptionSelected}
                filterSelectedOptions
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={'Categoría'}
                    id={`${featId}-biodiversity-cap-input`}
                    error={Boolean(errors?.biodiversityCapacity)}
                    helperText={errors?.biodiversityCapacity?.errorMessage}
                  />
                )}
                onChange={onChangeBioCat}
              />
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            <Box mb={1}>
              <TypographicInputLabel htmlFor={`${featId}-use-capacity-input`}>
                Clase de capacidad de uso de suelo
              </TypographicInputLabel>
            </Box>
            <Box my={1}>
              <Autocomplete
                value={useCapacity}
                multiple
                name={'selector-de-capacidades-uso'}
                options={useCapacityOptions}
                getOptionLabel={getUseCapacityLabel}
                filterSelectedOptions
                renderInput={params => (
                  <TextField
                    {...params}
                    id={`${featId}-use-capacity-input`}
                    variant="outlined"
                    placeholder={'Selecciona clases de uso'}
                    // error={Boolean(errorMessage)}
                    // helperText={errorMessage}
                  />
                )}
                onChange={onChangeUseCapacity}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box my={0}>
              <Box>
                <TypographicInputLabel htmlFor={`${featId}-use-input`}>Uso del suelo</TypographicInputLabel>
                <TextField
                  id={`${featId}-use-input`}
                  className={classes.textArea}
                  minRows={3}
                  maxRows={10}
                  multiline
                  name="use"
                  onChange={genericOnChange}
                  placeholder="Escribe una breve descripción del uso actual del suelo"
                  type="text"
                  value={use}
                  variant="outlined"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box my={1}>
              <Box>
                <TypographicInputLabel htmlFor={`${featId}-erosion-input`}>Nivel de erosión</TypographicInputLabel>
                <TextField
                  id={`${featId}-erosion-input`}
                  className={classes.textArea}
                  minRows={3}
                  maxRows={10}
                  multiline
                  name="erosion"
                  onChange={genericOnChange}
                  placeholder="Describe el nivel de erosión"
                  type="text"
                  value={erosion}
                  variant="outlined"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box my={1}>
              <Box>
                <TypographicInputLabel htmlFor={`${featId}-erosion-input`}>Características químicas</TypographicInputLabel>
                <TextField
                  id={`${featId}-erosion-input`}
                  className={classes.textArea}
                  minRows={3}
                  maxRows={10}
                  multiline
                  name="chemicalCharacteristics"
                  onChange={genericOnChange}
                  placeholder="Nombra brevemente singularidades quimicas del suelo, si es que existen"
                  type="text"
                  value={chemicalCharacteristics}
                  variant="outlined"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>;
});

SoilUnitForm.propTypes = {
  setForm: PropTypes.func,
  setHighlightedFeat: PropTypes.func,
  biodiversityCapacityOptions: PropTypes.object,
  vegetationCover: PropTypes.any,
  index: PropTypes.number,
  featId: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  unitNumber: PropTypes.number,
  nameInGeoJson: PropTypes.string,
  idInPojectInGeoJson: PropTypes.string,
  name: PropTypes.string,
  idInProject: PropTypes.string,
  use: PropTypes.string,
  erosion: PropTypes.string,
  chemicalCharacteristics: PropTypes.string,
  biodiversityCapacity: PropTypes.string,
  useCapacity: PropTypes.arrayOf(PropTypes.number),
  dataOnWeb: PropTypes.bool,
  errors: PropTypes.object,
  isHighlighted: PropTypes.bool,
  finished: PropTypes.bool,
};

// Sin esto, el memo hace que la función aparezca como función anónima en react devtools
SoilUnitForm.displayName = 'SoilUnitForm';


export { SoilUnitForm };