import React, { useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Grid, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Map, GridCoordinatesGeometry } from 'src/components';
import { objectIsEmpty } from 'src/utils';


const GridCoordinatesMap = ({ name, index, control, watch, setValue, getValues, importKml }) => {

  const [ fromKml, setFromKml ] = useState(false);

  const [ showBadDataCheckbox, setShowBadDataCheckbox ] = useState(false);
  const [ isBadData, setIsBadData ] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'gridCoordinatesGeometry',
  });

  const geoJsonName = name ? `${name}[${index}].geoJson` : 'geoJson';
  const mapName = name ? `${name}[${index}].map` : 'map';

  const geoJsonState = watch(geoJsonName, {});

  const cleanAllGeometriesAndGeoJson = () => {
    const indexArray = fields.map((_, index) => index);
    remove(indexArray);
  };

  const addIsBadDataToGeojson = ({ geojson, isBadData }) => {
    const geo = { ...geojson };
    if (geo.features) {
      geo.features = geo.features.map(({ properties, ...restOfGeojson }) => ({
        ...restOfGeojson,
        properties: { ...properties, isBadData },
      }));
    }
    return geo;
  };

  const changeIsBadData = newIsBadData => {
    const newGeojsonState = addIsBadDataToGeojson({ geojson: geoJsonState, isBadData: newIsBadData });

    setIsBadData(newIsBadData);
    setValue(geoJsonName, newGeojsonState);
  };

  const handleGeoJson = ({ geoJson, index = 0, importFromKml = false }) => {
    if (importFromKml) {
      cleanAllGeometriesAndGeoJson();

      setFromKml(true);
      const newGeojsonState = addIsBadDataToGeojson({ geojson: geoJson, isBadData: false });
      setValue(geoJsonName, newGeojsonState);
      setShowBadDataCheckbox(true);

      return;
    }

    //Check old status for clean the map from the last geoJson if came from kml
    if (fromKml) {
      setValue(geoJsonName, {});
    }

    const newestGeoJson = getValues(geoJsonName);

    let newFeatures = objectIsEmpty(newestGeoJson) ? [] : [ ...newestGeoJson.features ];

    if (objectIsEmpty(geoJson)) {
      newFeatures = newFeatures.filter((_, indexFeature) => indexFeature !== index);
    } else {
      newFeatures[index] = geoJson;
    }

    const finalGeoJson = newFeatures.length ? { 'type': 'FeatureCollection', features: [ ...newFeatures ] } : {};
    setFromKml(false);
    setValue(geoJsonName, finalGeoJson);
  };

  const onPressNewGeometry = () => {
    setShowBadDataCheckbox(false);
    append();
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6}>
        <Button variant="contained" color="primary" onClick={ onPressNewGeometry }>
          Nueva Geometría
        </Button>
        <Grid item container direction="column">
          {
            fields.map(({ id }, index) =>
              <GridCoordinatesGeometry
                key={id}
                id={id}
                index={index}
                remove={remove}
                control={control}
                watch={watch}
                getValues={getValues}
                setValue={setValue}
                handleGeoJson={handleGeoJson}
              />,
            )
          }
        </Grid>
        <Controller
          control={control}
          name={geoJsonName}
          defaultValue={{}}
          render={({ field }) => <input {...field} hidden/>}
        />
      </Grid>
      <Grid item xs={6}>
        <Map key={index} nameId={mapName} geoJson={geoJsonState} handleGeoJson={handleGeoJson} importKml={importKml} />
        {
          showBadDataCheckbox &&
            <FormControlLabel
              control={<Checkbox color="primary" />}
              value={isBadData}
              labelPlacement="start"
              label="¿Datos erróneos?"
              onChange={ e => changeIsBadData(e.target.checked) }
            />
        }
      </Grid>
    </Grid>
  );
};

GridCoordinatesMap.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
  control: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  importKml: PropTypes.bool,
};


export default GridCoordinatesMap;