import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link, Button, Typography, Box, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

import { formatDateWithHour } from 'src/utils';
import { projectApi } from 'src/services';
import { InfoTooltip } from 'src/components';

const baseUrlProject = id => `https://seia.sea.gob.cl/expediente/xhr_documentos.php?id_expediente=${id}`;

const useStyles = makeStyles(theme => ({
  hr: {
    marginLeft: 0,
    width: '25%',
  },
  success: {
    color: 'green',
  },
  pending: {
    color: theme.palette.text.secondary,
  },
  inProgress: {
    color: theme.palette.text.secondary,
  },
}));

const Project = ({ project, formType, formStatus, children }) => {
  const classes = useStyles();
  const [ queueStatus, setQueueStatus ] = useState(null); // { isTaken, user: { cosas de user }, isOwner }
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    const fetchStatus = async () => {
      setLoading(true);
      const qs = await projectApi.getQueueStatus({ seaProjectId: project.id, type: formType });
      setQueueStatus(qs);
      setLoading(false);
    };
    if (formType && project?.id) {
      fetchStatus();
    }
  }, [ formType, project?.id ]);

  const takeProject = useCallback(async () => {
    setLoading(true);
    const qs = await projectApi.queueTake({ seaProjectId: project.id, type: formType });
    setQueueStatus(qs);
    setLoading(false);
  }, [ formType, project?.id ]);

  const unassign = useCallback(async () => {
    if (!queueStatus?.isOwner) {
      return;
    }
    setLoading(true);
    await projectApi.queueUnassign({ seaProjectId: project.id, type: formType });
    setQueueStatus({ isTaken: false, user: null, isOwner: false });
    setLoading(false);
  }, [ formType, project?.id, queueStatus?.isOwner ]);

  return <>
    <Box>
      <Typography variant="subtitle1" component="small" color="textSecondary">Año de presentación: { project.year }</Typography>
    </Box>
    <Grid container direction="row" spacing={4}>
      <Grid item xs={8}>
        <Grid item xs={12} sm container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" component="span" color="textSecondary">
              {project.nombre}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" component="span" color="textSecondary">
              Tipo de Proyecto: {project.tipo}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid item xs={12} sm container direction="column" spacing={3}>
          <Grid item>
            <Link href={baseUrlProject(project.id)} underline='none' target="_blank" rel="noreferrer">
              <Button variant="contained">Link al proyecto</Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    { (formStatus || queueStatus) && <>
      <hr className={classes.hr}></hr>
      { formStatus && <Box>
        { Boolean(formStatus?.updatedAt) &&
          <Typography variant="subtitle2" component="p" className={classes.status} color="textSecondary">
            Último cambio enviado: {formatDateWithHour(formStatus.updatedAt)}
          </Typography>
        }
        <Typography variant="h6" component="span" className={classes.status} color="textSecondary">
          Estado del proyecto:
        </Typography>
        <Typography variant="h6" component="span" className={classes[formStatus.status]}> { formStatus.message }</Typography>
      </Box>
      }
      { loading ? <CircularProgress/> : queueStatus?.isTaken &&
        <Box>
          <Typography variant="h6" component="strong" color="textSecondary">
            Tomado { queueStatus.user ? `por ${queueStatus.user.firstName} ${queueStatus.user.lastName}` : ''}
          </Typography>
          { queueStatus.isOwner &&
            <Box>
              <InfoTooltip contents='Desasígnate este formulario si ya no deseas llenar sus datos para este proyecto'>
                <Button variant="outlined" size="small" onClick={unassign}>Desasignar formulario</Button>
              </InfoTooltip>
            </Box>
          }
        </Box>
      }
      { (queueStatus && !queueStatus.isTaken) &&
        <InfoTooltip contents='Marca el formulario como tuyo si quieres llenar este formulario de este módulo del proyecto'>
          <Button variant="outlined" size="small" onClick={takeProject}>Tomar formulario</Button>
        </InfoTooltip>
      }
    </>}
    {children}
  </>;
};
Project.propTypes = {
  project: PropTypes.object.isRequired,
  children: PropTypes.node,
  formStatus: PropTypes.shape({
    status: PropTypes.oneOf([ 'success', 'pending', 'inProgress' ]).isRequired,
    message: PropTypes.string.isRequired,
    updatedAt: PropTypes.string,
  }),
  queueStatus: PropTypes.shape({
    user: PropTypes.object,
    isTaken: PropTypes.bool.isRequired,
  }),
  formType: PropTypes.string,
};


export default Project;