import React, { useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import { setUser, getUser, cleanUser, setBaseline, getBaseline, cleanBaseline } from 'src/context/utils';
import { enums } from 'src/utils';


export const GlobalContext = React.createContext();

export const GlobalProvider = ({ children }) => {

  const [ state, dispatch ] = useReducer((ps, { type, user, baseline }) => {
    switch (type) {
      case 'LOGIN':
        return { ...ps, user };
      case 'LOGOUT':
        return { ...ps, user: null };
      case 'RESTORE_SESSION':
        return { ...ps, user, baseline };
      case 'CHANGE_BASELINE':
        return { ...ps, baseline };
      default:
        throw new Error('No se conoce el tipo de acción');
    }
  }, { user: null, baseline: null });

  const loginSuccess = async user => {
    setUser(user);
    dispatch({ type: 'LOGIN', user });
  };

  const logout = async () => {
    cleanUser();
    cleanBaseline();
    dispatch({ type: 'LOGOUT' });
  };

  const restoreSession = () => {
    const user = getUser();
    const baselineType = getBaseline();

    dispatch({ type: 'RESTORE_SESSION', user, baseline: enums.baseline[baselineType] });
  };

  const changeBaseline = baselineType => {
    if (baselineType) {
      setBaseline(baselineType);
    } else {
      cleanBaseline();
    }

    dispatch({ type: 'CHANGE_BASELINE', baseline: enums.baseline[baselineType] });
  };

  const contextActionsAndValues = useMemo(() => ({ ...state, loginSuccess, logout, restoreSession, changeBaseline }), [ state ]);
  return (
    <GlobalContext.Provider value={ contextActionsAndValues }>
      { children }
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
