import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography, StepLabel, Step, Stepper } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const WizardPage = ({ children }) =>
  <div>
    { children }
  </div>;

WizardPage.propTypes = {
  children: PropTypes.node,
};


export const Wizard = ({ children, showLastStep = false, onComplete, onReset, stepsValidations = () => ({ isValid: true }) }) => {
  const classes = useStyles();

  const [ activeStep, setActiveStep ] = useState(0);
  const [ errorMessage, setErrorMessage ] = useState('');
  const steps = React.Children.toArray(children);

  const nextStep = () => setActiveStep(ps => ps + 1);
  const previousStep = () => {
    setErrorMessage('');
    setActiveStep(ps => ps - 1);
  };
  const resetSteps = () => setActiveStep(0);

  const validateStep = () => {
    const { isValid, message } = stepsValidations({ fromStep: activeStep });
    if (isValid) {
      setErrorMessage('');
      nextStep();
    } else {
      setErrorMessage(message);
    }

    return isValid;
  };

  const onResetSteps = () => {
    resetSteps();
    onReset();
  };

  const onCompleteSteps = () => {
    const isValid = validateStep();
    if (isValid) {
      onComplete();
    }
  };

  return (
    <Box display='flex' flexDirection='column' flexGrow={ 1 }>
      <Stepper activeStep={activeStep} alternativeLabel>
        { steps.map(({ props }, index) =>
          <Step key={ index }>
            {/* eslint-disable-next-line react/prop-types */}
            <StepLabel>{ props.title || '' }</StepLabel>
          </Step>)
        }
      </Stepper>
      <Box>
        {activeStep === steps.length ?
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            { showLastStep && steps[activeStep - 1] }
            <Typography className={ classes.instructions }>Pasos completados</Typography>
            <Button onClick={ onResetSteps } variant="contained" color="secondary">Volver a llenar</Button>
          </Box> :
          <Box>
            { errorMessage &&
              <Box mb={ 3 }>
                <Alert variant="filled" severity="error">
                  <AlertTitle>Error:</AlertTitle>
                  { errorMessage }
                </Alert>
              </Box>
            }
            { steps[activeStep] }
            <Box>
              <Button disabled={ activeStep === 0 } onClick={ previousStep } className={ classes.backButton }>
                Atrás
              </Button>
              {
                activeStep === steps.length - 1 ?
                  <Button variant="contained" color="primary" onClick={ onCompleteSteps }>Terminar</Button> :
                  <Button variant="contained" color="primary" onClick={ validateStep }>Siguiente</Button>
              }
            </Box>
          </Box>
        }
      </Box>
    </Box>
  );
};

Wizard.propTypes = {
  children: PropTypes.node,
  onComplete: PropTypes.func,
  onReset: PropTypes.func,
  stepsValidations: PropTypes.func,
  showLastStep: PropTypes.bool,
};
