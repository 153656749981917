import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ReactDataSheet from 'react-datasheet';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dataGrid: {
    marginTop: theme.spacing(2),
    width: '100%',
    margin: 'auto',
  },
}));

const latLngHeader = [
  { readOnly: true, value: 'Pts. Transecta' },
  { readOnly: true, value: 'Latitud' },
  { readOnly: true, value: 'Longitud' },
];

const wsg68Header = [
  { readOnly: true, value: 'Pts. Transecta' },
  { readOnly: true, value: 'C Norte' },
  { readOnly: true, value: 'C Este' },
];

const colToKeyMapper = {
  0: 'name',
  1: 'y',
  2: 'x',
};

const emptyRow = [ { value: '' }, { value: '' }, { value: '' } ];

const GridCoordinatesTransectGeometryFlora = ({ coordType, samplingPoints, setSamplingPoints }) => {

  const classes = useStyles();
  const [ transectGrid, setTransectGrid ] = useState([
    coordType.type === 'LAT-LNG' ? latLngHeader : wsg68Header,
    emptyRow,
  ]);

  const samplingPointToRow = sp => [
    { value: sp.name || '' },
    { value: sp.y || '' },
    { value: sp.x || '' },
  ];

  useEffect(() => {
    setTransectGrid([
      coordType.type === 'LAT-LNG' ? latLngHeader : wsg68Header,
      ...samplingPoints.map(samplingPointToRow),
      emptyRow,
    ]);
  }, [ samplingPoints, coordType.type ]);

  // TODO: this should be setting transect.rowValues, and then update samplingPoints according to that
  const onCellsChanged = (changes, additions) => {
    const newSamplingPoints = [ ...samplingPoints ];

    changes && changes.forEach(({ row, col, value }) => {
      if (row - 1 >= newSamplingPoints.length) {
        newSamplingPoints.push({ [colToKeyMapper[col]]: value });
      } else {
        newSamplingPoints[row - 1] = { ...newSamplingPoints[row - 1], [colToKeyMapper[col]]: value };
      }
    });

    additions && additions.forEach(({ row, col, value }) => {
      if (value) {
        if (row - 1 > newSamplingPoints.length) {
          newSamplingPoints.push({ [colToKeyMapper[col]]: value });
        } else {
          newSamplingPoints[row - 1] = { ...newSamplingPoints[row - 1], [colToKeyMapper[col]]: value };
        }
      }
    });

    setSamplingPoints(
      newSamplingPoints
        .filter(sp => sp.name || sp.x || sp.y)
        .map(sp => ({ ...sp, x: parseFloat(sp.x), y: parseFloat(sp.y) })),
    );
  };

  // maybe use multiple tbodys instead? (the delete button's positioning may get awkward thou?)
  return (
    <Grid item className={classes.container}>
      <Grid item xs={8}>
        {/* Sin implementar */}
        <ReactDataSheet
          className={classes.dataGrid}
          data={transectGrid}
          valueRenderer={ cell => cell.value }
          onCellsChanged={onCellsChanged}
          onContextMenu={(e, cell) => cell.readOnly ? e.preventDefault() : null}
        />
      </Grid>
    </Grid>
  );
};


GridCoordinatesTransectGeometryFlora.propTypes = {
  coordType: PropTypes.object,
  samplingPoints: PropTypes.array,
  setSamplingPoints: PropTypes.func,
};

export { GridCoordinatesTransectGeometryFlora };
