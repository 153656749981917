import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { mdiLayersPlus, mdiTractor, mdiPickaxe } from '@mdi/js';


const LayersPlus = props => <SvgIcon {...props}><path d={mdiLayersPlus} /></SvgIcon>;
const Tractor = props => <SvgIcon {...props}><path d={mdiTractor} /></SvgIcon>;
const Pickaxe = props => <SvgIcon {...props}><path d={mdiPickaxe} /></SvgIcon>;


export {
  LayersPlus,
  Tractor,
  Pickaxe,
};
