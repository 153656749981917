import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import ReactDataSheet from 'react-datasheet';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dataGrid: {
    marginTop: theme.spacing(2),
    width: '60%',
    margin: 'auto',
  },
}));

const indexColumn = { readOnly: true, value: '' };
const emptySpeciesColumn = { value: { scientificName: '' } };
const emptyRow = [ indexColumn, emptySpeciesColumn ];
const headerRow = [ { ...indexColumn, width: '10%' }, { value: 'Especies', readOnly: true, width: '90%' } ];

const speciesToRow = species => [ indexColumn, { value: species } ];

const OnlySpecies = ({ speciesList, onUpdate }) => {
  const classes = useStyles();
  const [ grid, setGrid ] = useState([]);

  useEffect(() => {
    setGrid([
      headerRow,
      ...speciesList.map(speciesToRow),
      emptyRow,
    ]);
  }, [ speciesList ]);

  const cleanSpeciesName = name => name.replace(/\s+/g, ' ').replace().toLowerCase().trim();

  const onCellsChanged = (changes, additions) => {
    const newSpeciesList = [ ...speciesList ];

    changes && changes.forEach(({ row, value }) => {
      if (row - 1 >= speciesList.length) {
        newSpeciesList.push({ scientificName: cleanSpeciesName(value) });
      } else {
        newSpeciesList[row - 1] = { scientificName: cleanSpeciesName(value) };
      }
    });

    additions && additions.forEach(({ value }) => {
      if (value) {
        newSpeciesList.push({ scientificName: cleanSpeciesName(value) });
      }
    });

    onUpdate(newSpeciesList.filter(s => s.scientificName));
  };

  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
      <ReactDataSheet
        className={ classes.dataGrid }
        data={ grid }
        valueRenderer={(cell, i, j) =>
          i === 0 ? (j > 0 ? cell.value : '') :
          j === 0 ? i : cell.value.scientificName
        }
        onCellsChanged={onCellsChanged}
      />
    </Box>
  );
};

OnlySpecies.propTypes = {
  speciesList: PropTypes.array,
  onUpdate: PropTypes.func,
};

export default OnlySpecies;