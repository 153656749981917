import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, AccordionSummary, Box, Button, Typography, AccordionDetails, makeStyles }
  from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import { AoIContents } from 'src/scenes/AreasOfInfluence/components';
import { InfoTooltip } from 'src/components';


const useStyles = makeStyles({
  disabledAccordionSummary: {
    backgroundColor: '#ccc',
    cursor: 'auto !important',
  },
  enabledAccordionSummary: {
    backgroundColor: '#eee',
  },
});

const accordionTransitionPropObject = { unmountOnExit: true };
// TODO: mover a utils?
const stopEventPropagation = event => event.stopPropagation();

const ProjectComponent = ({
  projectComponent, phaseOptions, updateComponentState, errors, index, getEmptyAreaGeometryGroup, openRemoveDialog,
}) => {
  const classes = useStyles();
  const { name, areaGeometryGroups, dataOnWeb } = projectComponent;

  const [ expanded, setExpanded ] = useState(false);
  const [ availablePhases, setAvailablePhases ] = useState(phaseOptions.reduce((acc, curr) => ({ ...acc, [curr.value]: true }), {}));

  useEffect(() => {
    const nextCurrent = phaseOptions.reduce((acc, curr) => ({ ...acc, [curr.value]: true }), {});

    for (const areaGeometryGroup of areaGeometryGroups) {
      for (const phase of areaGeometryGroup.phases) {
        nextCurrent[phase] = false;
      }
    }
    setAvailablePhases(nextCurrent);
    // eslint-disable-next-line -- phaseOptions no debería cambiar
  }, [ areaGeometryGroups ]);

  const toggleExpanded = useCallback((e, expanded) => {
    setExpanded(expanded);
  }, []);

  // const updateComponent = useCallback(update => updateComponentState({ index, update }), [ updateComponentState, index ]);

  const addAreaGeometryGroup = () => updateComponentState({
    index,
    update: prevComp => ({ ...prevComp, areaGeometryGroups: [ ...prevComp.areaGeometryGroups, getEmptyAreaGeometryGroup() ] }),
  });

  const deleteAreaGeometryGroup = delInd => updateComponentState({
    index,
    update: prevComp => ({ ...prevComp, areaGeometryGroups: prevComp.areaGeometryGroups.filter((pa, i) => i !== delInd) }),
  });

  const updateAreaGeometryGroup = ({ groupIndex, update }) => updateComponentState({
    index,
    update: prevComp => ({
      ...prevComp,
      areaGeometryGroups: prevComp.areaGeometryGroups.map((agg, i) => i === groupIndex ? { ...agg, ...update } : agg),
    }),
  });

  // si algún valor de availablePhasesRef es false, entonces se puede agregar otra fase, pero no si quedarían más áreas que fases
  const canAddMoreAreas = Object.values(availablePhases).some(ap => ap) && areaGeometryGroups.length < phaseOptions.length;

  const buttonTooltipText = canAddMoreAreas ? undefined
    : 'No puedes tener más áreas de las que hay fases de proyecto, ni más áreas cuando ya has asignado todas las fases';

  const ownOpenRemoveDialog = useCallback(() => openRemoveDialog({ index, name, dataOnWeb }), [ index, name, dataOnWeb, openRemoveDialog ]);

  return (
    <Accordion expanded={!dataOnWeb && expanded} onChange={dataOnWeb ? null : toggleExpanded} defaultExpanded={false}
      TransitionProps={accordionTransitionPropObject}>
      <AccordionSummary className={ dataOnWeb ? classes.disabledAccordionSummary : classes.enabledAccordionSummary }
        expandIcon={ dataOnWeb ? null : <ExpandMoreIcon />}
      >
        <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
          <Box display='flex' alignItems='center'>
            { Boolean(errors) &&
              <Box component="span" mr={1} color="error.main">
                ¡Problemas al validar datos!
              </Box>
            }
            <Typography>
              {name}
            </Typography>
            { dataOnWeb &&
              <Box component="span" ml={1} color="green">
                ¡Completado!
              </Box>
            }
          </Box>
          <Box onClick={stopEventPropagation}>
            <Button variant="contained" color="secondary" onClick={ ownOpenRemoveDialog }>
              { dataOnWeb ? 'Borrar datos' : 'Descartar' }
            </Button>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display='flex' flexGrow={ 1 } flexDirection='column'>
          <Box display='flex' mb={2} mt={3} justifyContent='space-between'>
            <Typography variant="h5" component="span" gutterBottom>
              Áreas de Influencia por fase(s)
            </Typography>
            { Boolean(errors?.errorMessage) &&
              <Alert severity="error">{`${errors.errorMessage}`}</Alert>
            }
            { Boolean(errors?.areaGeometryGroups?.errorMessage) &&
              <Alert severity="error">{`${errors.areaGeometryGroups?.errorMessage}`}</Alert>
            }
            { canAddMoreAreas ? // TODO: por qué el segundo botón es más chico que el primero :O?
              <Button type="button" variant="contained" color="primary"
                onClick={ addAreaGeometryGroup } disabled={!canAddMoreAreas}
              >
                Agregar área en fase
              </Button>
              : <InfoTooltip contents={buttonTooltipText}>
                <Button type="button" variant="contained" color="primary"
                  onClick={ addAreaGeometryGroup } disabled={!canAddMoreAreas}
                >
                  Agregar área en fase
                </Button>
              </InfoTooltip>
            }
          </Box>
          { areaGeometryGroups.map((ag, agIndex) =>
            <AoIContents key={ agIndex } index={ agIndex } componentIndex={ index }
              phaseOptions={phaseOptions}
              areaGeometry={ ag }
              updateAreaGeometryGroup={updateAreaGeometryGroup}
              onDelete={ () => deleteAreaGeometryGroup(agIndex) }
              errors={errors?.areaGeometryGroups?.[agIndex]}
              availablePhases={availablePhases}
            />)
          }
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

ProjectComponent.propTypes = {
  phaseOptions: PropTypes.array.isRequired,
  getEmptyAreaGeometryGroup: PropTypes.func,
  projectComponent: PropTypes.object.isRequired,
  updateComponentState: PropTypes.func,
  errors: PropTypes.object,
  index: PropTypes.number.isRequired,
  openRemoveDialog: PropTypes.func,
};


export { ProjectComponent };
