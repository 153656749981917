import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { AppBar, Box, Typography, IconButton, Toolbar, makeStyles } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';

import { GlobalContext } from 'src/context';
import { userApi } from 'src/services';
import { useContext } from 'react';

const useStyles = makeStyles(() => ({
  contrastText: {
    color: '#ffffff',
  },
}));

const TopBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user, logout, baseline, changeBaseline } = useContext(GlobalContext);
  const currentLocation = history.location.pathname;

  const { firstName, lastName } = user;

  const onPressLogout = async () => {
    try {
      await userApi.logout();
      history.push('/');
      logout();
    } catch (e) {
      console.error(e);
    }
  };

  const userInSomeBaseline = currentLocation.match(/\/app\/.+/);
  return (
    <AppBar>
      <Toolbar>
        <RouterLink to={ userInSomeBaseline ? '/app/' : '/' } onClick={ () => changeBaseline() }>
          <Typography variant="h6" className={classes.contrastText}>
            BASEALINES {userInSomeBaseline ? `/ ${baseline?.name ?? ''}` : ''}
          </Typography>
        </RouterLink>
        <Box flexGrow={1} />
        <Typography variant='h6'>Hola, {`${firstName} ${lastName}`}! </Typography>
        <IconButton color="inherit" onClick={ onPressLogout }>
          <InputIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};


export default TopBar;