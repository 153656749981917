import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, Box, Button, Typography, FormHelperText,
  AccordionDetails, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

import WizardFlora from 'src/scenes/Flora/components/WizardFlora';
import OnlySpecies from 'src/scenes/Flora/components/OnlySpecies';
import ExactGridContainer from 'src/scenes/Flora/components/ExactGridContainer';
import { accuracyTypes } from 'src/scenes/Flora/utils';


const useStyles = makeStyles({
  accordionSummary: {
    backgroundColor: '#eee',
  },
  selector: {
    width: 300,
  },
});

const options = [
  { key: 'project-area', label: 'Área de Proyecto' },
  { key: 'sampling-point', label: 'Puntos de muestreo' },
  { key: 'exact', label: 'Exacta' },
  // { key: 'transect', label: 'Transecta' },
];

const projectAreaAccuracyEmptyPlace = {
  name: null,
  geometry: null,
  species: [],
};

const Record = ({ index, campaignIndex, record, onUpdate, onDelete, errors }) => {

  const classes = useStyles();

  const { accuracy, places } = record;

  const onUpdateSpecies = index => newData => {
    onUpdate({
      places: places.map((p, i) => i !== index ? p : ({ ...p, ...newData })),
    });
  };

  const onChangeAccuracy = e => {
    const accuracy = e.target.value;
    const places = accuracy === 'project-area' ? [ projectAreaAccuracyEmptyPlace ] : [];
    onUpdate({ accuracy, places });
  };

  return (
    <Accordion>
      <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
        <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
          <Box display='flex' alignItems='center'>
            { Boolean(errors) &&
              <Box component="span" mr={1} color="error.main">
                ¡Problema al validar datos!
              </Box>
            }
            <Typography>
              Precisión #{ index + 1 }{ accuracy ? `: ${options.find(o => o.key === accuracy).label}` : '' }
            </Typography>
          </Box>
          <Button variant="contained" color="secondary" onClick={ onDelete }>
            BORRAR
          </Button>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display='flex' flexDirection='column' flexGrow={ 1 }>
          <FormControl error={Boolean(errors?.accuracy)} component="fieldset">
            <InputLabel id="accuracy-selector">Precisión de datos</InputLabel>
            <Select
              className={ classes.selector }
              labelId="accuracy-selector"
              value={ accuracy }
              onChange={ onChangeAccuracy }
              displayEmpty
            >
              { options.map(o => <MenuItem key={ o.key } value={ o.key }>{ o.label }</MenuItem>) }
            </Select>
          </FormControl>
          { Boolean(errors?.accuracy) &&
            <FormHelperText>
              {`${errors.accuracy.errorMessage}`}
            </FormHelperText>
          }
          { Boolean(errors?.places?.errorMessage) &&
            <Alert severity="error">{`${errors.places.errorMessage}`}</Alert>
          }
          { (accuracy === accuracyTypes.PROJECT_AREA && Boolean(errors?.places?.[0]?.species)) &&
            <Alert severity="error">{`${ errors.places[0].species.errorMessage}`}</Alert>
          }
          <Box display='flex' mt={ 3 }>
            { accuracy === accuracyTypes.PROJECT_AREA &&
              <Box display='flex' flexDirection='column'>
                <OnlySpecies speciesList={ places[0]?.species || [] } onUpdate={species => onUpdateSpecies(0)({ species }) } />
                <strong>* Las especies se asociarán al área del proyecto</strong>
              </Box>
            }
            { accuracy === accuracyTypes.EXACT &&
              <Box display='flex' flexDirection='column'>
                <ExactGridContainer
                  places={ places }
                  updatePlaces={ places => onUpdate({ places }) }
                  campaignIndex={ campaignIndex }
                  recordIndex={ index }
                  accuracy={ accuracy }
                />
              </Box>
            }
            { (accuracy === accuracyTypes.SAMPLING_POINT || accuracy === accuracyTypes.TRANSECT) &&
              <WizardFlora places={ places } updatePlaces={ places => onUpdate({ places }) }
                campaignIndex={ campaignIndex } recordIndex={ index } accuracy={ accuracy } /> }
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

Record.propTypes = {
  campaignIndex: PropTypes.number,
  index: PropTypes.number,
  record: PropTypes.object,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  errors: PropTypes.object,
};

export { Record };
