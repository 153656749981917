import React from 'react';
import { Accordion, AccordionSummary, Box, Button, Typography, TextField, AccordionDetails, Checkbox, FormControlLabel, makeStyles }
  from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

import { accuracyTypes } from 'src/scenes/Flora/utils';
import { Record } from 'src/scenes/Flora/components/Record';


const useStyles = makeStyles({
  accordionSummary: {
    backgroundColor: '#ddd',
  },
  campaignInput: {
    width: '30%',
  },
});

const Campaign = ({ campaign, index, onUpdate, onDelete, errors }) => {
  const classes = useStyles();

  const { name, date, isExactDate, records } = campaign;

  const addNewRecord = () => {
    onUpdate({ records: [ ...records, { accuracy: accuracyTypes.SAMPLING_POINT, places: [] } ] });
  };

  const onUpdateRecord = index => newData => {
    onUpdate({
      records: records.map((r, i) => i !== index ? r : ({ ...r, ...newData })),
    });
  };

  const onDeleteRecord = index => () => {
    onUpdate({ records: records.filter((_, i) => i !== index) });
  };

  return (
    <Accordion>
      <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
        <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
          <Box display='flex' alignItems='center'>
            { Boolean(errors) &&
              <Box component="span" mr={1} color="error.main">
                ¡Problemas al validar datos!
              </Box>
            }
            <Typography>
              Campaña #{ index + 1 }{ name ? ` - ${name}` : '' }
            </Typography>
          </Box>
          <Button variant="contained" color="secondary" onClick={ onDelete }>
            BORRAR
          </Button>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display='flex' flexGrow={ 1 } flexDirection='column'>
          <TextField
            className={ classes.campaignInput }
            value={ name }
            onChange={ e => onUpdate({ name: e.target.value }) }
            label="Nombre"
            type="text"
            InputLabelProps={ { shrink: true } }
          />
          <Box display='flex' mb={ 3 } mt={ 2 }>
            <KeyboardDatePicker
              label='Fecha de la campaña'
              value={ date }
              onChange={ newDate => onUpdate({ date: newDate }) }
              format="dd/MM/yyyy"
              maxDate={ new Date() }
              invalidDateMessage='Formato de fecha inválido'
              maxDateMessage='No pueden haber campañas con una fecha superior a la de hoy'
            />
            <FormControlLabel
              control={<Checkbox checked={ isExactDate } onChange={ e => onUpdate({ isExactDate: e.target.checked }) } />}
              label="¿Es exacta?"
            />
            { Boolean(errors?.date) &&
              <Alert severity="error">{`${errors.date?.errorMessage}`}</Alert>
            }
          </Box>
          <Box display='flex' mb={ 1 } justifyContent='space-between'>
            <Typography variant="h5" component="span" gutterBottom>
              Registros de especies
            </Typography>
            { Boolean(errors?.errorMessage) &&
              <Alert severity="error">{`${errors.errorMessage}`}</Alert>
              // "there's more than one 'project-area' accuracy"-error
            }
            { Boolean(errors?.records?.errorMessage) &&
              <Alert severity="error">{`${errors.records?.errorMessage}`}</Alert>
              // "you have to have at least 1 record per campaign"
            }
            <Button type="button" variant="contained" color="primary" onClick={ addNewRecord }>Agregar Registros</Button>
          </Box>
          { records.map((r, recordIndex) =>
            <Record
              key={ recordIndex } index={ recordIndex } campaignIndex={ index } record={ r }
              onUpdate={ onUpdateRecord(recordIndex) } onDelete={ onDeleteRecord(recordIndex) }
              errors={errors?.records?.[recordIndex]}
            />)
          }
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

Campaign.propTypes = {
  campaign: PropTypes.object,
  index: PropTypes.number,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  errors: PropTypes.object,
};


export { Campaign };
