import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { projectApi } from 'src/services';
import { Page, Loading, ReportIssueDialog, DialogWrapper } from 'src/components';
import { ProjectYearSelection, ProjectForm } from 'src/scenes/LoadProject/components';
import { GlobalContext } from 'src/context';
import { stopSendingOnEnter, isInvalidGeometry } from 'src/utils';



const validationSchema = yup.object().shape({
  geoJson: yup.object().shape({
    features: yup.array().of(
      yup.object().test(
        'features',
        'No puedes agregar una geometría vacía o una con coordenadas invalidas',
        function (value) {
          const coordinates = value?.geometry?.coordinates || value?.geometry?.geometries;
          if (!coordinates) {
            return this.createError({ message: 'No puedes agregar una geometría vacía' });
          }
          const hasInvalidData = isInvalidGeometry(value);
          if (coordinates.length === 0) {
            return this.createError({ message: 'No puedes agregar una geometría vacía' });
          } else if (hasInvalidData) {
            return this.createError({ message: 'No puedes agregar una geometría con coordenadas invalidas' });
          } else {
            return coordinates.length > 0 && !hasInvalidData;
          }
        },
      ),
    ).required('Agrega al menos una geometría, campo obligatorio'),
  }),
  consultant: yup.string().when('doesntHaveConsultant', {
    is: false,
    then: schema => schema.test('len', 'Debes agregar una consultora o marcar el checkbox "¿No tiene consultora?"',
      string => string.trim().length > 0),
    otherwise: () => yup.string().length(0, 'No puedes agregar una consultora y marcar el checkbox al mismo tiempo'),
  }),
});

const defaultState = {
  seaProject: '',
  projectYear: false,
  consultant: '',
  project: {},
  doesntHaveConsultant: false,
  features: [],
  geoJson: {},
};

const useStyles = makeStyles(theme => ({
  submitButton: {
    display: 'block',
    marginTop: theme.spacing(5),
  },
}));

const LoadProject = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseline } = useContext(GlobalContext);

  const [ coordType, setCoordType ] = useState({
    type: 'LAT-LNG',
    timezone: 19,
  });
  const [ state, setState ] = useState(defaultState);
  const [ seaProject, setSeaProject ] = useState({});
  const [ isLoading, setIsLoading ] = useState(true);
  const [ errors, setErrors ] = useState({});
  const [ priorityState, setPriorityState ] = useState({ triedPrioritized: false, gotPrioritized: false });
  const [ dialogStatus, setDialogStatus ] = useState(false);

  const closeDialog = () => setDialogStatus(false);
  const openDialog = () => setDialogStatus(true);

  const getDialogType = () => dialogStatus ?
    <ReportIssueDialog seaProjectId={seaProject.id} actions={{ closeDialog, restartForm: clearState }} /> :
    <></>;

  const updateState = update => setState(prevState => ({ ...prevState, ...update }));

  const historyPush = result => {
    if (result?.project) {
      history.push({
        pathname: `${baseline.path}/load-project/${result.seaProject.id}/form`,
        state: { project: result.project, seaProject: result.seaProject },
      });
    }
  };

  const getPrioritaryProject = async () => {
    try {
      setPriorityState(prevState => ({
        ...prevState,
        triedPrioritized: true,
      }));
      let result = await projectApi.getPriorityProject({ type: baseline.key });
      if (result.data) {
        result = result.data;
        setPriorityState(prevState => ({
          ...prevState,
          gotPrioritized: true,
        }));
        updateState({ seaProject: result.seaProject, project: result.seaProject });
        setSeaProject(result.seaProject);
        setIsLoading(false);
        historyPush(result);
      } else {
        setIsLoading(false);
        setPriorityState(prevState => ({
          ...prevState,
          gotPrioritized: false,
        }));
      }
    } catch (err) {
      setIsLoading(false);
      setPriorityState(prevState => ({
        ...prevState,
        gotPrioritized: false,
      }));
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      } else {
        console.error('Problem to request project:', err);
      }
    }
  };

  const getRandomizedProject = async () => {
    try {
      const result = (await projectApi.getRandomProject({ year: projectYear, type: baseline.key }))?.data;
      updateState({ seaProject: result.seaProject, project: result.seaProject });
      setSeaProject(result.seaProject);
      setIsLoading(false);
      historyPush(result);
    } catch (err) {
      toast.error(err.serverMessage);
      console.error('Problem to request project:', err);
      if (baseline.key !== 'project') {
        history.push(`${baseline.path}/load-project`);
      } else {
        setIsLoading(false);
        clearState();
      }
    }
  };

  const {
    project,
    projectYear,
    features,
    consultant,
    geoJson,
    doesntHaveConsultant } = state;

  const getProject = useCallback(async year => {
    setIsLoading(true);
    if (year !== undefined) {
      getRandomizedProject();
    } else {
      getPrioritaryProject();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ history, projectYear ]);

  useEffect(() => {
    if (priorityState.triedPrioritized) {
      return;
    }
    getProject();
  }, [ priorityState.triedPrioritized, getProject ]);

  useEffect(() => {
    if (projectYear) {
      getProject(projectYear);
    }
  }, [ getProject, projectYear ]);

  const YupValidation = async params => {
    try {
      await validationSchema.validate(params, { abortEarly: false });
      return true;
    } catch (errors) {
      let validationErrors = {};
      errors.inner.forEach(error => {
        if (/geoJson.features\[(\d+)\]/.test(error.path)) {
          validationErrors = { ...validationErrors, geoJson: { features: { message: error.message } } };
        }
        if (error.path === 'geoJson.features') {
          validationErrors = { ...validationErrors, geoJson: { features: { message: error.message } } };
        }
        if (error.path === 'consultant') {
          validationErrors = { ...validationErrors, consultant: { message: error.message } };
        }
      });
      setErrors(validationErrors);
      throw errors;
    }
  };

  const clearState = () => {
    setState(defaultState);
    setSeaProject({});
    setIsLoading(true);
    setErrors({});
    setPriorityState({ triedPrioritized: false, gotPrioritized: false });
    closeDialog();
  };

  const onSubmit = async () => {
    const params = {
      geoJson: state.geoJson,
      seaId: state.project.id,
      formType: baseline.key,
      consultant: state.consultant,
      doesntHaveConsultant: state.doesntHaveConsultant,
    };
    try {
      await YupValidation(params);
      const response = await projectApi.saveProject(params);
      if (response) {
        toast.success(response.message);
        if (baseline.key !== 'project') {
          history.push({
            pathname: `${baseline.path}/load-project/${seaProject.id}/form`,
            state: { project: { id: response.data.id }, seaProject },
          });
        } else {
          clearState();
        }
      }
    } catch (err) {
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      } else if (err.errors) {
        console.error (err.errors);
        toast.error('Validación del proyecto falló');
      } else {
        console.error('Problem submiting form:', err);
        toast.error('Carga del proyecto falló');
      }
    }
  };

  return (
    <Page title={ 'Cargar Proyecto' }>
      <form noValidate onKeyDown={ stopSendingOnEnter } >
        {priorityState.triedPrioritized && !priorityState.gotPrioritized ?
          <ProjectYearSelection formType={ baseline.key } projectYear={projectYear} updateState={updateState} /> :
          null
        }
        {isLoading ?
          <Loading /> :
          (projectYear || priorityState.gotPrioritized) &&
            <>
              <ProjectForm
                project={project}
                coordType={coordType}
                setCoordType={setCoordType}
                features={features}
                geoJson={geoJson}
                consultant={consultant}
                doesntHaveConsultant={doesntHaveConsultant}
                updateState={updateState}
                errors={errors}
              />
              <Box display="flex" flex={2}>
                <Box mr={1}>
                  <Button className={classes.submitButton} variant="contained" onClick={openDialog}>
                    No es posible llenar el formulario
                  </Button>
                </Box>
                <Button className={classes.submitButton} onClick={onSubmit} variant="contained" color="primary">
                  Enviar
                </Button>
              </Box>
              <DialogWrapper maxWidth='sm' fullWidth onClose={closeDialog} open={dialogStatus}>
                { getDialogType() }
              </DialogWrapper>
            </>
        }
      </form>
    </Page>
  );
};


export { LoadProject };
