import React, { useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { FormControl, FormControlLabel, Checkbox, Grid, TextField } from '@material-ui/core';

import { projectApi } from 'src/services';


const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  spacingTop: {
    marginTop: theme.spacing(2),
  },
}));

const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), timeout);
  };
};

const AutoCompleteConsultants = ({ errors, consultant, doesntHaveConsultant, updateState }) => {
  const classes = useStyles();
  const [ consultantNames, setConsultantNames ] = useState([]);

  const getConsultantNames = async searchTerm => {
    try {
      const consultants = await projectApi.getConsultantNames({ searchTerm });
      setConsultantNames(consultants);
    } catch (err) {
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      } else {
        console.error(`Problem getting the consultant names: ${err}`);
      }
    }
  };

  const processChange = value => {
    updateState({ consultant: value, doesntHaveConsultant });
    debounce(getConsultantNames(value));
  };

  const handleDoesntHaveConsultant = () => {
    updateState({ doesntHaveConsultant: !doesntHaveConsultant });
  };

  const processAutocomplete = (event, newInputValue) => {
    updateState({ consultant: newInputValue, doesntHaveConsultant });
  };

  return (
    <Grid container item direction="row" alignItems="center" justifyContent="flex-start" >
      <Grid item xs={8}>
        <Autocomplete
          options={consultantNames.map(option => option.name)}
          freeSolo
          onInputChange={processAutocomplete}
          renderInput={ params => (
            <TextField
              value = {consultant}
              {...params}
              variant="outlined"
              label="Empresa Consultora"
              helperText={errors?.consultant?.message}
              error={Boolean(errors?.consultant?.message)}
              onChange={e => processChange(e.target.value)}
            />
          )}
        />
      </Grid>
      <Grid item >
        <FormControl className={classes.spacingTop}>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            value={doesntHaveConsultant}
            checked={doesntHaveConsultant}
            onChange={handleDoesntHaveConsultant}
            labelPlacement="start"
            label="¿No tiene consultora?"
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

AutoCompleteConsultants.propTypes = {
  errors: PropTypes.object.isRequired,
  consultant: PropTypes.string.isRequired,
  doesntHaveConsultant: PropTypes.bool.isRequired,
  updateState: PropTypes.func.isRequired,
};


export default AutoCompleteConsultants;