import React from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const DialogWrapper = props => {
  const { open, children, theme, ...otherProps } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Dialog fullScreen={fullScreen} open={open} scroll="paper" {...otherProps} >
      {children || <div></div>}
    </Dialog>
  );
};

DialogWrapper.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.any,
  theme: PropTypes.object,
};

const DialogWrapperWithTheme = withTheme(DialogWrapper);


export { DialogWrapperWithTheme as DialogWrapper } ;