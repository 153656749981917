import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Page, Section } from 'src/components';
import { ProjectsTable } from 'src/scenes/Archeology/components/ProjectsTable';
import { archeologyApi } from 'src/services';

const useStyles = makeStyles(theme => ({
  searchBox: {
    width: '30%',
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
  },
  newProjBox: {
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
  },
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignContent: 'center',
  },
  button: {
    marginLeft: '2px',
    marginRight: '2px',
  },
}));


const Header = ({ setSearch, clean, setClean }) => {
  const classes = useStyles();
  const history = useHistory();


  const onLoadProject = () => {
    history.push('/app/archeology/load-project');
  };

  const [ searchInput, setSearchInput ] = useState('');
  const onSubmit = async () => {
    try {
      toast.info('Buscando Proyecto');
      const resp = await archeologyApi.getOneProject({ seaId: searchInput });
      setSearch(resp);
      if (resp) {
        toast.dismiss();
      }
    } catch (err) {
      toast.dismiss();
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      } else {
        console.error(`Problem submit form: ${err}`);
      }
    }
  };

  const onClean = () => {
    setSearchInput('');
    setClean(!clean);
  };

  return (
    <Section title="Arqueología" >
      <div className={classes.container}>
        <div className={classes.searchBox}>
          <input
            className={classes.button}
            placeholder="Ingresa un id del SEA"
            onChange={e => setSearchInput(e.target.value)}
            value={searchInput}
          />
          <Button className={classes.button} onClick={e => onSubmit(e)} type="submit" variant="contained" color="primary">
            <SearchIcon fontSize="small"/>
          </Button>
          <Button className={classes.button} onClick={onClean} type="button" variant="contained" color="primary">
            <span>Limpiar</span>
          </Button>
        </div>
        <div className={classes.newProjBox}>
          <Button className={classes.button} onClick={onLoadProject} type="button" variant="contained" color="primary">
            <span>Cargar Proyecto</span>
          </Button>
        </div>
      </div>
    </Section>
  );
};
Header.propTypes = {
  setSearch: PropTypes.func.isRequired,
  clean: PropTypes.bool.isRequired,
  setClean: PropTypes.func.isRequired,
};



const ArcheologyContainer = () => {
  const [ search, setSearch ] = useState({ projects: [] });
  const [ clean, setClean ] = useState(false);

  return (
    <Page title="Arqueología">
      <Header setSearch={setSearch} clean={clean} setClean={setClean} />
      <ProjectsTable search={search} clean={clean}/>
    </Page>
  );
};

export { ArcheologyContainer };
