import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';


const defaultMultiVal = [];

const AutocompleteInput = ({ name, options, label, error, helperText, multiple = false, defaultValue, control, ...otherProps }) => (
  <Controller
    render={({ field }) => (
      <Autocomplete
        multiple={multiple}
        options={options}
        getOptionLabel={option => `${option.label || option.value || ''}`}
        getOptionSelected={(option, value) => option.value === value?.value}
        filterSelectedOptions
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            error={Boolean(error)}
            helperText={helperText}
          />
        )}
        onChange={(e, data) => {
          if (multiple) {
            const values = data.map(option => option.value);
            return field.onChange(values);
          }

          return field.onChange(data?.value ?? data);
        }}
        {...otherProps}
      />
    )}
    defaultValue={defaultValue !== undefined ? defaultValue : (multiple ? defaultMultiVal : null)}
    name={name}
    control={control}
  />
);

AutocompleteInput.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  helperText: PropTypes.string,
  multiple: PropTypes.bool,
  defaultValue: PropTypes.string,
  control: PropTypes.object.isRequired,
};


export default AutocompleteInput;