import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Button, Checkbox } from '@material-ui/core';
import ReactDataSheet from 'react-datasheet';
import PropTypes from 'prop-types';

import {
  wgs84ToLLNullable as wgs84ToLL,
  psad56ToLLNullable as psad56ToLL,
  isValidCoordinate,
  formatCoordinateToUser,
  formatCoordinateToFloatNumber,
} from 'src/utils';


const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dataGrid: {
    marginTop: theme.spacing(2),
    width: '100%',
    margin: 'auto',
  },
}));

const gridHeader = type => type === 'LAT-LNG' ?
  [
    { readOnly: true, value: '' },
    { value: 'Latitud', readOnly: true },
    { value: 'Longitud', readOnly: true },
  ] : [
    { readOnly: true, value: '' },
    { value: 'C Este', readOnly: true },
    { value: 'C Norte', readOnly: true },
  ];

const gridColumn = (index = 1) => ([
  { readOnly: true, value: index },
  { value: '' },
  { value: '' },
]);

const typeGeoJson = {
  POINT: 'Point',
  LINESTRING: 'LineString',
  POLYGON: 'Polygon',
};

const GridCoordinatesGeometryProj = ({
  index,
  updateState,
  coordType,
  features,
  isBadDataGlobal = false }) => {
  const classes = useStyles();

  const { type, timezone } = coordType;

  const gridDefault = type => [
    gridHeader(type),
    gridColumn(),
  ];

  const [ localState, setLocalState ] = useState(gridDefault(type));
  const [ geometry, setGeometry ] = useState(typeGeoJson.POINT);
  const [ isBadData, setIsBadData ] = useState(false);

  useEffect(() => {
    if (localState) {
      onChangeGridCoordinates({ grid: localState });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isBadData, localState, coordType ]);

  useEffect(() => {
    setIsBadData(isBadDataGlobal);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isBadDataGlobal ]);

  useEffect(() => {
    setLocalState(gridDefault(type));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ type ]);

  const updateFeatures = (newFeatures, index) => {
    const aux = [ ...features ];
    aux.splice(index, 1, newFeatures);
    updateState({ features: aux });
  };

  const handleChangeGeometry = (_, data) => {
    setLocalState(gridDefault(type));
    setGeometry(data);
  };

  const addRow = () => setLocalState(prevState => [ ...prevState, gridColumn(prevState.length) ]);

  const cleanGridCoordinates = ({ grid }) => grid.filter(([ , x, y ], index) => index > 0 && x.value && y.value)
    .map(([ , x, y ]) => [ x.value, y.value ]);

  const convertCoordinates = (coordinates, timezone) => type === 'WSG84' ?
    coordinates.map(([ x, y ]) =>
      wgs84ToLL({ coordinates: [ formatCoordinateToFloatNumber(x), formatCoordinateToFloatNumber(y) ], zoneNumber: timezone })) :
    type === 'PSAD56' ?
      coordinates.map(([ x, y ]) => psad56ToLL({
        coordinates: [ formatCoordinateToFloatNumber(x), formatCoordinateToFloatNumber(y) ],
        zoneNumber: timezone,
      })) :
      coordinates.map(([ x, y ]) => [ formatCoordinateToFloatNumber(y), formatCoordinateToFloatNumber(x) ]);

  const buildGeoJson = coordinates => {
    if (coordinates.length === 0) {
      return {};
    }
    const coordinatesGeoJson =
      geometry === typeGeoJson.POINT ? coordinates[0] :
      geometry === typeGeoJson.LINESTRING ? coordinates :
      geometry === typeGeoJson.POLYGON ? [ [ ...coordinates, coordinates[0] ] ] :
      undefined;
    return {
      type: 'Feature',
      geometry: { type: geometry, coordinates: coordinatesGeoJson },
      properties: { isBadData },
    };
  };

  const onChangeGridCoordinates = ({ grid }) => {
    const coordinates = convertCoordinates(cleanGridCoordinates({ grid }), timezone);
    const geoJson = buildGeoJson(coordinates);
    setLocalState(grid);
    updateFeatures(geoJson, index);
  };

  const handleRemoveGridGeometry = () => {
    updateState({ features: [ ...features.slice(0, index), ...features.slice(index + 1, features.length) ] });
  };

  const onCellsChanged = (changes, additions) => {
    const newGrid = [ ...localState ];
    changes.forEach(({ row, col, value }) => {
      if (isValidCoordinate({ value, coordinatesTypes: type, col })) {
        newGrid[row][col] = { ...newGrid[row][col], value: formatCoordinateToUser({ value, type }) };
      }
      additions && additions.forEach(({ row, col, value }) => {
        if (isValidCoordinate({ value, coordinatesTypes: type, col })) {
          if (!newGrid[row] && value) {
            newGrid[row] = gridColumn(row);
          }
          if (newGrid[row]?.[col]) {
            newGrid[row][col] = { ...newGrid[row][col], value: formatCoordinateToUser({ value, type }) };
          }
        }
      });
    });
    onChangeGridCoordinates({ grid: newGrid });
  };


  return (
    <Grid item className={classes.container}>
      <Grid item container direction="row" justifyContent="space-between" alignItems="center" >
        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="legend">Tipo de Geometría</FormLabel>
            <RadioGroup row
              value={geometry}
              onChange={handleChangeGeometry}
            >
              <FormControlLabel value={typeGeoJson.POINT} control={<Radio />} label="Punto" />
              <FormControlLabel value={typeGeoJson.LINESTRING} control={<Radio />} label="Línea" />
              <FormControlLabel value={typeGeoJson.POLYGON} control={<Radio />} label="Polígono" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <Grid item container direction="column" alignItems="flex-end">
            <Grid item>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                value={isBadData}
                checked={isBadData}
                labelPlacement="start"
                label="¿Datos erróneos?"
                onChange={(_, data) => {
                  setIsBadData(data);
                }}
              />
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={handleRemoveGridGeometry}>
                BORRAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        {geometry !== typeGeoJson.POINT &&
          <Button variant="outlined" onClick={addRow}>
            AGREGAR FILA
          </Button>
        }
        <ReactDataSheet
          className={classes.dataGrid}
          data={localState}
          valueRenderer={cell => cell.value}
          onCellsChanged={onCellsChanged}
          onContextMenu={(e, cell) => cell.readOnly ? e.preventDefault() : null}
        />
      </Grid>
    </Grid>
  );
};

GridCoordinatesGeometryProj.propTypes = {
  index: PropTypes.number.isRequired,
  updateState: PropTypes.func.isRequired,
  isBadDataGlobal: PropTypes.bool,
  coordType: PropTypes.object,
  features: PropTypes.array,
};


export default GridCoordinatesGeometryProj;