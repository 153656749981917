import React from 'react';
import PropTypes from 'prop-types';
import { Box, TextField } from '@material-ui/core';

import { Section } from 'src/components';


const ObservationsSection = ({ onChange, value, className, disabled, ...restProps }) => <Section title="Observaciones">
  <Box>
    <TextField
      onChange={onChange}
      type="text"
      name="comments"
      className={className}
      multiline
      minRows={4}
      placeholder={disabled && !value ? 'Sin observaciones' : 'Escribe las consideraciones que tengan con relación al proyecto'}
      variant="outlined"
      value={value}
      disabled={disabled}
      { ...restProps }
    />
  </Box>
</Section>;

ObservationsSection.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  className: PropTypes.any,
  disabled: PropTypes.bool,
};


export { ObservationsSection };
