import yup from 'src/scenes/yup';


// TODO: confirmar obligatoriedad de todas las cosas: se requiere una fase por área? se requiere una geometría?
// o puede haber solo fases y localidad? se requiere si quiera que haya por lo menos un componente de área en el proyecto?
// localidad por lo menos sabemos que no es obligatorio

const areaGeometryGroupSchema = {
  phases: yup.array().of(yup.string()).compact()
    .required('Campo obligatorio').test('required', 'Campo obligatorio', value => value?.length > 0)
    .test('noRepeats', 'No se deben repetir fases', val => val.length === (new Set([ ...val ])).size),
  locality: yup.string(),
  geometriesHaveProblems: yup.boolean(),
  // No requerido, pero si no es requerido debe estar chequeado que hay error o no se puede llenar y tener el campo de razón no vacío
  // TODO: verificar validez del geoJson? Se verifica si se indica que hay problemas?
  geoJson: yup.object()
    .when('geometriesHaveProblems', { is: true,
      then: schema => schema.nullable(),
      otherwise: schema => schema.required('Si no puedes dar geometrías, marca la casilla abajo del mapa e indica el problema'),
    }),
  problemsDescription: yup.string()
    .when('geometriesHaveProblems', { is: true, then: schema => schema.required('Campo obligatorio cuando has marcado la casilla') }),
};

const projectComponentSchema = {
  name: yup.string(),
  areaGeometryGroups: yup.array().of(
    yup.object().shape(areaGeometryGroupSchema),
  ) .test({
    name: 'noInterAreaPhasesRepeats',
    test: function(groupArray) {
      const phasesCount = {};
      const repeats = [];
      for (let gIndex = 0; gIndex < groupArray.length; gIndex++) {
        const currentPhaseArr = groupArray[gIndex].phases;
        let hasRepeat = false;

        for (let phaseIndex = 0; phaseIndex < currentPhaseArr.length; phaseIndex++) {
          const currentPhase = currentPhaseArr[phaseIndex];
          const dictVal = phasesCount[currentPhase];
          phasesCount[currentPhase] = dictVal === undefined ? 1 : dictVal + 1;
          if (phasesCount[currentPhase] > 1) {
            hasRepeat = true;
          }
        }

        if (hasRepeat) {
          repeats.push(gIndex);
        }
      }
      if (repeats.length > 0) {
        return this.createError({
          message: 'Contiene una fase que se repite en otra área',
          path: this.path,
          params: { errorExtra: { badIndices: repeats, phasesCount: phasesCount } },
        });
      } else {
        return true;
      }
    },
  }),
};

const emptyFormMess = 'No has agregado componentes. Si no se puede, puedes usar el botón de "No es posible llenar el formulario"';
const aoiSchema = yup.object({
  // (notar que esto deja mandar un proyecto con puras cosas con dataOnWeb true, lo que deja actualizar el comentario)
  projectComponents: yup.array().of(yup.lazy(comp => comp.dataOnWeb ? yup.object() : yup.object().shape(projectComponentSchema)))
    .required(emptyFormMess)
    .test('required', emptyFormMess, value => value?.length > 0),
  // no validation needed for comments
});

// Which path names refer to arrays of objects that we validate individually?
export const arraySchemaIds = [ 'projectComponents', 'areaGeometryGroups' ];

export default aoiSchema;