import { useEffect, useState, useCallback } from 'react';


const useMustLeave = ({ history, destination = '/app/' }) => {
  const [ mustLeave, setMustLeave ] = useState(false);

  const leavePage = useCallback(() => setMustLeave(true), []);

  useEffect(() => {
    if (mustLeave) {
      history.push(destination);
    }
    // eslint-disable-next-line -- history no cambia así que no es necesario ponerlo aquí
  }, [ mustLeave ]);

  return { leavePage, setMustLeave, mustLeave };
};


export { useMustLeave };