import React, { memo, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Checkbox,
  TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';

import { testDecimalsFormat, parseNumber, prependZeros, parseStringDayMonthYearToDate } from 'src/utils';
import { TypographicInputLabel } from 'src/components';
import * as commons from 'src/scenes/subformCommons/subformCommons';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
  textArea: {
    width: '100%',
  },
}));

const getOptionSelected = (option, value) => option.id === value || option === value;

const accordionTransitionPropObject = { unmountOnExit: true };

const FieldMeasurementsForm = memo(({ index, setHighlightedFeat, errors, fieldMeasurement, noiseUnitOptions, setForm }) => {

  const { featId, finished, dataOnWeb } = fieldMeasurement;

  const dayNoiseDescriptorOptions = [ 'NPSeq', 'Leq', 'NPSdia' ];
  const nightNoiseDescriptorOptions = [ 'NPSeq', 'Leq', 'NPSnoche' ];

  const stopEventPropagation = event => event.stopPropagation();

  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId, isPoint: true });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const getUnitLabels = useCallback(value => noiseUnitOptions.labels[value?.id ?? value], [ noiseUnitOptions ]);

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      fieldMeasurements: pf.fieldMeasurements.map((fm, currInd) => index === currInd ? ({ ...fm, [fieldName]: data }) : fm),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onBlurNumber = useCallback(e => {
    setForm(pf => ({
      ...pf,
      fieldMeasurements: pf.fieldMeasurements.map((fm, currInd) =>
        index === currInd ? ({ ...fm, [e.target.name]: parseNumber(fm[e.target.name], 1) ?? null }) : fm,
      ),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const stringToDate = timeString => {
    const [ hours, minutes ] = timeString.split(':');
    const date = new Date();
    date.setHours(hours, minutes);
    return date;
  };

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      fieldMeasurements: pf.fieldMeasurements.map((cm, currInd) => index === currInd ? ({ ...cm, [e.target.name]: e.target.checked }) : cm),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Medición en terreno #{index + 1}{`: ${fieldMeasurement.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={fieldMeasurement.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-dist-to-project`}>Distancia al proyecto [m]</TypographicInputLabel>
          <TextField
            variant="outlined" size="small" autoComplete="off"
            name={'distToProject'}
            value={fieldMeasurement.distToProject !== null ? fieldMeasurement.distToProject : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, 0) && onChange(e)}
            error={Boolean(errors?.distToProject)}
            helperText={errors?.distToProject ? errors.distToProject.errorMessage : ''}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${featId}-noise-unit`}>Unidad</TypographicInputLabel>
          </Box>
          <Box my={1}>
            <Autocomplete
              value={fieldMeasurement.unit}
              name={'unit'}
              options={noiseUnitOptions.options}
              getOptionLabel={getUnitLabels}
              getOptionSelected={getOptionSelected}
              filterSelectedOptions
              size="small"
              renderInput={params => (
                <TextField
                  {...params}
                  id={`${featId}-noise-unit`}
                  variant="outlined"
                  placeholder={'Unidad de ruido'}
                  error={Boolean(errors?.noiseUnit)}
                  helperText={errors?.noiseUnit?.errorMessage}
                />
              )}
              onChange={(event, newValue) => {
                onChange({ target: { name: 'unit', value: newValue === null ? null : newValue.id } });
              }}
            />
          </Box>
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-dayNoiseLevel`}>Nivel del ruído durante el día
            {fieldMeasurement.unit ? ` [${fieldMeasurement.unit}]` : '' }</TypographicInputLabel>
          <TextField
            variant="outlined" size="small" autoComplete="off"
            name={'dayNoiseLevel'}
            value={fieldMeasurement.dayNoiseLevel !== null ? fieldMeasurement.dayNoiseLevel : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, 1) && onChange(e)}
            error={Boolean(errors?.dayNoiseLevel)}
            helperText={`Nivel con hasta un decimal. ${
              errors?.dayNoiseLevel ? errors.dayNoiseLevel.errorMessage : ''}`}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-day-noise-descriptor`}>Descriptor nivel ruido durante el día
          </TypographicInputLabel>
          <Autocomplete
            value={fieldMeasurement.dayNoiseDescriptor}
            name={'dayNoiseDescriptor'}
            options={dayNoiseDescriptorOptions}
            getOptionLabel={value => value}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            size="small"
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-day-noise-descriptor`}
                variant="outlined"
                placeholder={'Descriptor nivel ruido durante el día'}
                error={Boolean(errors?.dayNoiseDescriptor)}
                helperText={errors?.dayNoiseDescriptor?.errorMessage}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'dayNoiseDescriptor', value: newValue === null ? null : newValue } });
            }}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-nightNoiseLevel`}>Nivel del ruído durante la noche
            {fieldMeasurement.unit ? ` [${fieldMeasurement.unit}]` : '' }
          </TypographicInputLabel>
          <TextField
            variant="outlined" size="small" autoComplete="off"
            name={'nightNoiseLevel'}
            value={fieldMeasurement.nightNoiseLevel !== null ? fieldMeasurement.nightNoiseLevel : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, 1) && onChange(e)}
            error={Boolean(errors?.nightNoiseLevel)}
            helperText={`Nivel con hasta un decimal. ${
              errors?.nightNoiseLevel ? errors.nightNoiseLevel.errorMessage : ''}`}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-night-noise-descriptor`}>
            Descriptor nivel ruido durante la noche
          </TypographicInputLabel>
          <Autocomplete
            value={fieldMeasurement.nightNoiseDescriptor}
            name={'nightNoiseDescriptor'}
            options={nightNoiseDescriptorOptions}
            getOptionLabel={value => value}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            size="small"
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-night-noise-descriptor`}
                variant="outlined"
                placeholder={'Descriptor nivel ruido durante la noche'}
                error={Boolean(errors?.nightNoiseDescriptor)}
                helperText={errors?.nightNoiseDescriptor?.errorMessage}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'nightNoiseDescriptor', value: newValue === null ? null : newValue } });
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={fieldMeasurement.preciseDate}
                  onChange={onCheckboxChange} name='preciseDate'
                />
              }
              labelPlacement="start"
              label="¿Datos de fecha y hora exactos?"
            />
          </Box>
        </Grid>

        { !fieldMeasurement.preciseDate ?
          <Grid item md={6} xs={6}>
            <Box mb={1}>
              <TypographicInputLabel htmlFor={`${featId}-measurement-date`}>
                Fecha medición
              </TypographicInputLabel>
            </Box>
            <Box my={1}>
              <KeyboardDatePicker
                placeholder="Fecha medición"
                name={'measurementDate'}
                value={fieldMeasurement.measurementDate ? parseStringDayMonthYearToDate(fieldMeasurement.measurementDate) : null}
                onChange={newValue => {
                  if (!newValue) {
                    onChange({ target: { name: 'measurementDate', value: null } });
                    return;
                  }
                  const day = newValue.getDate();
                  const month = newValue.getMonth() + 1;
                  const year = newValue.getFullYear();
                  onChange({ target: { name: 'measurementDate', value: newValue === null ? null :
                    `${prependZeros(day)}/${prependZeros(month)}/${year}` } });
                }}
                views= {[ 'year', 'month' ]}
                format='MM/yyyy'
                maxDate={new Date()}
                invalidDateMessage="Formato de fecha inválido"
                maxDateMessage="No pueden haber campañas con una fecha superior a la de hoy"
                ampm={false}
              />
            </Box>
          </Grid> :
          <>
            <Grid item md={6} xs={6}>
              <Box mb={1}>
                <TypographicInputLabel htmlFor={`${featId}-day-measurement-date`}>
                  Fecha medición durante el día
                </TypographicInputLabel>
              </Box>
              <Box my={1}>
                <KeyboardDatePicker
                  placeholder="Fecha medición durante el día"
                  name={'dayMeasurementDate'}
                  value={fieldMeasurement.dayMeasurementDate ? parseStringDayMonthYearToDate(fieldMeasurement.dayMeasurementDate) : null}
                  onChange={newValue => {
                    if (!newValue) {
                      onChange({ target: { name: 'dayMeasurementDate', value: null } });
                      return;
                    }
                    const day = newValue.getDate();
                    const month = newValue.getMonth() + 1;
                    const year = newValue.getFullYear();
                    onChange({ target: { name: 'dayMeasurementDate', value: newValue === null ? null :
                      `${prependZeros(day)}/${prependZeros(month)}/${year}` } });
                  }}
                  format='dd/MM/yyyy'
                  maxDate={new Date()}
                  invalidDateMessage="Formato de fecha inválido"
                  maxDateMessage="No pueden haber campañas con una fecha superior a la de hoy"
                  ampm={false}
                />
              </Box>
            </Grid>

            <Grid item md={6} xs={6}>
              <Box mb={1}>
                <TypographicInputLabel htmlFor={`${featId}-day-measurement-hour`}>Hora medición durante el día</TypographicInputLabel>
              </Box>
              <Box my={1}>
                <KeyboardTimePicker
                  placeholder="Hora medición durante el día"
                  name={'dayMeasurementHour'}
                  value={fieldMeasurement.dayMeasurementHour ? stringToDate(fieldMeasurement.dayMeasurementHour) : null}
                  onChange={(event, newValue) => {
                    onChange({ target: { name: 'dayMeasurementHour', value: newValue === null ? null : newValue } });
                  }}
                  format="HH:mm"
                  invalidDateMessage="Formato hora inválido"
                  ampm={false}
                />
              </Box>
            </Grid>

            <Grid item md={6} xs={6}>
              <Box mb={1}>
                <TypographicInputLabel htmlFor={`${featId}-night-measurement-date`}>
                  Fecha medición durante la noche
                </TypographicInputLabel>
              </Box>
              <Box my={1}>
                <KeyboardDatePicker
                  placeholder="Fecha medición durante la noche"
                  name="nightMeasurementDate"
                  value={fieldMeasurement.nightMeasurementDate ?
                    parseStringDayMonthYearToDate(fieldMeasurement.nightMeasurementDate) : null }
                  onChange={newValue => {
                    if (!newValue) {
                      onChange({ target: { name: 'nightMeasurementDate', value: null } });
                      return;
                    }
                    const day = newValue.getDate();
                    const month = newValue.getMonth() + 1;
                    const year = newValue.getFullYear();
                    onChange({
                      target: { name: 'nightMeasurementDate', value: newValue === null ? null :
                        `${prependZeros(day)}/${prependZeros(month)}/${year}`,
                      },
                    });
                  }}
                  format="dd/MM/yyyy"
                  maxDate={new Date()}
                  invalidDateMessage="Formato de fecha inválido"
                  maxDateMessage="No pueden haber campañas con una fecha superior a la de hoy"
                  ampm={false}
                />
              </Box>
            </Grid>

            <Grid item md={6} xs={6}>
              <Box mb={1}>
                <TypographicInputLabel htmlFor={`${featId}-night-measurement-hour`}>Hora medición durante la noche</TypographicInputLabel>
              </Box>
              <Box my={1}>
                <KeyboardTimePicker
                  placeholder="Hora medición durante la noche"
                  name={'nightMeasurementHour'}
                  value={fieldMeasurement.nightMeasurementHour ? stringToDate(fieldMeasurement.nightMeasurementHour) : null}
                  onChange={(event, newValue) => {
                    onChange({ target: { name: 'nightMeasurementHour', value: newValue === null ? null : newValue } });
                  }}
                  format="HH:mm"
                  invalidDateMessage="Formato hora inválido"
                  ampm={false}
                />
              </Box>
            </Grid>
          </>
        }

        <Grid item xs={12}>
          <Box my={1}>
            <Box>
              <TypographicInputLabel htmlFor={`${featId}-method-description`}>Metodología utilizada</TypographicInputLabel>
              <TextField
                id={`${featId}-method-description`}
                className={classes.textArea}
                minRows={1}
                maxRows={4}
                multiline
                name="methodDescription"
                onChange={onChange}
                helperText={`Breve descripción de la metodología de medición utilizada`}
                type="text"
                value={fieldMeasurement.methodDescription}
                variant="outlined"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>;
});

FieldMeasurementsForm.propTypes = {
  setHighlightedFeat: PropTypes.func,
  errors: PropTypes.object,
  fieldMeasurement: PropTypes.object,
  noiseUnitOptions: PropTypes.object.isRequired,
  index: PropTypes.number,
  setForm: PropTypes.func,
};

FieldMeasurementsForm.displayName = 'FieldMeasurementsForm';


export { FieldMeasurementsForm };