import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  label: {
    color: 'inherit',
  },
}));

const TypographicInputLabel = memo(({ TypographyProps, children, ...restProps }) => {
  const classes = useStyles();
  return <InputLabel disableAnimation className={ restProps.className || classes.label } {...restProps}>
    <Typography variant='subtitle2' gutterBottom { ...TypographyProps }>
      { children }
    </Typography>
  </InputLabel>;
});

TypographicInputLabel.propTypes = {
  children: PropTypes.any,
  TypographyProps: PropTypes.object,
};


export {
  TypographicInputLabel,
};
