import React, { useState } from 'react';
import { DialogTitle, DialogContent, DialogActions, Button, TextField, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { SelectWrapper } from 'src/components';
import { projectApi } from 'src/services';
import { enums } from 'src/utils';


const baseLines = enums.baseline;
const formTypesOptions = Object.keys(baseLines).map(key => ({ label: baseLines[key].name, value: key }));

const problems = [
  'El proyecto no tiene áreas de influencia que declarar',
  'El proyecto tiene demasiados registros y es posible que colapse la web',
  'Es un proyecto de transporte',
  'La línea base no está en el anexo',
  'Proyecto sin área específica',
  'Faltan datos de ubicación',
  'Faltan archivos digitalizados',
  'Faltan campos en Basealines para completar correctamente los datos',
  'Tiene registros de tipo transecta u otro',
  'Otra razón',
];

const problemsOptions = problems.map((p, idx) => ({ label: p, value: idx }));

const ReportIssueDialog = ({ actions, seaProjectId: seaId, type }) => {
  const [ data, setData ] = useState({ formType: type ?? 'project', problemIndex: 0, observation: '' });
  const handleChange = e => {
    e.persist();
    setData(prevData => ({ ...prevData, [e.target.name]: e.target.value }));
  };

  const { formType, problemIndex, observation } = data;

  const handleSubmit = async () => {
    try {
      const data = { type: formType, observation: `${problems[problemIndex]} - ${observation}` };
      const resp = await projectApi.reportIssue({ seaId, ...data });
      if (resp) {
        toast.success(resp.message);
        actions.restartForm();
      }
    } catch (err) {
      toast.error(err.serverMessage);
      actions.closeDialog();
    }
  };

  return (
    <>
      <DialogTitle>Ingrese la razón por la que no pudo rellenar el formulario</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          { !type &&
            <Box mt={2}>
              <SelectWrapper
                value={formType}
                fullWidth
                name="formType"
                options={formTypesOptions}
                label="Tipo de formulario"
                onChange={handleChange}
                required
              />
            </Box>
          }
          <Box mt={2}>
            <SelectWrapper
              value={problemIndex}
              fullWidth
              name="problemIndex"
              options={problemsOptions}
              label="Tipo de problema"
              onChange={handleChange}
              required
            />
          </Box>
          <Box my={2}>
            <TextField
              value={observation}
              fullWidth
              onChange={handleChange}
              type="text"
              name="observation"
              label="Observaciones"
              multiline
              minRows={4}
              maxRows={4}
              placeholder="Escribe las razones por la que no fue posible rellenar el formulario"
              variant="outlined"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <Button onClick={handleSubmit} color="primary">Confirmar</Button>
      </DialogActions>
    </>
  );
};

ReportIssueDialog.propTypes = {
  actions: PropTypes.object,
  seaProjectId: PropTypes.string,
  type: PropTypes.string.isRequired,
};


export { ReportIssueDialog };