import React from 'react';
import PropType from 'prop-types';
import { Box, Toolbar } from '@material-ui/core';

import TopBar from 'src/layouts/AppLayout/components/TopBar';


const AppLayout = ({ children }) => (
  <Box display="flex">
    <TopBar />
    <Box flexGrow={ 1 } p={ 3 } overflow="hidden">
      <Toolbar/>
      { children }
    </Box>
  </Box>
);

AppLayout.propTypes = {
  children: PropType.node.isRequired,
};

export { AppLayout };
